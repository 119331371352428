import _ from "lodash";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import Configuration from "../../components/pages/configuration";
import {setConfigPart, changeTabLock, showAdvancedConfigs, clearDirty} from "../../store/configuration/actions";
import {AppState} from "../../store/rootReducer";
import {ConfigurationProps} from "../../components/pages/configuration/types";
import {IntegrationsProps} from "../../components/pages/integrations/types";
import getSharedConfiguration from "../sharedConfiguration";

const mapStateToProps = (state: AppState) => state.configuration;
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  let configuration = getSharedConfiguration(dispatch);
  return {
    ...configuration,
    setConfigPart: (part: string) => {
      dispatch(setConfigPart(part));
    },
    changeTabLock: (tabName: string, locked: boolean) => {
      dispatch(changeTabLock(tabName, locked));
    },
    showAdvancedConfigs: (show: boolean) => {
      dispatch(showAdvancedConfigs(show));
    },
    clearChanges: (props: ConfigurationProps | IntegrationsProps) => {
      if (props.unlockedTabName) {
        const tabName = props.unlockedTabName;
        if (!_.isUndefined(tabName)) {
          dispatch(clearDirty(tabName));
          props.changeTabLock(tabName, true);
        }
      }
      props.showConfirmCancel(false);
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Configuration);
