import React from "react";
import _ from "lodash";
import DBReloadPageProps from "./types";

export default function StartButton(props: DBReloadPageProps) {
  if (_.isUndefined(props.loadId)) {
    return (
      <button
        type="button"
        className="btn btn-warning"
        disabled={_.every(props.viewCO2Components, vc => !vc.selected)}
        onClick={() => {
          if (_.isArray(props.viewCO2Components)) {
            props.startReload(props.viewCO2Components, props.filterAttributes);
          }
        }}
      >
        Start database reload
      </button>
    );
  } else {
    return null;
  }
}
