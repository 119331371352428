import React from "react";
import _ from "lodash";
import {IntegrationsProps} from "./types";
import renderConfigList from "../configuration/renderConfigList";
import {ConfigurationMetadata, ConfigurationPart, ConfigurationSetting} from "../../../store/configuration/types";
import {getSettingKey} from "../../../store/configuration/utils";
import {integrationName} from "../../../store/_types/integrations.types";
import {ConfigurationValueTypes} from "../../common/input/types";
import SyncButton from "./syncButton";
import TestConnectionBlock from "./testConnectionBlock";
import "./index.scss";

export default function Integrations(props: IntegrationsProps) {
  let currentConfigPart = _.find(props.configuration, {name: integrationName});
  let currentMetadataPart = _.find(props.metadata, {name: integrationName});

  if (_.isUndefined(currentMetadataPart) || _.isUndefined(currentConfigPart)) {
    return (
      <div className="alert alert-warning" role="alert">
        No config found
      </div>
    );
  }

  const itemsList = _.intersectionBy(currentMetadataPart.settingsMetadata, currentConfigPart.settings, "name");

  const dataWasChanged = _.some(itemsList, (configItem: ConfigurationSetting) => {
    return props.viewSettings[getSettingKey(currentConfigPart as ConfigurationPart, configItem)].isDirty;
  });

  const dataIsValid = _.every(
    itemsList,
    (configItem: ConfigurationSetting) =>
      props.viewSettings[getSettingKey(currentConfigPart as ConfigurationPart, configItem)].isValid
  );

  const getSettingValue = (configItem: ConfigurationMetadata) => {
    let settingValue = props.viewSettings[getSettingKey(currentConfigPart as ConfigurationPart, configItem)].value;
    return configItem.valueType === ConfigurationValueTypes.PASSWORD && _.isString(settingValue)
      ? "*".repeat(settingValue.length)
      : settingValue;
  };

  const integrationSettings = currentMetadataPart.settingsMetadata.map((settingsMetadata, index) => {
    return (
      <div className="row m-0" key={"integrationSetting_" + index}>
        <div className="col-sm-4 p-2">{settingsMetadata.title}</div>
        <div className="col-sm-8 p-2">{getSettingValue(settingsMetadata)}</div>
      </div>
    );
  });

  return (
    <>
      <div className="list-group list-group-flush h-100">
        {!props.editModeIsActive ? (
          <>
            {props.integrationSettingsReadOnly || (
              <div className="row">
                <div className="col-12 col-lg-8 pb-3 pr-3 text-right">
                  <button
                    type="button"
                    className="btn btn-warning editButton"
                    onClick={() => {
                      props.changeEditMode(true);
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            )}
            <div className="row m-0 mb-3">
              <div className="col-12 col-lg-8 p-2 m-0 breadcrumb rounded-0">Set Up</div>
            </div>
            {integrationSettings}
            <div className="row">
              <div className="col-12 col-lg-8 p-3 text-right">
                <SyncButton {...props} />
              </div>
            </div>
          </>
        ) : (
          <div className="mb-5 h-100 d-flex flex-column justify-content-between">
            <div className="row m-0">
              <div className="card edit-mode col-12 col-lg-8 p-0 rounded-0">
                <div className="list-group-item">
                  {renderConfigList(itemsList, props, !props.editModeIsActive, currentMetadataPart)}
                </div>
                <TestConnectionBlock {...props} currentCP={currentConfigPart} />
              </div>
            </div>
            <div className="mb-5 pt-3 pb-3">
              <SyncButton {...props} />
            </div>
            <div className="save-cancel-block text-right p-3 d-flex align-items-center justify-content-end alert-secondary fixed-bottom">
              <button
                type="button"
                className="btn btn-light border-dark m-1 cancelButton"
                onClick={() => {
                  dataWasChanged ? props.showConfirmCancel(true) : props.changeEditMode(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  await props.saveChanges(currentConfigPart as ConfigurationPart, props);
                  props.changeEditMode(false);
                }}
                type="button"
                disabled={!dataWasChanged || !dataIsValid}
                className="btn btn-primary m-1 saveButton"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
