import React from "react";
import _ from "lodash";

import {Props} from "./types";
import OptionsPopupSearch from "./OptionsPopupSearch";
import OptionsPopupList from "./OptionsPopupList";
import {containsChild} from "./optionFilterUtility";

export default class OptionsPopup extends React.Component<Props> {
  el: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.el = React.createRef();
    this.onBodyClick = this.onBodyClick.bind(this);
  }
  onBodyClick(e: MouseEvent) {
    if (_.isNull(this.el.current) || _.isNull(e.target)) {
      return;
    }
    if (e.target !== this.el.current) {
      if (!containsChild(this.el.current, e.target as HTMLElement)) {
        this.props.openOptionSelector(this.props.name, false);
      }
    }
  }
  componentDidMount() {
    window.document.body.addEventListener("click", this.onBodyClick, false);
  }
  componentWillUnmount() {
    window.document.body.removeEventListener("click", this.onBodyClick, false);
  }
  render() {
    if (!this.props.optionSelectorOpened) {
      return null;
    }
    return (
      <div className="optionSelectorMenu border bg-white" ref={this.el}>
        <OptionsPopupSearch {...this.props} />
        <OptionsPopupList {...this.props} />
      </div>
    );
  }
}
