import React from "react";
import _ from "lodash";
import DBReloadPageProps from "./types";
import StartButton from "./StartButton";
import SpinnerWithInterval from "./SpinnerWithInterval";

const Controls = (props: DBReloadPageProps) => {
  return (
    <>
      <div className="row m-0">
        <StartButton {...props} />
        {_.isString(props.loadId) && (
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              if (_.isString(props.loadId)) {
                props.stopReload(props.loadId);
              }
            }}
          >
            {_.isString(props.loadId) && <SpinnerWithInterval {...props} />}
            Stop database reload
          </button>
        )}
      </div>
    </>
  );
};
export default Controls;
