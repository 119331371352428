import React from "react";

import {Props} from "./types";
import SelectedAttributesList from "./SelectedAttributesList";
import MainLabel from "./MainLabel";
import OptionsPopup from "./OptionsPopup";
import "./index.scss";

export default function OptionFilter(props: Props) {
  return (
    <div className="optionSelector position-relative">
      <div className="topLabel">{props.title}:</div>
      <MainLabel {...props} />
      {props.optionSelectorOpened && <OptionsPopup {...props} />}
      <SelectedAttributesList {...props} />
    </div>
  );
}
