export const SHOW_LOADING = "SHOW_LOADING";

export interface LoadingState {
  loading: boolean;
}

interface ShowLoadingAction {
  type: typeof SHOW_LOADING;
  loading: boolean;
}

export type LoadingActionTypes = ShowLoadingAction;
