import React from "react";
import _ from "lodash";

import DBReloadPageProps from "./types";
import {
  ViewCO2Component,
  SortFields,
  SortOrder,
  ComponentReloadStatus,
  ReloadStatuses,
  statusMap
} from "../../../store/dbReload/types";
import SortHeader from "../../sortHeader";

function getProgressBar(percent: number) {
  return (
    <div className="row no-gutters align-items-center">
      <div className="progress rounded-0 col-8">
        <div
          className="progress-bar"
          role="progressbar"
          style={{width: percent + "%"}}
          aria-valuenow={percent}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
      <span className="col-4 pl-3">{Math.floor(percent)}%</span>
    </div>
  );
}

function getStatus(crs: ComponentReloadStatus) {
  if (crs.loadStatus === ReloadStatuses.IN_PROGRESS) {
    if (_.isUndefined(crs.currentStep) || _.isUndefined(crs.loadSteps[crs.currentStep])) {
      return getProgressBar(0);
    }
    const loadStep = crs.loadSteps[crs.currentStep];
    const finalPercentage = loadStep.countTotal === 0 ? 0 : (loadStep.countProcessed / loadStep.countTotal) * 100;
    return (
      <>
        {getProgressBar(finalPercentage)}
        Loading ({crs.currentStep === "_all" ? "All" : crs.currentStep}) {loadStep.countProcessed} of{" "}
        {loadStep.countTotal}
      </>
    );
  } else {
    return statusMap[crs.loadStatus];
  }
}

export default function ComponentsTable(props: DBReloadPageProps) {
  function getActualLoadAttribute(vco2c: ViewCO2Component) {
    let filterAttribute = _.find(props.filterAttributes, {
      name: vco2c.co2Component.loadAttribute
    });
    if (_.isUndefined(filterAttribute)) {
      return `All `;
    } else {
      return filterAttribute.selectedOptions.length > 0
        ? `${filterAttribute.title}: ${filterAttribute.selectedOptions.join(", ")}`
        : `All `;
    }
  }
  let sortedVC2C = _.sortBy<ViewCO2Component>(props.viewCO2Components, vc2c => {
    return _(vc2c).get(props.sortField);
  });
  if (props.sortOrder === SortOrder.DESC) {
    sortedVC2C = sortedVC2C.reverse();
  }
  return (
    <table className="table">
      <thead>
        <tr className="row no-gutters">
          <th className="col-1 top-border-yellow pointy font-weight-light">
            <div className="ml-3 form-check">
              <input
                className="form-check-input position-static"
                type="checkbox"
                disabled={_.isString(props.loadId)}
                checked={_.every(props.viewCO2Components, vc => vc.selected)}
                aria-label="select all"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.onAllComponentCheckChange(e.target.checked);
                }}
              />
            </div>
          </th>
          <SortHeader
            active={props.sortField === SortFields.DB_NAME}
            name="Database Name"
            sortOrder={props.sortOrder}
            colVal={4}
            onClick={(sortOrder: SortOrder) => {
              props.setSort(SortFields.DB_NAME, sortOrder);
            }}
          />
          <SortHeader
            active={props.sortField === SortFields.LOAD_ATTRIBUTE}
            name="Load Attribute"
            sortOrder={props.sortOrder}
            colVal={3}
            onClick={(sortOrder: SortOrder) => props.setSort(SortFields.LOAD_ATTRIBUTE, sortOrder)}
          />
          <th className="col-4 top-border-yellow pointy font-weight-light">Status</th>
        </tr>
      </thead>
      <tbody>
        {sortedVC2C.map(vco2c => (
          <tr key={vco2c.co2Component._id} className="row no-gutters">
            <td className="col-1">
              <div className="ml-3 form-check">
                <input
                  className="form-check-input position-static"
                  type="checkbox"
                  disabled={_.isString(props.loadId)}
                  checked={vco2c.selected}
                  onChange={() => {
                    props.onComponentCheckChange(vco2c);
                  }}
                  aria-label="select db {vco2c.co2Component._id}"
                />
              </div>
            </td>
            <td className="col-4">{vco2c.co2Component._id}</td>
            <td className="col-3">{getActualLoadAttribute(vco2c)}</td>
            <td className="col-4">
              {vco2c.viewComponentLoadStatus &&
                vco2c.viewComponentLoadStatus.loadStatus &&
                getStatus(vco2c.viewComponentLoadStatus)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
