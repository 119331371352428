import _ from "lodash";
import {ValueType, SingleValueType} from "../../components/common/input/types";
import {
  CHANGE_LABEL_TRANSLATIONS,
  GENERIC_REQUEST_FAILURE,
  GENERIC_REQUEST_START,
  GET_CONFIGURABLE_LABELS_SUCCESS,
  ADD_CHANGE_CONFIG_LABEL_SUCCESS,
  ADD_CHANGE_CONFIG_LABEL_FAILURE,
  DELETE_CONFIG_LABEL_SUCCESS,
  CHANGE_ACTIVE_TAB,
  SHOW_ADD_EDIT_CONFIG_LABEL,
  CHANGE_ACTIVE_LABEL,
  ConfigurableLabelResponseSchema,
  ScreenExtensionsConfigResponseSchema,
  ConfigurableLabel,
  ScreenExtensionsResponseSchema,
  GET_SCREEN_EXTENSIONS_CONFIG_SUCCESS,
  ADD_MENU_BUTTON_ON_SCREEN_SUCCESS,
  ADD_MENU_BUTTON_ON_SCREEN_FAILURE,
  SELECT_SCREEN_CHANGE,
  SELECT_BUTTON_SCREEN_CHANGE,
  SET_ACTIVE_SCREEN_BTNS_CONFIG,
  SHOW_ADD_EDIT_CONFIGURABLE_FIELD,
  CHANGE_CONFIG_FIELD,
  HIDE_INFO_MESSAGE,
  ADD_CHANGE_CONFIGURABLE_FIELD_SUCCESS,
  SET_CONFIG_FIELD_TO_ADD,
  SET_CONFIG_FIELD_TO_EDIT,
  SET_CONFIG_FIELD_TO_DELETE,
  ADD_CHANGE_CONFIGURABLE_FIELD_FAILURE,
  GET_SCREEN_EXTENSIONS_SUCCESS,
  TOGGLE_CUSTOM_TREE_VIEW,
  CHANGE_ACTIVE_CONFIG_FIELDS,
  SET_ACTIVE_CONFIG_BUTTON,
  SET_CONFIG_BUTTON_TO_DELETE,
  EDIT_SCREEN_CONFIGURATION_SUCCESS,
  EDIT_SCREEN_CONFIGURATION_START,
  EDIT_SCREEN_CONFIGURATION_FAILURE,
  SET_SCREEN_CONFIG_WORKING_COPY,
  CLEAR_SCREEN_CONFIG_WORKING_COPY,
  SCREEN_EDITOR_CONFIG_UPDATED,
  DELETE_CONFIGURABLE_FIELD_SUCCESS,
  DELETE_CONFIGURABLE_BUTTON_SUCCESS,
  ADD_CHANGE_CONFIGURABLE_FIELD_REQUEST_START,
  CLEAR_CONFIG_CREATE_ERROR_MESSAGE,
  ActiveConfigField,
  ConfigField,
  ConfigurableField,
  configurableButtonsConfig,
  activeConfigurableButtonConfig
} from "./types";

export function selectScreenChange(name: string, value: ValueType) {
  return {
    type: SELECT_SCREEN_CHANGE,
    name,
    value
  };
}

export function selectButtonScreenChange(name: string, value: ValueType) {
  return {
    type: SELECT_BUTTON_SCREEN_CHANGE,
    name,
    value
  };
}

export function changeActiveTab(activeTab: string) {
  return {
    type: CHANGE_ACTIVE_TAB,
    activeTab
  };
}

export function showAddEditConfigLabel(show: boolean, editMode: boolean) {
  return {
    type: SHOW_ADD_EDIT_CONFIG_LABEL,
    show,
    editMode
  };
}
export function changeActiveLabel(label?: ConfigurableLabel) {
  return {
    type: CHANGE_ACTIVE_LABEL,
    label
  };
}

export function showAddEditConfigField(show: boolean, editConfig: boolean) {
  return {
    type: SHOW_ADD_EDIT_CONFIGURABLE_FIELD,
    show,
    editConfig
  };
}

export function addConfigLabel(label: ConfigurableLabel) {
  const payload = _.omit(label, "labelId");
  return {
    types: [GENERIC_REQUEST_START, ADD_CHANGE_CONFIG_LABEL_SUCCESS, ADD_CHANGE_CONFIG_LABEL_FAILURE],
    payload,
    getURL: () => `e7/services/config/labels/${label.labelId}`,
    method: "POST"
  };
}
export function changeConfigLabel(label: ConfigurableLabel) {
  const payload = _.omit(label, "labelId");
  return {
    types: [GENERIC_REQUEST_START, ADD_CHANGE_CONFIG_LABEL_SUCCESS, ADD_CHANGE_CONFIG_LABEL_FAILURE],
    payload,
    responseSchema: ConfigurableLabelResponseSchema,
    getURL: () => `e7/services/config/labels/${label.labelId}`,
    method: "PUT"
  };
}
export function deleteConfigLabel(labelId: string) {
  return {
    types: [GENERIC_REQUEST_START, DELETE_CONFIG_LABEL_SUCCESS, GENERIC_REQUEST_FAILURE],
    responseSchema: ConfigurableLabelResponseSchema,
    getURL: () => `e7/services/config/labels/${labelId}`,
    method: "DELETE"
  };
}

export function changeValue(name: string, value: SingleValueType) {
  return {
    type: CHANGE_LABEL_TRANSLATIONS,
    name,
    value
  };
}

export function changeConfigValue(name: string, value: string | undefined) {
  return {
    type: CHANGE_CONFIG_FIELD,
    name,
    value
  };
}

export function getConfigLabels() {
  return {
    types: [GENERIC_REQUEST_START, GET_CONFIGURABLE_LABELS_SUCCESS, GENERIC_REQUEST_FAILURE],
    responseSchema: ConfigurableLabelResponseSchema,
    getURL: () => `e7/services/config/labels`
  };
}

export function getScreenExtensionsConfig() {
  return {
    types: [GENERIC_REQUEST_START, GET_SCREEN_EXTENSIONS_CONFIG_SUCCESS, GENERIC_REQUEST_FAILURE],
    responseSchema: ScreenExtensionsConfigResponseSchema,
    getURL: () => `e7/services/config/screen-extensions/configs`
  };
}

export function setActiveScreenBtnsConfig(config: configurableButtonsConfig) {
  return {
    type: SET_ACTIVE_SCREEN_BTNS_CONFIG,
    config
  };
}

export function addMenuButtonOnScreen(config: activeConfigurableButtonConfig) {
  const payload = _.pickBy(
    {action: config.action, label: config.label},
    field => _.isString(field) && field.length > 0
  );
  return {
    types: [GENERIC_REQUEST_START, ADD_MENU_BUTTON_ON_SCREEN_SUCCESS, ADD_MENU_BUTTON_ON_SCREEN_FAILURE],
    payload,
    getURL: () => `e7/services/config/screen-extensions/configs/actions?screen=${config.id}&group=${config.group}`,
    method: "POST"
  };
}
export function setActiveConfigButton(config: activeConfigurableButtonConfig) {
  return {
    type: SET_ACTIVE_CONFIG_BUTTON,
    config
  };
}
export function setConfigButtonToDelete(config: activeConfigurableButtonConfig) {
  return {
    type: SET_CONFIG_BUTTON_TO_DELETE,
    config
  };
}
export function clearScreenConfigWorkingCopy() {
  return {
    type: CLEAR_SCREEN_CONFIG_WORKING_COPY
  };
}

export function screenEditorConfigUpdated(updated: boolean) {
  return {
    type: SCREEN_EDITOR_CONFIG_UPDATED,
    updated
  };
}

export function editScreenConfiguration(screenConfiguration: ConfigurableField) {
  return {
    types: [EDIT_SCREEN_CONFIGURATION_START, EDIT_SCREEN_CONFIGURATION_SUCCESS, EDIT_SCREEN_CONFIGURATION_FAILURE],
    payload: screenConfiguration,
    getURL: () => `/e7/services/config/screen-extensions/screen`,
    method: "POST"
  };
}

export function setScreenConfigWorkingCopy(screen: string) {
  return {
    type: SET_SCREEN_CONFIG_WORKING_COPY,
    screen
  };
}

export function clearConfigCreateErrorMessage() {
  return {
    type: CLEAR_CONFIG_CREATE_ERROR_MESSAGE
  };
}

export function deleteConfigButton(config: activeConfigurableButtonConfig) {
  return {
    types: [GENERIC_REQUEST_START, DELETE_CONFIGURABLE_BUTTON_SUCCESS, GENERIC_REQUEST_FAILURE],
    getURL: () =>
      `e7/services/config/screen-extensions/configs/actions?screen=${config.id}&group=${config.group}&action=${
        config.action
      }`,
    method: "DELETE"
  };
}

export function getConfigurableFields() {
  return {
    types: [GENERIC_REQUEST_START, GET_SCREEN_EXTENSIONS_SUCCESS, GENERIC_REQUEST_FAILURE],
    responseSchema: ScreenExtensionsResponseSchema,
    getURL: () => `/e7/services/config/screen-extensions`
  };
}

export function addConfigurableField(configField: ConfigField, screen: string, group: string) {
  return {
    types: [
      ADD_CHANGE_CONFIGURABLE_FIELD_REQUEST_START,
      ADD_CHANGE_CONFIGURABLE_FIELD_SUCCESS,
      ADD_CHANGE_CONFIGURABLE_FIELD_FAILURE
    ],
    payload: configField,
    getURL: () => `/e7/services/config/screen-extensions/fields?screen=${screen}&group=${group}`,
    method: "POST"
  };
}

export function setConfigurableFieldToAdd(configField: ConfigField, screen: string, group: string) {
  return {
    type: SET_CONFIG_FIELD_TO_ADD,
    configField,
    screen,
    group
  };
}

export function setConfigurableFieldToEdit(configField: ConfigField, screen: string, group: string) {
  return {
    type: SET_CONFIG_FIELD_TO_EDIT,
    configField,
    screen,
    group
  };
}

export function setConfigurableFieldToDelete(field: ActiveConfigField) {
  return {
    type: SET_CONFIG_FIELD_TO_DELETE,
    field
  };
}

export function editConfigurableField(configField: ConfigField, screen: string, group: string) {
  return {
    types: [
      ADD_CHANGE_CONFIGURABLE_FIELD_REQUEST_START,
      ADD_CHANGE_CONFIGURABLE_FIELD_SUCCESS,
      ADD_CHANGE_CONFIGURABLE_FIELD_FAILURE
    ],
    payload: configField,
    getURL: () => `/e7/services/config/screen-extensions/fields?screen=${screen}&group=${group}`,
    method: "PUT"
  };
}

export function toggleExpanded(key: string, value: boolean) {
  return {
    type: TOGGLE_CUSTOM_TREE_VIEW,
    key,
    value
  };
}

export function changeActiveConfigFields(config: ActiveConfigField) {
  return {
    type: CHANGE_ACTIVE_CONFIG_FIELDS,
    config
  };
}
export function deleteConfigField(config: ActiveConfigField) {
  return {
    types: [GENERIC_REQUEST_START, DELETE_CONFIGURABLE_FIELD_SUCCESS, GENERIC_REQUEST_FAILURE],
    payload: config,
    getURL: () =>
      `/e7/services/config/screen-extensions/fields?screen=${config.screen}&group=${config.section}&field=${
        config.fieldName
      }`,
    method: "DELETE"
  };
}
export function hideInfoMessage() {
  return {
    type: HIDE_INFO_MESSAGE
  };
}
