import {LoadingState} from "../loading/types";

interface UserObject {
  username?: string;
  displayName?: string;
  tenant?: string;
}

export interface Route {
  path: string;
  title: string;
  main: Element;
  icon: string;
}

export interface AuthorizationState {
  userInfo: UserObject;
  errorMessage: string;
  routes: Route[];
  defaultRoute: string;
  integrationSettingsReadOnly: boolean;
  isLoginModalOpened: boolean;
  goBackUrl: string;
  cmms: string;
}

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  user: UserObject;
}

export const AUTH_START = "AUTH_START";
interface LoginStartAction {
  type: typeof AUTH_START;
}
export const AUTH_FAILURE = "AUTH_FAILURE";
interface LoginFailAction {
  type: typeof AUTH_FAILURE;
  errorMessage: string;
}

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
interface LogoutAction {
  type: typeof LOGOUT_SUCCESS;
}

interface AuthorizationActions {
  login: (login: string, password: string) => void;
  logout: () => void;
  setLoginModalMode: (isLoginModalOpened: boolean) => void;
  hideLoader: () => void;
  getSystemSettings: () => Promise<void>;
  getCmmsSettings: () => Promise<void>;
}

export const SYSTEM_SETTINGS_FAILURE = "SYSTEM_SETTINGS_FAILURE";

export const SYSTEM_SETTINGS_SUCCESS = "SYSTEM_SETTINGS_SUCCESS";
interface getSystemSettings {
  type: typeof SYSTEM_SETTINGS_SUCCESS;
  data: {
    userInfo: UserObject;
    integrationSettingsReadOnly?: boolean;
    usersComponentDisabled: boolean;
    amrComponentDisabled: boolean;
    goBackUrl?: string;
  };
}

export const CMMS_SETTINGS_FAILURE = "CMMS_SETTINGS_FAILURE";
export const CMMS_SETTINGS_SUCCESS = "CMMS_SETTINGS_SUCCESS";
interface getCmmsSettings {
  type: typeof CMMS_SETTINGS_SUCCESS;
  data: {
    cmms: string;
    goBackUrl: string;
  };
}

export const SET_LOGIN_MODAL_MODE = "SET_LOGIN_MODAL_MODE";
interface setLoginModalModeAction {
  type: typeof SET_LOGIN_MODAL_MODE;
  isLoginModalOpened: boolean;
}

export type AuthorizationProps = AuthorizationState & AuthorizationActions & LoadingState;

export type AuthenticationActionsTypes =
  | LoginStartAction
  | LoginSuccessAction
  | LoginFailAction
  | LogoutAction
  | getSystemSettings
  | getCmmsSettings
  | setLoginModalModeAction;
