import {SHOW_LOADING, LoadingActionTypes} from "./types";

export default function loading(state: boolean = false, action: LoadingActionTypes) {
  switch (action.type) {
    case SHOW_LOADING:
      return action.loading;
    default:
      return state;
  }
}
