import React from "react";
import Modal from "react-bootstrap/Modal";

interface InfoMessageProps {
  message: string;
  hide: () => void;
}

export default class InfoMessage extends React.Component<InfoMessageProps> {
  constructor(props: InfoMessageProps) {
    super(props);
    window.setTimeout(props.hide, 3000);
  }
  render() {
    return (
      <Modal show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={this.props.hide}>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <span>{this.props.message}</span>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
