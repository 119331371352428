import React from "react";
import _ from "lodash";
import {DropdownProps} from "./types";

export default function Dropdown(props: DropdownProps) {
  let finalValue = _.isNull(props.value) ? props.placeholder : props.value;
  return (
    <div className={`form-group ${props.class}`}>
      {props.title && <label htmlFor={props.name}>{props.title}</label>}
      <select
        disabled={props.disabled}
        className={`form-control ${props.isValid ? "" : "is-invalid"} rounded-0`}
        id={props.name}
        value={finalValue}
        onChange={e => props.onChange(e.target.value)}
      >
        {props.placeholder && (
          <option disabled hidden selected value={props.placeholder}>
            {props.placeholder}
          </option>
        )}
        {_.map(props.options, item => {
          let value = item;
          let name = item;
          if (typeof item === "object") {
            name = item.description;
            value = item.id || item.value;
          }
          return (
            <option key={value.toString()} value={value.toString()}>
              {name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
