import React from "react";

import "./FileUpload.scss";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FileUploadProps} from "../input/types";

export default function FileUpload(props: FileUploadProps) {
  function getFileFromInput(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = function() {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  }

  function removeFile(): void {
    props.onChange(null);
  }

  const value =
    typeof props.value === "string" &&
    props.value.split("|")[1] + " attached at " + new Date(props.value.split("|")[2]).toLocaleString();
  return (
    <div className={props.uploadAsFile ? "" : "col-12 form-group"}>
      {props.title && <label>{props.title}</label>}
      <div className="file-upload">
        <input
          type="file"
          accept={props.acceptFileTypes || ".cer,.der"}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = _.isObject(e.target.files) && e.target.files[0];
            if (file) {
              if (props.uploadAsFile) {
                props.onChange(file);
                e.target.value = '';
              } else {
                let preparedValue = await getFileFromInput(file).then(result => {
                  return result.split(",")[1];
                });
                const lastModified = new Date().toISOString();
                preparedValue += `|${file.name}|${lastModified}`;
                props.onChange(preparedValue);
              }
            }
          }}
          disabled={props.disabled}
          className="file-input"
          id={props.name}
        />
        <label htmlFor={props.name}>{props.label || "Upload"}</label>
        {!props.uploadAsFile && (
          <label className={value ? "file-label" : ""}>
            {value || "No file attached"}
            {value && (
              <span onClick={removeFile}>
                <FontAwesomeIcon icon="times" className="position-absolute" />
              </span>
            )}
          </label>
        )}
      </div>
    </div>
  );
}
