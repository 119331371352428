import React from "react";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import {ScreenExtensionsProps} from "./types";
import Input from "../../common/input";
import {ConfigurationValueTypes} from "../../common/input/types";
import {ConfigurableLabel, translationLanguages} from "../../../store/screenExtensions/types";
import "./addEditConfigLabel.scss";

export default function AddEditConfigLabel(props: ScreenExtensionsProps) {
  let activeLabelId = props.activeLabel ? props.activeLabel.labelId : "";
  let langInputs = _.map(translationLanguages, (lang, key) => (
    <Input
      isValid={true}
      disabled={false}
      name={key}
      title={lang}
      type="text"
      valueType={ConfigurationValueTypes.STRING}
      value={_.get(props.activeLabel, key, null)}
      key={key}
      onChange={val => props.changeValue(key, val)}
    />
  ));

  return (
    <Modal
      show={props.showAddEditConfigLabelDialog}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        props.changeActiveLabel();
        props.showAddEditConfigLabel(false, false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add translations</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <Input
          isValid={true}
          disabled={props.editMode}
          name="labelId"
          title="LabelId"
          type="text"
          value={activeLabelId}
          valueType={ConfigurationValueTypes.STRING}
          key="active-label-id"
          onChange={val => props.changeValue("labelId", val)}
        />
        {langInputs}
      </Modal.Body>
      <Modal.Footer>
        {props.configLabelErrorMessage.length > 0 && (
          <label className="alert-danger p-2 m-0 mr-3">{props.configLabelErrorMessage}</label>
        )}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            props.changeActiveLabel();
            props.showAddEditConfigLabel(false, false);
          }}
        >
          Cancel
        </button>
        {props.editMode ? (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              props.changeConfigLabel(props.activeLabel as ConfigurableLabel);
            }}
          >
            Save
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-secondary"
            disabled={activeLabelId === ""}
            onClick={() => {
              props.addConfigLabel(props.activeLabel as ConfigurableLabel);
            }}
          >
            Add
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
