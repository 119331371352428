import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {Props} from "./types";

library.add(faSearch);

export default function OptionsPopupSearch(props: Props) {
  return (
    <div className="input-group optionSelectorSearch p-1 mb-3">
      <input
        type="text"
        className="form-control border-right-0 rounded-0"
        aria-label="options query"
        value={props.optionsQuery}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.setOptionsQuery(props.name, e.currentTarget.value);
        }}
      />
      <div className="input-group-append">
        <span className="input-group-text rounded-0 bg-transparent border-left-0">
          <FontAwesomeIcon icon="search" />
        </span>
      </div>
    </div>
  );
}
