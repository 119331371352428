import React from "react";
import _ from "lodash";
import {ConfigurationProps} from "./types";
import {getParts} from "../../../store/configuration/utils";
import EditTab from "./EditTab";
import renderConfigList from "./renderConfigList";

export default function Configurations(props: ConfigurationProps) {
  let {currentConfigPart, currentMetadataPart} = getParts(props);
  if (_.isUndefined(currentMetadataPart) || _.isUndefined(currentConfigPart)) {
    return (
      <div className="alert alert-warning" role="alert">
        No correspondant config part found
      </div>
    );
  }
  const itemsList = _.intersectionBy(currentMetadataPart.settingsMetadata, currentConfigPart.settings, "name");
  const regularConfigItemsList = itemsList.filter(settingMD => !settingMD.system);
  const advancedConfigs = itemsList.filter(settingMD => settingMD.system);
  const tabLocked = _.isUndefined(props.unlockedTabName) || props.unlockedTabName !== currentConfigPart.name;
  return (
    <div className="position-relative">
      <EditTab {...props} />
      {renderConfigList(regularConfigItemsList, props, tabLocked, currentMetadataPart)}
      {!props.showAdvancedOptions && advancedConfigs.length > 0 && (
        <a className="nav-link p-0 mt-3" href="#" onClick={() => props.showAdvancedConfigs(true)}>
          Show advanced configuration options
        </a>
      )}
      {props.showAdvancedOptions && advancedConfigs.length > 0 && (
        <>
          {renderConfigList(advancedConfigs, props, tabLocked, currentMetadataPart)}
          <a className="nav-link p-0 mt-3" href="#" onClick={() => props.showAdvancedConfigs(false)}>
            Hide advanced configuration options
          </a>
        </>
      )}
    </div>
  );
}
