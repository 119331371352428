import React from "react";
import FormItem from "../../../formItem/FormItem";
import {ConfigurationMetadata} from "../../../../store/configuration/types";
import {ValueType} from "../../../common/input/types";
import {IFormData, SaveProviderPayload} from "../types";

import _ from "lodash";

export default function RenderFormList(
  config: ConfigurationMetadata[],
  activeMenuItem: string,
  changeValue: Function,
  dataStorage: IFormData,
  dataSources?: SaveProviderPayload[]
) {
  return (
    <div className="row">
      {config.map((settingMD: ConfigurationMetadata) => {
        let displayed = true;
        if (!!settingMD.activationSettings) {
          displayed = settingMD.activationSettings.every(setting => {
            return setting.values.includes(dataStorage[activeMenuItem][setting.name] as string);
          });
        } else if (!!settingMD.dataSourceTypes && dataSources) {
          const selectedSource = dataSources.find(provider => {
            const currentDataSourceId = dataStorage[activeMenuItem].dataSource;
            return Number(provider.id) === Number(currentDataSourceId);
          });
          // eslint-disable-next-line eqeqeq
          displayed = selectedSource != undefined && settingMD.dataSourceTypes.includes(selectedSource.type as string);
        } else if (!!settingMD.activationSetting) {
          displayed = settingMD.activationSetting.values.includes(dataStorage[activeMenuItem][
            settingMD.activationSetting.name
          ] as string);
        }

        const settingValue = dataStorage[activeMenuItem][settingMD.name];
        const value = settingValue === undefined || !displayed ? null : settingValue;
        const onChange = (val: ValueType) => {
          changeValue(activeMenuItem, settingMD.name, val);
        };
        const disabled =
          (settingMD.widgetType === "DROPDOWN" && !(settingMD.options && settingMD.options.length)) ||
          ((settingMD.name === "conflationPeriod1Unit" || settingMD.name === "calculationType") &&
            !_.isNumber(dataStorage.configureMeterReadings.conflationPeriod1)) ||
          (settingMD.name === "conflationPeriod2Unit" &&
            !_.isNumber(dataStorage.configureMeterReadings.conflationPeriod2)) ||
          (settingMD.name === "certificate" && dataStorage.setupConnection.securityPolicy === "None");

        const isLinearCalculationSelected =
          (settingMD.name !== "linearCalculationOffset" && settingMD.name !== "linearCalculationFactor") ||
          dataStorage.configureMeterReadings.calculationType === "LINEAR";
        const isFullWidth = !settingMD.name.includes("interval");
        return (
          displayed &&
          isLinearCalculationSelected && (
            <FormItem
              key={settingMD.name}
              value={value as ValueType}
              isValid={true}
              onChange={onChange}
              disabled={disabled}
              settingMD={settingMD}
              fullWidth={isFullWidth}
            />
          )
        );
      })}
    </div>
  );
}
