import {
  CHANGE_EDIT_MODE,
  IntegrationsTypes,
  IntegrationsState,
  integrationName,
  SYNC_CONFIGURATION,
  TEST_CONNECTION_START,
  TEST_CONNECTION_PASSED,
  TEST_CONNECTION_FAILURE
} from "../_types/integrations.types";
import {ThunkDispatch} from "redux-thunk";
import getSharedConfiguration from "../../containers/sharedConfiguration";
import {ConfigurationProps} from "../../components/pages/configuration/types";
import {IntegrationsProps} from "../../components/pages/integrations/types";
import _ from "lodash";
import {
  CLEAR_DIRTY,
  GENERIC_REQUEST_FAILURE,
  GENERIC_REQUEST_START,
  SHOW_CONFIRM_CANCEL,
  ConfigurationTypes,
  ConfigurationPart,
  ConfigurationState
} from "../configuration/types";
import {getChangedSettings} from "../configuration/utils";

const initialState: IntegrationsState = {
  editModeIsActive: false,
  testingConnection: false,
  connectionPassed: false,
  testConnectionResult: ""
};

export const actionCreators = (dispatch: ThunkDispatch<{}, {}, any>) => {
  let configuration = getSharedConfiguration(dispatch);
  return {
    ...configuration,
    changeEditMode: (editable: boolean) => {
      dispatch({
        type: CHANGE_EDIT_MODE,
        editable
      });
    },
    clearChanges: (props: ConfigurationProps | IntegrationsProps) => {
      const currentConfigPart = _.find(props.configuration, {
        name: integrationName
      });
      if (!_.isUndefined(currentConfigPart)) {
        dispatch({
          type: CLEAR_DIRTY,
          tabName: currentConfigPart.name
        });
        dispatch({
          type: CHANGE_EDIT_MODE,
          editable: false
        });
      }
      dispatch({
        type: SHOW_CONFIRM_CANCEL,
        show: false
      });
    },
    syncConfiguration: () => {
      dispatch({
        types: [GENERIC_REQUEST_START, SYNC_CONFIGURATION, GENERIC_REQUEST_FAILURE],
        getURL: () => `api/sync`,
        method: "POST"
      });
    },
    testConnection: (configPart: ConfigurationPart, configState: ConfigurationState) => {
      const changedSettings = getChangedSettings(configPart, configState);
      let settingsToTest = configPart.settings.map(setting => {
        const updatedSetting = _.find(changedSettings, s => s.name === setting.name);
        return !!updatedSetting ? updatedSetting : setting;
      });
      dispatch({
        types: [TEST_CONNECTION_START, TEST_CONNECTION_PASSED, TEST_CONNECTION_FAILURE],
        payload: settingsToTest,
        getURL: () => `api/check-cmms-connection`,
        method: "POST"
      });
    }
  };
};
export const reducer = (state = initialState, action: IntegrationsTypes | ConfigurationTypes) => {
  switch (action.type) {
    case CHANGE_EDIT_MODE: {
      return {
        ...state,
        editModeIsActive: action.editable
      };
    }
    case TEST_CONNECTION_START: {
      return {
        ...state,
        testingConnection: true,
        testConnectionResult: ""
      };
    }
    case TEST_CONNECTION_FAILURE: {
      let error = JSON.parse(action.errorMessage);
      return {
        ...state,
        testingConnection: false,
        connectionPassed: false,
        testConnectionResult: (error.data && error.data.message) || action.errorMessage
      };
    }
    case TEST_CONNECTION_PASSED: {
      return {
        ...state,
        testingConnection: false,
        connectionPassed: true,
        testConnectionResult: "Connection is successful"
      };
    }
    default:
      return state;
  }
};
