import {ConfigurationMetadata} from "../configuration/types";
import {Config, SaveProviderPayload} from "../../components/pages/amr/types";

export const getDefaultFormDataState = (config: Config) => {
  const getDefaultValuesForConfig = (configs: ConfigurationMetadata[]) => {
    return configs
      .filter(item => item.defaultValue !== "")
      .reduce(
        (acc, cur) => {
          acc[cur.name] = cur.defaultValue as string;
          return acc;
        },
        {} as {[key: string]: string}
      );
  };

  const meterReadingsConfig = [
    ...config.setupConnectionForMeter,
    ...config.setupDestinationForMeter,
    ...config.setupRulesForMeter
  ];

  return {
    setupConnection: getDefaultValuesForConfig(config.setupConnection),
    setupDestination: getDefaultValuesForConfig(config.setupDestination),
    configureMeterReadings: getDefaultValuesForConfig(meterReadingsConfig)
  };
};

export const updateSecureFieldsForProvider = (provider: SaveProviderPayload, activeMenuItem: string) => {
  const PASSWORD = "someHardcodedPassword";
  let updatedProvider = provider;

  if (provider.properties.securityType === "OAuth2") {
    updatedProvider = {...provider, 
      properties: {...provider.properties,
        refreshToken: PASSWORD,
        clientId: PASSWORD,
        clientSecret: PASSWORD
      }
    }
  } else if (provider.properties.securityType === "Basic" ||
    provider.type === "OPC_UA" ||
    activeMenuItem === "setupDestination") {
    updatedProvider = {...provider, password: PASSWORD}
  }

  return updatedProvider as SaveProviderPayload;
}