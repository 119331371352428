import React from "react";
import _ from "lodash";
import {ConfigurationProps} from "./types";
import {integrationName} from "../../../store/_types/integrations.types";

export default function Tabs(props: ConfigurationProps) {
  if (_.isUndefined(props.metadata)) {
    return null;
  }
  const finalMetadataList = _.filter(props.metadata, metadataPart => metadataPart.name !== integrationName);
  return (
    <ul className="nav border-bottom flex-nowrap">
      {finalMetadataList.map(metadataPart => (
        <li
          key={metadataPart.name}
          className="nav-item"
          onClick={() => {
            if (_.isUndefined(props.unlockedTabName)) {
              props.setConfigPart(metadataPart.name);
            }
          }}
        >
          <a
            className={`nav-link ${
              props.currentConfigPart === metadataPart.name
                ? "active text-dark border-bottom border-dark border-bottom-2px"
                : "text-muted"
            }`}
            href="#"
          >
            {metadataPart.title}
          </a>
        </li>
      ))}
    </ul>
  );
}
