import React, {Suspense} from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {showLoading} from "../store/loading/actions";
import {AppState} from "../store/rootReducer";
import {actionCreators as getAuthenticationActions} from "../store/_reducers/authentication.reducer";
import {
  AUTH_START,
  SYSTEM_SETTINGS_FAILURE,
  SYSTEM_SETTINGS_SUCCESS,
  CMMS_SETTINGS_SUCCESS,
  CMMS_SETTINGS_FAILURE,
  AuthorizationProps
} from "../store/_types/authentication.types";

const appName = process.env.REACT_APP_MAIN_COMPONENT;
const DynamicallyLoaded = React.lazy(() => import(`../components/app/${appName}`));
const DynamicLoader = function(props: AuthorizationProps) {
  return (
    <Suspense fallback={<div>&nbsp;</div>}>
      <DynamicallyLoaded {...props} />
    </Suspense>
  );
};
const mapStateToProps = (state: AppState) => ({
  loading: state.loading,
  ...state.authentication
});
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  let authenticationActions = getAuthenticationActions(dispatch);
  return {
    ...authenticationActions,
    hideLoader: () => {
      dispatch(showLoading(false));
    },
    getSystemSettings: async () => {
      dispatch({
        types: [AUTH_START, SYSTEM_SETTINGS_SUCCESS, SYSTEM_SETTINGS_FAILURE],
        method: "GET",
        getURL: () => `api/system-settings`
      });
    },
    getCmmsSettings: async () => {
      dispatch({
        types: [AUTH_START, CMMS_SETTINGS_SUCCESS, CMMS_SETTINGS_FAILURE],
        method: "GET",
        getURL: () => `api/cmms-settings`
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicLoader);
