import React from "react";
import _ from "lodash";
import {OptionPart, TextPart} from "./types";

export function getFinalListOfOptions(potentialOptions: string[], optionsQuery: string) {
  return optionsQuery.length === 0
    ? potentialOptions
    : potentialOptions.filter(option => option.toLowerCase().includes(optionsQuery.toLowerCase()));
}
export function createInnerText(option: string, queryRegex: RegExp | null): TextPart {
  let currentResults: RegExpExecArray | null = null;
  let textPart: JSX.Element;
  if (!_.isNull(queryRegex)) {
    let optionParts: OptionPart[] = [];
    let currentIndex = 0;
    while ((currentResults = queryRegex.exec(option)) !== null) {
      let preResult = option.substring(currentIndex, currentResults.index);
      if (preResult.length > 0) {
        optionParts.push({val: preResult, isUnderlined: false});
      }
      currentIndex = currentResults.index;
      let currentFoundPart = option.substring(currentIndex, currentIndex + currentResults[0].length);
      optionParts.push({val: currentFoundPart, isUnderlined: true});
      currentIndex = currentIndex + currentResults[0].length;
    }
    let afterResults = option.substring(currentIndex, option.length);
    if (afterResults.length > 0) {
      optionParts.push({val: afterResults, isUnderlined: false});
    }

    textPart = (
      <span>
        {optionParts.map((optionPart, i) =>
          optionPart.isUnderlined ? (
            <span key={i} className="text-underlined">
              {optionPart.val}
            </span>
          ) : (
            optionPart.val
          )
        )}
      </span>
    );
  } else {
    textPart = <span>{option}</span>;
  }
  return {
    text: textPart,
    value: option
  };
}
export function createOuterPart(
  textPart: TextPart,
  selectedOptions: string[],
  selectOption: (name: string, option: string) => void,
  deselectOption: (name: string, option: string) => void,
  name: string
) {
  return (
    <div
      className={`optionItem pl-1 ${_.includes(selectedOptions, textPart.value) ? "active" : ""}`}
      onClick={() => {
        if (_.includes(selectedOptions, textPart.value)) {
          deselectOption(name, textPart.value);
        } else {
          selectOption(name, textPart.value);
        }
      }}
      key={textPart.value}
    >
      {textPart.text}
    </div>
  );
}
