import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "@redux-devtools/extension";
import thunkMiddleware from "redux-thunk";
import {createLogger} from "redux-logger";
import {routerMiddleware} from "connected-react-router";
import monitorReducersEnhancer from "../enhancers/monitorReducer";
import fetchMiddleware from "../middleware/fetchMiddleware";
import rootReducer, {history} from "./rootReducer";

export default function configureStore(preloadedState: any) {
  const loggerMiddleware = createLogger();
  const middlewares = [loggerMiddleware, thunkMiddleware, fetchMiddleware, routerMiddleware(history)];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("./rootReducer", () => {
        const newRootReducer = require("./rootReducer").default;
        store.replaceReducer(newRootReducer);
      });
    }
  }

  return store;
}
