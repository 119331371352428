import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faChevronUp, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {SortOrder} from "../../store/dbReload/types";

library.add(faChevronUp);
library.add(faChevronDown);

interface SortHeaderProps {
  onClick(sortOrder: SortOrder): void;
  active: boolean;
  sortOrder: SortOrder;
  name: string;
  colVal: number;
}

export default function SortHeader(props: SortHeaderProps) {
  return (
    <th
      onClick={() => {
        let newSortOrder: SortOrder = props.sortOrder;
        if (props.active) {
          newSortOrder = props.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        }
        props.onClick(newSortOrder);
      }}
      className={`col-${props.colVal} top-border-yellow pointy font-weight-light`}
    >
      <div
        className={`d-flex justify-content-between pr-3
                ${props.active ? "text-primary" : ""}
              `}
      >
        {props.name}
        {props.active && props.sortOrder === SortOrder.ASC && <FontAwesomeIcon icon="chevron-up" />}
        {props.active && props.sortOrder === SortOrder.DESC && <FontAwesomeIcon icon="chevron-down" />}
      </div>
    </th>
  );
}
