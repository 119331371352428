import {connect} from "react-redux";
import _ from "lodash";
import {ThunkDispatch} from "redux-thunk";
import ScreenExtensions from "../../components/pages/screenExtensions";
import {AppState} from "../../store/rootReducer";
import {
  changeActiveTab,
  showAddEditConfigLabel,
  changeActiveLabel,
  changeValue,
  getConfigLabels,
  addConfigLabel,
  deleteConfigLabel,
  changeConfigLabel,
  getScreenExtensionsConfig,
  selectScreenChange,
  selectButtonScreenChange,
  setActiveScreenBtnsConfig,
  addMenuButtonOnScreen,
  showAddEditConfigField,
  changeConfigValue,
  addConfigurableField,
  getConfigurableFields,
  deleteConfigField,
  deleteConfigButton,
  toggleExpanded,
  editConfigurableField,
  changeActiveConfigFields,
  setActiveConfigButton,
  setConfigButtonToDelete,
  hideInfoMessage
} from "../../store/screenExtensions/actions";
import {fetchSettings, showConfirmCancel} from "../../store/sharedStore/actions";
import {
  ConfigurableLabel,
  ConfigField,
  ActiveConfigField,
  configurableButtonsConfig,
  activeConfigurableButtonConfig
} from "../../store/screenExtensions/types";
import {ValueType} from "../../components/common/input/types";

const mapStateToProps = (state: AppState) => {
  return {...state.screenExtensions, ...state.sharedStore, ...state.authentication};
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    fetchSettings: async () => {
      dispatch(fetchSettings());
    },
    selectScreenChange: (name: string, value: ValueType) => {
      dispatch(selectScreenChange(name, value as string));
    },
    selectButtonScreenChange: (name: string, value: ValueType) => {
      dispatch(selectButtonScreenChange(name, value));
    },
    setActiveScreenBtnsConfig: (config: configurableButtonsConfig) => {
      dispatch(setActiveScreenBtnsConfig(config));
    },
    addMenuButtonOnScreen: async (config: activeConfigurableButtonConfig) => {
      await dispatch(addMenuButtonOnScreen(config));
      dispatch(getConfigurableFields());
    },
    changeActiveTab: (activeTab: string) => {
      dispatch(changeActiveTab(activeTab));
    },
    showAddEditConfigLabel: (show: boolean, editMode: boolean) => {
      dispatch(showAddEditConfigLabel(show, editMode));
    },
    changeActiveLabel: (label?: ConfigurableLabel) => {
      dispatch(changeActiveLabel(label));
    },
    changeValue: (name: string, value: ValueType) => {
      value = _.isArray(value) ? value[0] : value;
      dispatch(changeValue(name, value));
    },
    changeConfigValue: (name: string, value: ValueType) => {
      dispatch(changeConfigValue(name, value as string));
    },
    getConfigLabels: async () => {
      dispatch(getConfigLabels());
    },
    addConfigLabel: async (label: ConfigurableLabel) => {
      await dispatch(addConfigLabel(label));
      dispatch(getConfigLabels());
    },
    changeConfigLabel: async (label: ConfigurableLabel) => {
      await dispatch(changeConfigLabel(label));
      dispatch(getConfigLabels());
    },
    deleteConfigLabel: async (labelId: string) => {
      await dispatch(deleteConfigLabel(labelId));
      dispatch(getConfigLabels());
    },
    showConfirmCancel: (show: boolean) => {
      dispatch(showConfirmCancel(show));
    },
    getScreenExtensionsConfig: async () => {
      dispatch(getScreenExtensionsConfig());
    },
    showAddEditConfigField: (show: boolean, editConfig: boolean) => {
      dispatch(showAddEditConfigField(show, editConfig));
    },
    addConfigurableField: async (configField: ConfigField, screen: string, group: string) => {
      await dispatch(addConfigurableField(configField, screen, group));
      dispatch(getConfigurableFields());
    },
    editConfigurableField: async (configField: ConfigField, screen: string, group: string) => {
      await dispatch(editConfigurableField(configField, screen, group));
      dispatch(getConfigurableFields());
    },
    deleteConfigField: async (config: ActiveConfigField) => {
      await dispatch(deleteConfigField(config));
      dispatch(getConfigurableFields());
    },
    deleteConfigButton: async (config: activeConfigurableButtonConfig) => {
      await dispatch(deleteConfigButton(config));
      dispatch(getConfigurableFields());
    },
    changeActiveConfigFields: (config: ActiveConfigField) => {
      dispatch(changeActiveConfigFields(config));
    },
    setActiveConfigButton: (config: activeConfigurableButtonConfig) => {
      dispatch(setActiveConfigButton(config));
    },
    setConfigButtonToDelete: (config: activeConfigurableButtonConfig) => {
      dispatch(setConfigButtonToDelete(config));
    },
    getConfigurableFields: async () => {
      dispatch(getConfigurableFields());
    },
    toggleExpanded: (key: string, value: boolean) => {
      dispatch(toggleExpanded(key, value));
    },
    hideInfoMessage: () => {
      dispatch(hideInfoMessage());
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenExtensions);
