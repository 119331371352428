import React from "react";
import {ConfigurationPart} from "../../../../store/configuration/types";
import {IntegrationsProps} from "../types";

interface TestConnectionBlockProps {
  currentCP: ConfigurationPart;
}

export default function TestConnectionBlock(props: IntegrationsProps & TestConnectionBlockProps) {
  return (
    <div className="list-group-item">
      <div className="d-flex justify-content-end align-items-start">
        {!!props.testConnectionResult && (
          <div className={"alert alert-" + (!!props.connectionPassed ? "success" : "danger")} role="alert">
            {props.testConnectionResult}
          </div>
        )}
        <button
          className="d-flex ml-auto btn btn-primary"
          disabled={!!props.testingConnection}
          onClick={() => props.testConnection(props.currentCP as ConfigurationPart, props)}
        >
          Test Connection
        </button>
      </div>
    </div>
  );
}
