import React, {useEffect} from "react";
import {AmrProps, DownsamplingConfigItems, FormItem, MeterMappingsPayload} from "../types";
import RenderFormList from "../renderFormList";
import {ValueType} from "../../../common/input/types";

import _ from "lodash";
import ConfirmCancel from "../../../confirmCancel/ConfirmCancel";

interface ICreateUpdateMeter {
  props: AmrProps;
}
export default function CreateUpdateMeter({props}: ICreateUpdateMeter) {
  const dataSource = props.formData.configureMeterReadings.dataSource;
  const dataProvider = props.dataProviders.find(provider => String(provider.id) === dataSource);
  const setupConnection =
    props.formData.configureMeterReadings.objectType === "ALERT"
      ? props.config.setupConnectionForMeter.filter(
          configItem => configItem.name === "dataSource" || configItem.name === "address"
        )
      : props.config.setupConnectionForMeter;
  const setupRules =
    props.formData.configureMeterReadings.objectType === "ALERT" ? [] : props.config.setupRulesForMeter;

  useEffect(() => {
    if (dataProvider && dataProvider.type === "WEB_SERVICE") {
      const {properties} = dataProvider;

      props.updateConfigurations(props.activeMenuItem, {
        dataSource,
        conversionRule: properties.conversionRule,
        meterValueExtractor: properties.meterValueExtractor
      });
    } else if (dataSource) {
      props.updateConfigurations(props.activeMenuItem, {dataSource});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  function isDisabled() {
    const destinationForReadings = props.config.setupDestinationForMeter.filter(
      configItem =>
        !configItem.activationSetting ||
        configItem.activationSetting.values.includes(props.formData.configureMeterReadings[
          configItem.activationSetting!.name
        ] as string)
    );
    const isDestinationNotFilled = _.some(destinationForReadings, destinationItem => {
      return !props.formData.configureMeterReadings[destinationItem.name];
    });
    const activeConnectionForMeterConfigs = setupConnection.filter(item => {
      let displayed = true;
      if (!!item.dataSourceTypes && props.dataProviders) {
        const selectedSource = props.dataProviders.find(provider => {
          const currentDataSourceId = props.formData.configureMeterReadings.dataSource;
          return Number(provider.id) === Number(currentDataSourceId);
        });
        // eslint-disable-next-line eqeqeq
        displayed = selectedSource != undefined && item.dataSourceTypes.includes(selectedSource.type as string);
      }
      return displayed;
    });
    const isConnectionNotFilled = _.some(activeConnectionForMeterConfigs, connectionItem => {
      return !props.formData.configureMeterReadings[connectionItem.name];
    });

    const rulesToCheck = setupRules.filter(
      ruleItem =>
        ruleItem.name !== "linearCalculationOffset" &&
        ruleItem.name !== "linearCalculationFactor" &&
        (ruleItem.name !== "calculationType" || _.isNumber(props.formData.configureMeterReadings.conflationPeriod1)) &&
        ruleItem.name !== "postValueOnChange"
    );
    const isRulesNotFilled = _.isNumber(props.formData.configureMeterReadings.conflationPeriod1)
      ? _.some(rulesToCheck, ruleItem => {
          return (
            _.isUndefined(props.formData.configureMeterReadings[ruleItem.name]) ||
            props.formData.configureMeterReadings[ruleItem.name] === ""
          );
        })
      : false;
    const isLinearRulesEmpty =
      props.formData.configureMeterReadings.calculationType === "LINEAR" &&
      (props.formData.configureMeterReadings["linearCalculationOffset"] === "" ||
        props.formData.configureMeterReadings["linearCalculationFactor"] === "");

    return isDestinationNotFilled || isConnectionNotFilled || isRulesNotFilled || isLinearRulesEmpty;
  }

  function saveClick() {
    const dataProvider = props.dataProviders.find(
      provider => Number(provider.id) === Number(props.formData.configureMeterReadings.dataSource)
    );

    const connectionForMeterConfigs = props.config["setupConnectionForMeter"]
      .filter(item => !!item.dataSourceTypes && item.dataSourceTypes.includes(dataProvider!.type as string))
      .map(el => el.name);
    const customProperties = Object.keys(props.formData.configureMeterReadings).filter(key =>
      connectionForMeterConfigs.includes(key)
    );
    let sourceAttributeProperties: FormItem = {};
    customProperties.forEach(key => {
      sourceAttributeProperties[key] = props.formData.configureMeterReadings[key] as string;
    });
    const {
      address,
      interval,
      intervalType,
      meterValueExtractor,
      conversionRule,
      ...properties
    } = sourceAttributeProperties;
    const meterId = props.formData.configureMeterReadings.meter;
    const downsamplingConfigProperties = setupRules.map(el => el.name) as DownsamplingConfigItems[];
    const downsamplingConfig = _.pick<FormItem, DownsamplingConfigItems>(
      props.formData.configureMeterReadings,
      downsamplingConfigProperties
    );

    const payload: MeterMappingsPayload = {
      sourceAttribute: {
        address,
        interval,
        intervalType,
        sourceSystemId: props.formData.configureMeterReadings.dataSource,
        properties
      },
      destinationAttribute: {
        destinationSystemId: props.formData.configureMeterReadings.type,
        site: {
          id: props.formData.configureMeterReadings.site
        },
        asset: {
          id: props.formData.configureMeterReadings.asset
        },
        ...(meterId && {
          assetMeter: {
            id: meterId
          }
        })
      },
      ...(!_.isEmpty(downsamplingConfig) && {downsamplingConfig})
    };
    if (props.meterMapperId) {
      payload.id = props.formData.configureMeterReadings.id;
      payload.sourceAttribute.id = props.formData.configureMeterReadings.dataSourceId;
      payload.destinationAttribute.id = props.formData.configureMeterReadings.typeId;
      if (payload.downsamplingConfig) {
        payload.downsamplingConfig.id = props.formData.configureMeterReadings.downsamplingConfigId;
      }
    }
    props.saveMeterMappings(payload, props.meterMapperId).then(async () => {
      await props.loadMeterMappings();
      props.loadMeterMappingsBySource(
        props.formData.configureMeterReadings.dataSource as string,
        props.formData.configureMeterReadings.type as string
      );
    });
  }

  function changeMeterOption(activeMenuItem: string, name: string, value: ValueType) {
    const {objectType} = props.formData.configureMeterReadings;
    props.changeMeterOption(activeMenuItem, name, value, objectType);
  }

  async function deleteMeterMapper() {
    await props.deleteMeterMapper(props.meterMapperId as number);
    props.loadMeterMappingsBySource(
      props.formData.configureMeterReadings.dataSource as string,
      props.formData.configureMeterReadings.type as string
    );
  }

  return (
    <>
      {RenderFormList(
        props.config.setupDestinationForMeter,
        props.activeMenuItem,
        changeMeterOption,
        props.formData,
        props.destinations
      )}
      <h5 className="border-bottom border-secondary pb-2 font-weight-bold">Connection</h5>
      {RenderFormList(setupConnection, props.activeMenuItem, props.changeValue, props.formData, props.dataProviders)}
      {setupRules.length ? <h5 className="border-bottom border-secondary pb-2 font-weight-bold">Rules</h5> : null}
      {RenderFormList(setupRules, props.activeMenuItem, props.changeValue, props.formData)}
      {props.createUpdaterErrorMessage && (
        <span className="alert alert-danger d-block">{props.createUpdaterErrorMessage}</span>
      )}
      <div className="SaveCancelContainer d-flex pt-3 pb-3 align-items-center justify-content-end alert-secondary">
        {props.meterMapperId && (
          <button
            onClick={() => {
              props.showDeleteModal();
            }}
            type="button"
            className="btn btn-warning m-1"
          >
            Delete
          </button>
        )}
        <button onClick={saveClick} type="button" disabled={isDisabled()} className="btn btn-warning m-1">
          {props.meterMapperId ? "Update" : "Save"}
        </button>
      </div>
      {props.isDeleteModalShown && (
        <ConfirmCancel
          text="Please confirm that you want to delete selected reading."
          cancelFunc={() => {
            props.showDeleteModal();
          }}
          cancelText="Cancel"
          confirmFunc={() => {
            deleteMeterMapper();
            props.showDeleteModal();
          }}
          confirmText="Confirm to Delete"
        />
      )}
    </>
  );
}
