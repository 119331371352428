import {SHOW_CONFIRM_CANCEL, CO2_SETTINGS_SUCCESS, SharedStoreState, SharedStoreTypes} from "./types";

const defaultSharedStoreState: SharedStoreState = {
  showConfirmationDialog: false,
  fieldSupportConfig: {}
};
export default function sharedStore(state = defaultSharedStoreState, action: SharedStoreTypes) {
  switch (action.type) {
    case SHOW_CONFIRM_CANCEL: {
      return {...state, showConfirmationDialog: action.show};
    }
    case CO2_SETTINGS_SUCCESS: {
      return {
        ...state,
        fieldSupportConfig: action.data
      };
    }
    default:
      return state;
  }
}
