import React from "react";
import {Props} from "./types";

import {createOptions} from "./optionFilterUtility";

export default function OptionsPopupList(props: Props) {
  return (
    <div className="optionList overflow-auto">
      {createOptions(
        props.potentialOptions,
        props.optionsQuery,
        props.selectedOptions,
        props.selectOption,
        props.deselectOption,
        props.name
      )}
    </div>
  );
}
