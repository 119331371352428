import React, {useState} from "react";
import {AmrProps} from "../types";
import MeterList from "../meterList";
import CreateUpdateMeter from "../createUpdateMeter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FileUpload from "../../../common/fileUpload/FileUpload";
import {ConfigurationValueTypes} from "../../../common/input/types";
import {WidgetTypes} from "../../../../store/configuration/types";
import ConfirmCancel from "../../../confirmCancel/ConfirmCancel";
import RenderFormList from "../renderFormList";
import ImportMeterReadingsModal from "../importMeterReadingsModal";

export default function SetupMeterReadings({props}: {props: AmrProps}) {
  const [isConfirmImportModalShown, toggleConfirmImportModal] = useState(false);
  const [file, setFile] = useState<File>();
  const alertDataProviderExists = props.dataProviders.some(provider => provider.objectType === "ALERT");

  const onConfirmImport = () => {
    const {updateExistingStructures} = props.formData.configureMeterReadings;
    const formData = new FormData();
    if (file) {
      formData.append("file", file, file.name);
      formData.append("updateExistingStructures", JSON.stringify(!!updateExistingStructures));
      props.importMetersFromCsv(formData);
      toggleConfirmImportModal(false);
    }
  };

  return props.isConfigureMeterReadings ? (
    <>
      <span
        onClick={() => {
          props.newMeterClick();
        }}
      >
        <FontAwesomeIcon icon="times" className="position-absolute" />
      </span>
      {props.errorFromMeterStatus && (
        <span className="text-danger font-weight-bold alert alert-danger d-block mt-4">
          {props.errorFromMeterStatus}
        </span>
      )}
      <h5 className="font-weight-bold text-center">
        {props.meterMapperId ? "Update" : "Setup"} {props.formData.configureMeterReadings.objectType === "ALERT" ? "Alert" : "Meter"} Readings
      </h5>
      <h5 className="border-bottom border-secondary pb-2 font-weight-bold">Destination</h5>
      <CreateUpdateMeter props={props} />
    </>
  ) : (
    <>
      <h5 className="font-weight-bold text-center">Meter / Alert readings configurations</h5>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <button
          onClick={() => {
            props.newMeterClick();
          }}
          className="btn btn-warning"
        >
          New Meter Reading
        </button>
        </div>
        <div className="d-flex">
          <FileUpload
            name="importMeters"
            label="Import Meters"
            uploadAsFile
            type={WidgetTypes.FILE}
            valueType={ConfigurationValueTypes.FILE}
            isValid={true}
            disabled={false}
            value={null}
            acceptFileTypes=".csv"
            onChange={file => {
              setFile(file as File);
              toggleConfirmImportModal(true);
            }}
          />
            <button type="button" className="btn btn-warning m-1" onClick={props.exportTemplate}>
              Export Template
            </button>
        </div>
        {isConfirmImportModalShown && (
          <ConfirmCancel
            text="New meters will be imported automatically. Are you sure you want to proceed?"
            cancelFunc={() => {
              toggleConfirmImportModal(false);
            }}
            cancelText="Cancel"
            confirmFunc={onConfirmImport}
            confirmText="Confirm to Import"
            bodyComponent={RenderFormList(
              props.config.setupImportForMeterConfigurations,
              props.activeMenuItem,
              props.changeValue,
              props.formData
            )}
          />
        )}
        <ImportMeterReadingsModal
          show={props.isImportResultModalShown}
          results={props.importMeterReadingsResults}
          onClose={props.toggleImportResultsModal}
          fileName={file && file.name}
        />
      </div>
        {alertDataProviderExists ? (
          <div className="mb-3">
            <button
              onClick={() => {
                props.newAlertClick();
              }}
              className="btn btn-warning"
            >
              New Alert Reading
            </button>
          </div>
        ) : null}
      {MeterList(props)}
    </>
  );
}
