import React from "react";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Props} from "./types";

library.add(faTimes);

export default function SelectedAttributesList(props: Props) {
  return (
    <>
      {props.selectedOptions.length === 0 && (
        <button className="btn btn-warning text-white">{props.noneSelectedLabel}</button>
      )}
      {_.isArray(props.selectedOptions) && (
        <ul className="nav nav-pills">
          {props.selectedOptions.map((option: string) => {
            return (
              <li key={option} className="nav-item mr-2 mb-2">
                <a className="nav-link active" href="#">
                  <span className="mr-1">{option}</span>
                  <span
                    className="deselect"
                    onClick={() => {
                      props.deselectOption(props.name, option);
                    }}
                  >
                    <FontAwesomeIcon icon="times" />
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
