import Joi from "joi";
import {ValueType, ConfigurationValueTypes} from "../../components/common/input/types";
import {Options} from "../../components/pages/configuration/widgets/types";

export enum WidgetTypes {
  TEXT = "TEXT",
  PASSWORD = "PASSWORD",
  FILE = "FILE",
  NUMBER = "NUMBER",
  MULTISELECT = "MULTISELECT",
  DROPDOWN = "DROPDOWN",
  SLIDER = "SLIDER",
  TEXTAREA = "TEXTAREA",
  TIMEOUT = "TIMEOUT"
}
export enum StructureTypes {
  SINGLE = "SINGLE",
  LIST = "LIST"
}
export const ValueTypeToWidgetTypeMap = {
  [ConfigurationValueTypes.STRING]: [
    WidgetTypes.TEXT,
    WidgetTypes.DROPDOWN,
    WidgetTypes.MULTISELECT,
    WidgetTypes.TIMEOUT
  ],
  [ConfigurationValueTypes.INTEGER]: [
    WidgetTypes.NUMBER,
    WidgetTypes.DROPDOWN,
    WidgetTypes.MULTISELECT,
    WidgetTypes.TIMEOUT
  ],
  [ConfigurationValueTypes.DECIMAL]: [WidgetTypes.NUMBER, WidgetTypes.DROPDOWN, WidgetTypes.MULTISELECT],
  [ConfigurationValueTypes.FILE]: [WidgetTypes.FILE],
  [ConfigurationValueTypes.BOOLEAN]: [WidgetTypes.SLIDER],
  [ConfigurationValueTypes.DATETIME]: [WidgetTypes.TEXT],
  [ConfigurationValueTypes.DATE]: [WidgetTypes.TEXT],
  [ConfigurationValueTypes.PASSWORD]: [WidgetTypes.PASSWORD]
};
export const StructureTypesToWidgetTypeMap = {
  [StructureTypes.SINGLE]: [
    WidgetTypes.TEXT,
    WidgetTypes.DROPDOWN,
    WidgetTypes.NUMBER,
    WidgetTypes.SLIDER,
    WidgetTypes.PASSWORD,
    WidgetTypes.TIMEOUT
  ],
  [StructureTypes.LIST]: [WidgetTypes.MULTISELECT]
};
export interface ViewSetting {
  value: ValueType;
  isDirty: boolean;
  isValid: boolean;
}
export interface ViewSettings {
  [propName: string]: ViewSetting;
}

export interface ActivationSetting {
  name: string;
  values: string[];
}

export interface Warning {
  message: string;
  values: string[];
}

export interface ConfigurationMetadata {
  activationSettings?: ActivationSetting[];
  activationSetting?: ActivationSetting;
  dataSourceTypes?: string[];
  defaultValue?: ValueType;
  description?: string;
  name: string;
  options?: string[] | number[] | Options[];
  structureType: StructureTypes;
  required?: boolean;
  title: string;
  valueType: ConfigurationValueTypes;
  widgetType: WidgetTypes;
  system?: boolean;
  placeholder?: string;
  minValue?: number;
  warning?: Warning;
  enabledSetting?: string;
}
export interface ConfigurationMetadataPart {
  name: string;
  title: string;
  settingsMetadata: ConfigurationMetadata[];
}
export interface ConfigurationSetting {
  id?: number;
  name: string;
  value: ValueType;
}
export interface ConfigurationPart {
  id?: number;
  name: string;
  settings: ConfigurationSetting[];
}

export interface ConfigurationState {
  currentConfigPart?: string;
  configuration?: ConfigurationPart[];
  metadata?: ConfigurationMetadataPart[];
  errorMessage: string;
  viewSettings: {
    [propName: string]: ViewSetting;
  };
  unlockedTabName?: string;
  showConfirmationDialog: boolean;
  showAdvancedOptions: boolean;
}

export const ConfigurationMetaResponseSchema = Joi.array().items(
  Joi.object().keys({
    name: Joi.string().required(),
    title: Joi.string().required(),
    settingsMetadata: Joi.array()
      .items(
        Joi.object().keys({
          defaultValue: Joi.alternatives()
            .try(
              Joi.number(),
              Joi.string().allow(""),
              Joi.boolean(),
              Joi.array().items(Joi.string()),
              Joi.array().items(Joi.number())
            )
            .allow(null),
          warning: Joi.object({
            message: Joi.string().allow(null),
            values: Joi.array().items(Joi.string(), Joi.number(), Joi.boolean())
          }).allow(null),
          description: Joi.string()
            .allow(null)
            .allow(""),
          name: Joi.string().required(),
          structureType: Joi.string().valid("LIST", "SINGLE"),
          options: Joi.alternatives()
            .try(
              Joi.array().items(Joi.string()),
              Joi.array().items(Joi.number()),
              Joi.array().items(
                Joi.object().keys({
                  value: Joi.alternatives().try(
                    Joi.string()
                      .allow("")
                      .required(),
                    Joi.number().required()
                  ),
                  description: Joi.string().required()
                })
              )
            )
            .allow(null),
          required: Joi.boolean(),
          system: Joi.boolean(),
          title: Joi.string().required(),
          valueType: Joi.any()
            .valid("STRING", "INTEGER", "DECIMAL", "BOOLEAN", "DATETIME", "DATE", "PASSWORD")
            .required(),
          widgetType: Joi.any()
            .valid("TEXT", "PASSWORD", "NUMBER", "MULTISELECT", "DROPDOWN", "SLIDER", "TIMEOUT")
            .required(),
          availableInScript: Joi.boolean().required(),
          enabledSetting: Joi.string().allow(null).required()
        })
      )
      .required()
  })
);

export const ConfigurationResponseSchema = Joi.array().items(
  Joi.object().keys({
    name: Joi.string().required(),
    settings: Joi.array()
      .items(
        Joi.object().keys({
          name: Joi.string().required(),
          value: Joi.alt(
            Joi.string().allow(""),
            Joi.number(),
            Joi.boolean(),
            Joi.array().items(Joi.string()),
            Joi.array().items(Joi.number())
          ).allow(null)
        })
      )
      .required()
  })
);

export const CONFIGURATION_SUCCESS = "CONFIGURATION_SUCCESS";
interface ConfigurationSuccessAction {
  type: typeof CONFIGURATION_SUCCESS;
  data: ConfigurationPart[];
}
export const CONFIGURATION_METADATA_SUCCESS = "CONFIGURATION_METADATA_SUCCESS";
interface ConfigurationMetadataSuccessAction {
  type: typeof CONFIGURATION_METADATA_SUCCESS;
  data: ConfigurationMetadataPart[];
}

export const GENERIC_REQUEST_START = "GENERIC_REQUEST_START";
interface GenericRequestStartAction {
  type: typeof GENERIC_REQUEST_START;
}
export const GENERIC_REQUEST_FAILURE = "GENERIC_REQUEST_FAILURE";
interface GenericRequestFailAction {
  type: typeof GENERIC_REQUEST_FAILURE;
  errorMessage: string;
}

export const TAB_LOCK_CHANGE = "TAB_LOCK_CHANGE";
interface TabLockChangeAction {
  type: typeof TAB_LOCK_CHANGE;
  tabName: string;
  locked: boolean;
}

export const SHOW_CONFIRM_CANCEL = "SHOW_CONFIRM_CANCEL";
interface ShowConfirmCancelAction {
  type: typeof SHOW_CONFIRM_CANCEL;
  show: boolean;
}

export const SHOW_ADVANCED_CONFIGS = "SHOW_ADVANCED_CONFIGS";
interface ShowAdvancedConfigsAction {
  type: typeof SHOW_ADVANCED_CONFIGS;
  show: boolean;
}

export const SELECT_CONFIG_PART = "SELECT_CONFIG_PART";
interface SelectConfigPartAction {
  type: typeof SELECT_CONFIG_PART;
  part: string;
}

export const SAVE_CHANGES_SUCCESS = "SAVE_CHANGES_SUCCESS";
interface SaveChangesSuccessAction {
  type: typeof SAVE_CHANGES_SUCCESS;
}

export const CHANGE_VALUE = "CHANGE_VALUE";
interface ChangeValueAction {
  type: typeof CHANGE_VALUE;
  mdPart: ConfigurationMetadataPart;
  name: string;
  value: ValueType;
}

export const CLEAR_DIRTY = "CLEAR_DIRTY";
interface ClearDirtyAction {
  type: typeof CLEAR_DIRTY;
  tabName: string;
}
export type ConfigurationTypes =
  | GenericRequestStartAction
  | GenericRequestFailAction
  | ConfigurationMetadataSuccessAction
  | ConfigurationSuccessAction
  | ChangeValueAction
  | ClearDirtyAction
  | ShowAdvancedConfigsAction
  | SaveChangesSuccessAction
  | SelectConfigPartAction
  | TabLockChangeAction
  | ShowConfirmCancelAction;
