import React, {useState, useEffect} from "react";
import Input from "../input";
import Dropdown from "../../pages/configuration/widgets/Dropdown";
import {TimeoutProps, ValueType} from "../input/types";
import "./TimeOutUnit.scss";
import _ from "lodash";

export default function TimeoutUnitContainer(props: TimeoutProps) {
  const [currVal, setCurrVal] = useState("");
  const [input, setInput] = useState(props.value);

  const unitOptions = [
    {value: "Ms", description: "Milliseconds"},
    {value: "Sec", description: "Seconds"},
    {value: "Min", description: "Minutes"},
    {value: "Hrs", description: "Hours"}
  ];

  const onLoadConvertor = (val: string | null | number) => {
    if (!_.isNull(val) && !_.isString(val)) {
      if (val % 3.6e6 === 0) {
        setInput(val / 3.6e6);
        return "Hrs";
      } else if (val % 60000 === 0) {
        setInput(val / 60000);
        return "Min";
      } else if (val % 1000 === 0) {
        setInput(val / 1000);
        return "Sec";
      } else {
        setInput(val);
        return "Ms";
      }
    }
    return "Ms";
  };

  useEffect(() => {
    const dropdownValue = onLoadConvertor(props.value);
    setCurrVal(dropdownValue);
  }, []);

  const transformValue = (unit: string, value: number) => {
    let result = 0;
    if (unit === "Ms") {
      result = Number(value);
    }
    if (unit === "Sec") {
      result = Number(value) * 1000;
    }
    if (unit === "Min") {
      result = Number(value) * 60000;
    }
    if (unit === "Hrs") {
      result = Number(value) * 3.6e6;
    }
    return result;
  };

  const onChange = (value: ValueType) => {
    setCurrVal(String(value));
    props.onChange(transformValue(String(value), Number(input)), props.name);
  };

  return (
    <div className="d-flex align-items-end">
      <Input
        {...props}
        name={props.name}
        isValid={props.isValid}
        disabled={props.disabled}
        type={props.type}
        value={input}
        key={props.name}
        onChange={(value: ValueType) => {
          props.onChange(transformValue(currVal, Number(value)), props.name);
          setInput(Number(value));
        }}
        class="specificMargin col-lg-8 col-7 p-0"
      />
      <Dropdown
        {...props}
        isValid={true}
        disabled={props.disabled}
        title={""}
        options={unitOptions}
        value={currVal}
        onChange={onChange}
        class="col-lg-4 col-5"
      />
    </div>
  );
}
