import React from "react";
import {ScreenExtensionsProps} from "./types";
import Breadcrumbs from "../../breadcrumbs";
import MainContentScroll from "../../mainContentScroll";
import ConfigurableLabels from "./configurableLabels";
import ConfigurableFields from "./configurableFields";
import ConfigurableButtons from "./configurableButtons";
import AddEditConfigLabel from "./AddEditConfigLabel";
import AddConfigurableField from "./configurableFields/addConfigurableField";
import InfoMessage from "../../common/infoMessage";

class ScreenExtensionsPage extends React.Component<ScreenExtensionsProps> {
  constructor(props: ScreenExtensionsProps) {
    super(props);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
  }

  hideInfoMessage() {
    this.props.hideInfoMessage();
  }
  componentDidMount() {
    this.props.getConfigLabels();
    this.props.getScreenExtensionsConfig();
    this.props.fetchSettings();
    this.props.getConfigurableFields();
    this.props.changeActiveTab("configurable_fields");
  }

  render() {
    return (
      <>
        {this.props.showInfoMessage && (
          <InfoMessage message="DB should be reloaded after custom field was added" hide={this.hideInfoMessage} />
        )}
        <AddEditConfigLabel {...this.props} />
        <AddConfigurableField {...this.props} />
        <Breadcrumbs />
        <MainContentScroll>
          <ul className="nav">
            <li
              key="configurable_fields"
              className="nav-item configurable-fields"
              onClick={() => {
                this.props.changeActiveTab("configurable_fields");
              }}
            >
              <a
                className={`nav-link configuration-tab ${
                  this.props.activeTab === "configurable_fields"
                    ? "active text-dark configuration-tab-active"
                    : "text-muted"
                }`}
                href="#"
              >
                {process.env.REACT_APP_ENABLE_APP_DESIGNER === "true" ? "Screen Designer" : "Configurable Fields"}
              </a>
            </li>
            <li
              key="configurable_labels"
              className="nav-item configurable-labels"
              onClick={() => {
                this.props.changeActiveTab("configurable_labels");
              }}
            >
              <a
                className={`nav-link configuration-tab ${
                  this.props.activeTab === "configurable_labels"
                    ? "active text-dark configuration-tab-active"
                    : "text-muted"
                }`}
                href="#"
              >
                Configurable Labels
              </a>
            </li>
            {/*<li*/}
            {/*  key="configurable_buttons"*/}
            {/*  className="nav-item configurable-butons"*/}
            {/*  onClick={() => {*/}
            {/*    this.props.changeActiveTab("configurable_buttons");*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <a*/}
            {/*    className={`nav-link configuration-tab ${*/}
            {/*      this.props.activeTab === "configurable_buttons"*/}
            {/*        ? "active text-dark configuration-tab-active"*/}
            {/*        : "text-muted"*/}
            {/*    }`}*/}
            {/*    href="#"*/}
            {/*  >*/}
            {/*    Configurable Buttons*/}
            {/*  </a>*/}
            {/*</li>*/}
          </ul>
          {this.props.activeTab === "configurable_fields" && (
            <ConfigurableFields key="configurable-fields" {...this.props} />
          )}
          {this.props.activeTab === "configurable_labels" && (
            <ConfigurableLabels key="configurable-labels" {...this.props} />
          )}
          {this.props.activeTab === "configurable_buttons" && (
            <ConfigurableButtons key="configurable-buttons" {...this.props} />
          )}
        </MainContentScroll>
      </>
    );
  }
}

export default ScreenExtensionsPage;
