import React from "react";
import _ from "lodash";
import {TextareaProps} from "../input/types";

export default function Textarea(props: TextareaProps) {
  let finalValue = _.isNull(props.value) ? "" : props.value;
  return (
    <div className="form-group">
      <label htmlFor={props.name}>{props.title}</label>
      <textarea
        value={finalValue}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          props.onChange(e.currentTarget.value);
        }}
        disabled={props.disabled}
        className={`form-control rounded-0 ${props.isValid ? "" : "is-invalid"}`}
        id={props.name}
        placeholder={props.placeholder}
      />
      <small className="form-text text-muted">{props.description}</small>
    </div>
  );
}
