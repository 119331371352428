import React from "react";
import {AmrMenuItem, AmrProps, FormItem, SaveProviderPayload} from "../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export default function amrMenu(props: AmrProps) {
  function getIcon(configItem: SaveProviderPayload, itemMenu: AmrMenuItem, activeFormData: FormItem) {
    const isSelected = activeFormData && activeFormData.name === configItem.name && props.isEditable;
    return (
      <span
        className="m-2 d-flex flex-column align-items-center cursor-pointer"
        key={configItem.name}
        onClick={() => {
          props.selectProvider(configItem, itemMenu.name);
        }}
      >
        <FontAwesomeIcon icon={itemMenu.icon as IconProp} size="5x" className={isSelected ? "selected" : ""} />
        <p>{configItem.name}</p>
      </span>
    );
  }

  return (
    <div className={`amr-menu col-4 ${
      ["setupConnection", "setupDestination"].includes(props.activeMenuItem) ? "edit-mode-active" : ""
    }`}>
      {props.amrMenu.map((itemMenu: AmrMenuItem) => {
        const activeFormData = props.formData[props.activeMenuItem as "setupDestination" | "setupConnection"];
        const isSelected = props.activeMenuItem === itemMenu.name && activeFormData && !props.isEditable;
        return (
          <div
            className="d-flex align-items-center border-bottom mb-3 flex-wrap justify-content-center col"
            key={itemMenu.title}
          >
            {itemMenu.name === "setupConnection" &&
              props.dataProviders.map(provider => getIcon(provider, itemMenu, activeFormData))}
            {itemMenu.name === "setupDestination" &&
              props.destinations.map(destination => getIcon(destination, itemMenu, activeFormData))}
            <span
              className="m-2 d-flex flex-column align-items-center cursor-pointer position-relative"
              onClick={() => {
                props.menuClick(itemMenu.name);
              }}
            >
              <FontAwesomeIcon
                className={isSelected ? "selected position-absolute" : "position-absolute"}
                icon="plus"
              />
              <FontAwesomeIcon className={isSelected ? "selected" : ""} icon={itemMenu.icon as IconProp} size="5x" />
              <p>{itemMenu.title}</p>
            </span>
          </div>
        );
      })}
    </div>
  );
}
