export type SingleValueType = string | number | boolean | File | null;

export type ValueType = SingleValueType | string[] | number[] | File | null;

export enum ConfigurationValueTypes {
  STRING = "STRING",
  INTEGER = "INTEGER",
  DECIMAL = "DECIMAL",
  FILE = "FILE",
  BOOLEAN = "BOOLEAN",
  DATETIME = "DATETIME",
  DATE = "DATE",
  PASSWORD = "PASSWORD"
}

export default interface WidgetProps {
  onChange: (value: ValueType, name?: string) => void;
  description?: string;
  name: string;
  title?: string;
  disabled: boolean;
  isValid: boolean;
  placeholder?: string;
  minValue?: number;
  label?: string;
}

export type InputProps = WidgetProps & {
  type: string;
  valueType: ConfigurationValueTypes;
  value: string | null | number;
  class?: string;
};

export type SwitcherProps = WidgetProps & {
  type: string;
  value: boolean | null;
};

export type DropdownProps = WidgetProps & {
  value: string | number | null;
  options: string[] | number[];
};
export type MultiSelectProps = WidgetProps & {
  value: string[] | number[] | null;
  options: string[] | number[];
};

export type TextareaProps = WidgetProps & {
  valueType: ConfigurationValueTypes;
  value: string | null | number;
};

export type FileUploadProps = InputProps & {
  acceptFileTypes?: string;
  uploadAsFile?: boolean;
};

export type TimeoutProps = WidgetProps & InputProps & DropdownProps;
