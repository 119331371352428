import React from "react";
import OptionFilter from "../../optionFilter";
import Controls from "./Controls";
import DBReloadStatusBar from "./DBReloadStatusBar";
import ComponentsTable from "./ComponentsTable";
import Breadcrumbs from "../../breadcrumbs";
import MainContentScroll from "../../mainContentScroll";
import AppVersions from "./AppVersions";
import DBReloadPageProps from "./types";
import "./dbReload.scss";

class DBReloadPage extends React.Component<DBReloadPageProps> {
  componentDidMount() {
    this.props.loadData();
  }
  render() {
    return (
      <>
        <Breadcrumbs />
        <MainContentScroll>
          <div>
            {this.props.filterAttributes.map(attribute => {
              return (
                <OptionFilter
                  key={attribute.name}
                  name={attribute.name}
                  title={attribute.title}
                  noneSelectedLabel="All Options"
                  optionLabel={attribute.name}
                  optionsQuery={attribute.attributeQuery}
                  potentialOptions={attribute.options}
                  selectedOptions={attribute.selectedOptions}
                  optionSelectorOpened={attribute.selectorOpened}
                  selectOption={this.props.selectFilterAttribute}
                  deselectOption={this.props.deselectFilterAttribute}
                  openOptionSelector={this.props.openFilterAttributeSelector}
                  setOptionsQuery={this.props.setAttributeQuery}
                />
              );
            })}
          </div>
          <div className="mt-3 mb-3">
            <Controls {...this.props} />
          </div>
          <DBReloadStatusBar {...this.props} />
          <ComponentsTable {...this.props} />
          <AppVersions {...this.props} />
        </MainContentScroll>
      </>
    );
  }
}

export default DBReloadPage;
