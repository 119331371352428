import _ from "lodash";
import {Middleware, MiddlewareAPI, Dispatch} from "redux";
import {showLoading} from "../store/loading/actions";
import {AppState} from "../store/rootReducer";
import Joi from "joi";
import download from "downloadjs";

interface FetchAction {
  types: string[];
  method?: string;
  getURL: (state: AppState) => string;
  shouldPerformRequest?: (state: AppState) => boolean;
  payload?: Object;
  showPopup?: boolean;
  responseSchema?: Joi.AnySchema;
  failSchema?: Joi.AnySchema;
  actionData: {[key: string]: string};
  contentType?: string;
}
const fetchMiddleware: Middleware<Dispatch, AppState> = ({dispatch, getState}: MiddlewareAPI) => {
  return (next: Dispatch) => (action: FetchAction) => {
    const {
      types,
      getURL,
      shouldPerformRequest,
      responseSchema,
      payload = {},
      showPopup = true,
      method = "GET",
      actionData,
      contentType
    } = action;

    if (!_.isArray(types)) {
      // other action type: pass it on
      return next(action as any);
    }

    Joi.assert(
      types,
      Joi.array()
        .items(Joi.string())
        .length(3),
      "Invalid types"
    );
    Joi.assert(getURL, Joi.func(), 'Invalid "getURL" type');
    Joi.assert(
      shouldPerformRequest,
      Joi.alternatives()
        .try(Joi.func())
        .allow(null),
      'Invalid "getURL" type'
    );

    if (_.isFunction(shouldPerformRequest) && !shouldPerformRequest(getState())) {
      return;
    }

    const [requestType, successType, failureType] = types;

    if (showPopup) {
      dispatch(showLoading(true));
    }
    dispatch({type: requestType});

    const defaultHeaders = {
      "Content-Type": "application/json;charset=UTF-8"
    };

    let options: {
      method: string;
      body?: string | FormData;
      headers: {[propName: string]: string};
    } = {
      method,
      headers: contentType !== "multipart/form-data" ? defaultHeaders : {}
    };
    if (method === "POST" || method === "PUT") {
      if (contentType === "multipart/form-data") {
        options.body = payload as FormData;
      } else {
        options.body = JSON.stringify(payload);
      }
    }
    return fetch(getURL(getState()), options)
      .then(response => {
        const contentType = response.headers.get("Content-Type");
        if (response.status === 401) {
          localStorage.removeItem("userInfo");
        }
        if (contentType === "text/csv" && response.ok) {
          const contentDisposition = response.headers.get("Content-Disposition");
          let fileName = "";
          if (contentDisposition) {
            const fileNameRegExMatch = contentDisposition.match(/(?<=filename=).*/);
            fileName = fileNameRegExMatch ? fileNameRegExMatch[0] : "file.csv";
          }
          let data;
          response.blob().then(blob => {
            data = blob;
            download(blob, fileName);
          });
          return {data, isOk: response.ok};
        }
        return response.text().then(text => {
          return text.length > 0
            ? {
                data: JSON.parse(text),
                isOk: response.ok
              }
            : {
                data: {},
                isOk: response.ok
              };
        });
      })
      .then(parsedResponse => {
        if (showPopup) {
          dispatch(showLoading(false));
        }
        if (parsedResponse.isOk) {
          if (!_.isUndefined(responseSchema)) {
            const {error} = responseSchema.validate(parsedResponse.data, {convert: false});
            if (!error) {
              dispatch({
                type: successType,
                data: parsedResponse.data,
                actionData
              });
            } else {
              throw error;
            }
          } else {
            dispatch({
              type: successType,
              data: parsedResponse.data,
              actionData
            });
          }
        } else {
          dispatch({
            type: failureType,
            errorMessage: JSON.stringify(parsedResponse)
          });
        }
      })
      .catch((error: {message: string}) => {
        if (showPopup) {
          dispatch(showLoading(false));
        }
        dispatch({type: failureType, errorMessage: error.message});
      });
  };
};
export default fetchMiddleware;
