import React from "react";
import _ from "lodash";
import DBReloadPageProps from "./types";
import {ReloadStatuses} from "../../../store/dbReload/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faBan, faCheck, faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import "./dbReload.scss";

library.add(faBan, faCheck, faInfoCircle, faTimes);

export default function DBReloadStatusBar(props: DBReloadPageProps) {
  let closeButton = (
    <button
      onClick={() => {
        props.hideReloadStatusInfo();
      }}
      className="btn times-button"
    >
      <FontAwesomeIcon icon="times" />
    </button>
  );
  return (
    <>
      {props.finalLoadStatus === ReloadStatuses.ERROR && props.errorMessage.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <FontAwesomeIcon icon="ban" className="result-icon mr-3" />
          {props.errorMessage}
        </div>
      )}
      {props.reloadStatusInfoShown && props.finalLoadStatus === ReloadStatuses.DONE && (
        <div className="alert alert-success" role="alert">
          <span>
            <FontAwesomeIcon icon="check" className="result-icon mr-3" />
          </span>
          <span>
            DB was successfully updated
            {!!props.lastReloadDate && ` on ${new Date(props.lastReloadDate).toLocaleString()}`}
            {closeButton}
          </span>
        </div>
      )}
      {props.reloadStatusInfoShown &&
        (props.finalLoadStatus === ReloadStatuses.IN_PROGRESS || props.finalLoadStatus === undefined) && (
          <div className="alert alert-primary" role="alert">
            <FontAwesomeIcon icon="info-circle" className="result-icon mr-3" />
            DB is updating
            {closeButton}
          </div>
        )}
    </>
  );
}
