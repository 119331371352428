import {
  ACTIVE_MENU_CLICK,
  CHANGE_FORM_VALUE,
  DATA_PROVIDERS_SUCCESS,
  DESTINATION_SUCCESS,
  SELECT_PROVIDER,
  SAVE_PROVIDER,
  METER_MAPPINGS_DESTINATION_SUCCESS,
  METER_MAPPINGS_BY_SOURCE_SUCCESS,
  METER_STATUSES_SUCCESS,
  TOGGLE_METER_TREE_VIEW,
  GET_SITES,
  GET_ASSETS,
  GET_METERS,
  SAVE_METER_READINGS,
  NEW_METER_CLICK,
  SAVE_METER_READINGS_START,
  SAVE_METER_READINGS_FAILURE,
  DELETE_METER_MAPPER,
  GET_METER_BY_ID,
  DELETE_PROVIDER,
  METER_STATUS_REQUEST_START,
  METER_STATUS_REQUEST_FAILURE,
  UPDATE_CONFIGURATIONS,
  TOGGLE_IMPORT_RESULTS_MODAL,
  IMPORT_METERS_SUCCESS,
  NEW_ALERT_CLICK,
  EXPORT_TEMPLATE,
  ASSETS_FIELD_CONFIGS_SUCCESS
} from "./types";
import {ValueType} from "../../components/common/input/types";
import {GENERIC_REQUEST_FAILURE, GENERIC_REQUEST_START} from "../configuration/types";
import {MeterMappingsPayload, SaveProviderPayload} from "../../components/pages/amr/types";
import {AppState} from "../rootReducer";

export function menuClick(activeMenuItem: string) {
  return {
    type: ACTIVE_MENU_CLICK,
    activeMenuItem
  };
}

export function changeValue(activeMenuItem: string, name: string, value: ValueType) {
  return {
    type: CHANGE_FORM_VALUE,
    activeMenuItem,
    name,
    value
  };
}

export function selectProvider(provider: SaveProviderPayload, activeMenuItem: string) {
  return {
    type: SELECT_PROVIDER,
    provider,
    activeMenuItem
  };
}

export function saveProvider(payload: SaveProviderPayload, isDataProvider: boolean, isEditable: boolean) {
  return {
    types: [GENERIC_REQUEST_START, SAVE_PROVIDER, GENERIC_REQUEST_FAILURE],
    payload,
    getURL: () => {
      let url = isDataProvider ? `api/source-systems` : `api/destination-systems`;
      url = isEditable ? `${url}/${payload.id}` : url;
      return url;
    },
    method: isEditable ? "PUT" : "POST"
  };
}

export function deleteProvider(deleteProviderId: number, isDataProvider: boolean) {
  return {
    types: [GENERIC_REQUEST_START, DELETE_PROVIDER, GENERIC_REQUEST_FAILURE],
    getURL: () => {
      return isDataProvider ? `api/source-systems/${deleteProviderId}` : `api/destination-systems/${deleteProviderId}`;
    },
    showPopup: true,
    method: "DELETE"
  };
}

export function loadDataProviders() {
  return {
    types: [GENERIC_REQUEST_START, DATA_PROVIDERS_SUCCESS, GENERIC_REQUEST_FAILURE],
    getURL: () => `api/source-systems`
  };
}

export function loadDestinations() {
  return {
    types: [GENERIC_REQUEST_START, DESTINATION_SUCCESS, GENERIC_REQUEST_FAILURE],
    getURL: () => `api/destination-systems`
  };
}

export function loadMeterMappings() {
  return {
    types: [GENERIC_REQUEST_START, METER_MAPPINGS_DESTINATION_SUCCESS, GENERIC_REQUEST_FAILURE],
    getURL: () => "api/meter-mappings/destinations"
  };
}

export function loadMeterStatuses() {
  return {
    types: [METER_STATUS_REQUEST_START, METER_STATUSES_SUCCESS, METER_STATUS_REQUEST_FAILURE],
    getURL: () => "api/meter-statuses",
    showPopup: false
  };
}

export function loadMeterMappingsBySource(sourceSystemId: string, destinationSystemId: string) {
  return {
    types: [GENERIC_REQUEST_START, METER_MAPPINGS_BY_SOURCE_SUCCESS, GENERIC_REQUEST_FAILURE],
    getURL: () => `api/meter-mappings?sourceSystemId=${sourceSystemId}&destinationSystemId=${destinationSystemId}`,
    actionData: {
      sourceSystemId,
      destinationSystemId
    }
  };
}

export function toggleExpanded(key: string, value: boolean) {
  return {
    type: TOGGLE_METER_TREE_VIEW,
    key,
    value
  };
}

export function loadDataById(activeMenuItem: string, name: string, objectType?: string) {
  if (name === "type") {
    return {
      types: [GENERIC_REQUEST_START, GET_SITES, GENERIC_REQUEST_FAILURE],
      getURL: (state: AppState) => {
        const typeId = state.amr.formData.configureMeterReadings.type;
        return `api/destination-systems/${typeId}/sites`;
      }
    };
  } else if (name === "site") {
    return {
      types: [GENERIC_REQUEST_START, GET_ASSETS, GENERIC_REQUEST_FAILURE],
      getURL: (state: AppState) => {
        const typeId = state.amr.formData.configureMeterReadings.type;
        const siteId = state.amr.formData.configureMeterReadings.site;
        return `api/destination-systems/${typeId}/assets?siteName=${siteId}`;
      }
    };
  } else if (name === "asset" && objectType === "METER") {
    return {
      types: [GENERIC_REQUEST_START, GET_METERS, GENERIC_REQUEST_FAILURE],
      getURL: (state: AppState) => {
        const typeId = state.amr.formData.configureMeterReadings.type;
        const assetId = state.amr.formData.configureMeterReadings.asset;
        return `api/destination-systems/${typeId}/asset-meters?assetId=${assetId}`;
      }
    };
  }
  return {
    type: GENERIC_REQUEST_START
  };
}

export function saveMeterMappings(payload: MeterMappingsPayload, meterMapperId: number | undefined) {
  return {
    types: [SAVE_METER_READINGS_START, SAVE_METER_READINGS, SAVE_METER_READINGS_FAILURE],
    getURL: () => `api/meter-mappings` + (meterMapperId ? `/${meterMapperId}` : ""),
    payload,
    method: meterMapperId ? "PUT" : "POST"
  };
}

export function newMeterClick(meterMapperId: number | undefined) {
  return {
    type: NEW_METER_CLICK,
    meterMapperId
  };
}

export function deleteMeterMapper(meterMapperId: number | undefined) {
  return {
    types: [GENERIC_REQUEST_START, DELETE_METER_MAPPER, SAVE_METER_READINGS_FAILURE],
    getURL: () => `api/meter-mappings/${meterMapperId}`,
    method: "DELETE"
  };
}

export function getMeterMappingById(meterMapperId: number) {
  return {
    types: [GENERIC_REQUEST_START, GET_METER_BY_ID, SAVE_METER_READINGS_FAILURE],
    getURL: () => `api/meter-mappings/${meterMapperId}`,
    method: "GET"
  };
}

export function showDeleteModal() {
  return {
    type: "SHOW_DELETE_MODAL"
  };
}

export function updateConfigurations(activeMenuItem: string, data: object) {
  return {
    type: UPDATE_CONFIGURATIONS,
    activeMenuItem,
    data
  };
}

export function toggleImportResultsModal() {
  return {
    type: TOGGLE_IMPORT_RESULTS_MODAL
  };
}

export function importMetersFromCsv(payload: FormData) {
  return {
    types: [GENERIC_REQUEST_START, IMPORT_METERS_SUCCESS, GENERIC_REQUEST_FAILURE],
    getURL: () => `api/request-mappings/upload-import-file`,
    payload,
    method: "POST",
    contentType: "multipart/form-data"
  };
}

export function newAlertClick(meterMapperId: number | undefined) {
  return {
    type: NEW_ALERT_CLICK,
    meterMapperId
  };
}

export function exportTemplate() {
  return {
    types: [GENERIC_REQUEST_START, EXPORT_TEMPLATE, GENERIC_REQUEST_FAILURE],
    getURL: () => `api/request-mappings/import-template`
  };
}

export function getAssetsFieldConfigs() {
  return {
    types: [GENERIC_REQUEST_START, ASSETS_FIELD_CONFIGS_SUCCESS, GENERIC_REQUEST_FAILURE],
    method: "GET",
    getURL: () => `api/assets-field-configs`
  }
}