import _ from "lodash";
import {
  ConfigurationState,
  StructureTypesToWidgetTypeMap,
  ViewSettings,
  ViewSetting,
  ConfigurationPart,
  ConfigurationMetadataPart,
  ValueTypeToWidgetTypeMap,
  StructureTypes,
  ConfigurationSetting,
  ConfigurationMetadata
} from "./types";
import {ValueType, ConfigurationValueTypes} from "../../components/common/input/types";

export function getSettingKey(part: {name: string}, setting: {name: string}) {
  return `${part.name}${setting.name}`;
}

export function getParts(props: ConfigurationState) {
  let currentConfigPart = _.find(props.configuration, {
    name: props.currentConfigPart
  });
  let currentMetadataPart = _.find(props.metadata, {
    name: props.currentConfigPart
  });
  return {currentConfigPart, currentMetadataPart};
}

export function validate(value: ValueType, settingMD: ConfigurationMetadata) {
  if (
    settingMD.required &&
    (_.isUndefined(value) ||
      _.isNull(value) ||
      value === "" ||
      (_.isArray(value) && value.length === 0) ||
      _.isNaN(value))
  ) {
    return false;
  }
  let isValid = true;
  if (settingMD.structureType === StructureTypes.LIST) {
    if (_.isArray(value)) {
      if (settingMD.valueType === ConfigurationValueTypes.STRING) {
        isValid = _.every(value, _.isString);
      } else if (settingMD.valueType === ConfigurationValueTypes.INTEGER) {
        isValid = _.every(value, part => _.isInteger(part));
      } else if (settingMD.valueType === ConfigurationValueTypes.DECIMAL) {
        isValid = _.every(value, part => !_.isNaN(part) && _.isNumber(part));
      }
    } else {
      isValid = false;
    }
  } else if (settingMD.structureType === StructureTypes.SINGLE) {
    if (_.isString(value) || _.isNumber(value) || _.isBoolean(value) || _.isNull(value)) {
      if (settingMD.valueType === ConfigurationValueTypes.STRING) {
        isValid = _.isNull(value) || _.isString(value);
      } else if (settingMD.valueType === ConfigurationValueTypes.INTEGER) {
        isValid = _.isNull(value) || _.isInteger(value);
      } else if (settingMD.valueType === ConfigurationValueTypes.DECIMAL) {
        isValid = _.isNull(value) || (!_.isNaN(value) && _.isNumber(value));
      } else if (settingMD.valueType === ConfigurationValueTypes.PASSWORD) {
        isValid = _.isNull(value) || _.isString(value);
      } else if (settingMD.valueType === ConfigurationValueTypes.BOOLEAN) {
        return _.isNull(value) || _.isBoolean(value);
      }
    } else {
      isValid = false;
    }
  }
  return isValid;
}

export function areWidgetsCompatibleWithTypes(metadataParts: ConfigurationMetadataPart[]) {
  let validationResults: string[] = [];
  metadataParts.forEach(mdPart => {
    mdPart.settingsMetadata.forEach(settingMD => {
      if (!ValueTypeToWidgetTypeMap[settingMD.valueType].includes(settingMD.widgetType)) {
        validationResults.push(
          `Configuration ${mdPart.name}, setting ${settingMD.name} has valueType ${
            settingMD.valueType
          } and widgetType ${
            settingMD.widgetType
          }. But only following widgets are supported for this valueType ${JSON.stringify(
            ValueTypeToWidgetTypeMap[settingMD.valueType]
          )}`
        );
      }
      if (!StructureTypesToWidgetTypeMap[settingMD.structureType].includes(settingMD.widgetType)) {
        validationResults.push(
          `Configuration ${mdPart.name}, setting ${settingMD.name} has structureType ${
            settingMD.structureType
          } and widgetType ${
            settingMD.widgetType
          }. But only following widgets are supported for this structureType ${JSON.stringify(
            StructureTypesToWidgetTypeMap[settingMD.structureType]
          )}`
        );
      }
    });
  });
  return validationResults;
}

export function createViewSetting(config: ConfigurationSetting, settingMD: ConfigurationMetadata): ViewSetting {
  return {
    value: _.isNull(config.value)
      ? _.isUndefined(settingMD.defaultValue)
        ? null
        : settingMD.defaultValue
      : config.value,
    isDirty: false,
    isValid: true
  };
}

export function generateViewSettingForMDPart(
  mdPart: ConfigurationMetadataPart,
  viewSettings: ViewSettings,
  newViewSettings: ViewSettings,
  configurationParts: ConfigurationPart[]
) {
  const configPart = _.find(configurationParts, {
    name: mdPart.name
  });
  if (_.isUndefined(configPart)) {
    return;
  }
  let finalSettingsMetadata = _.intersectionBy(mdPart.settingsMetadata, configPart.settings, "name");
  finalSettingsMetadata.forEach(settingMD => {
    const config = _.find(configPart.settings, {name: settingMD.name});
    if (_.isUndefined(config)) {
      return;
    }
    let finalValue;
    const currentValue = viewSettings[getSettingKey(mdPart, settingMD)];
    if (_.isUndefined(currentValue)) {
      finalValue = createViewSetting(config, settingMD);
    } else {
      if (currentValue.isDirty) {
        finalValue = currentValue;
      } else {
        finalValue = createViewSetting(config, settingMD);
      }
    }
    newViewSettings[getSettingKey(mdPart, settingMD)] = finalValue;
  });
}

export function generateViewSettings(
  viewSettings: ViewSettings,
  metadataParts?: ConfigurationMetadataPart[],
  configurationParts?: ConfigurationPart[]
) {
  _.forEach(viewSettings, viewSetting => {
    viewSetting.isDirty = false;
  });
  const newViewSettings: ViewSettings = {};
  if (_.isUndefined(metadataParts) || _.isUndefined(configurationParts)) {
    return newViewSettings;
  }

  metadataParts.forEach(mdPart => {
    generateViewSettingForMDPart(mdPart, viewSettings, newViewSettings, configurationParts);
  });
  return newViewSettings;
}

export function getChangedSettings(configPart: ConfigurationPart, configState: ConfigurationState) {
  const dirtyViewSettings = _.pickBy(configState.viewSettings, value => value.isDirty);
  const settings = configPart.settings;
  const changedSettings = _.chain(settings)
    .filter(setting => _.isObject(dirtyViewSettings[getSettingKey(configPart, setting)]))
    .map(setting => {
      return {
        ...setting,
        value: dirtyViewSettings[getSettingKey(configPart, setting)].value
      };
    })
    .value();
  return changedSettings;
}
