import React from "react";
import _ from "lodash";
import Input from "../common/input";
import Switcher from "../pages/configuration/widgets/Switcher";
import Dropdown from "../pages/configuration/widgets/Dropdown";
import MultiSelect from "../pages/configuration/widgets/MultiSelect";
import {ConfigurationMetadata, WidgetTypes, StructureTypes} from "../../store/configuration/types";
import {ValueType} from "../common/input/types";
import FileUpload from "../common/fileUpload/FileUpload";
import Textarea from "../common/textarea";
import TimeoutUnitContainer from "../common/timeoutUnit/TimeoutUnitContainer";

interface HasSettingMD {
  disabled: boolean;
  settingMD: ConfigurationMetadata;
  fullWidth: boolean;
  value: ValueType;
  isValid: boolean;
  onChange: (val: ValueType, name?: string) => void;
}
export default function FormItem(props: HasSettingMD) {
  const {value, isValid, onChange} = props;

  if (props.settingMD.structureType === StructureTypes.SINGLE) {
    if (
      (props.settingMD.widgetType === WidgetTypes.TEXT ||
        props.settingMD.widgetType === WidgetTypes.PASSWORD ||
        props.settingMD.widgetType === WidgetTypes.NUMBER) &&
      (_.isString(value) || _.isNumber(value) || _.isNull(value))
    ) {
      return (
        <div className={props.fullWidth ? "mt-1 col-12 p-0" : "mt-1 col-12 col-lg-6 p-0"}>
          <Input
            isValid={isValid}
            disabled={props.disabled}
            {...props.settingMD}
            type={props.settingMD.widgetType}
            value={value}
            key={props.settingMD.name}
            onChange={onChange}
          />
        </div>
      );
    } else if (
      props.settingMD.widgetType === WidgetTypes.TIMEOUT &&
      (_.isString(value) || _.isNumber(value) || _.isNull(value))
    ) {
      return (
        <div className={props.fullWidth ? "mt-1 col-12 p-0" : "mt-1 col-12 col-lg-6 p-0"}>
          <TimeoutUnitContainer
            {...props}
            isValid={isValid}
            disabled={props.disabled}
            // @ts-ignore
            options={props.settingMD.options}
            {...props.settingMD}
            type={props.settingMD.widgetType}
            value={value}
            key={props.settingMD.name}
            onChange={onChange}
          />
        </div>
      );
    } else if (
      props.settingMD.widgetType === WidgetTypes.TEXTAREA &&
      (_.isString(value) || _.isNumber(value) || _.isNull(value))
    ) {
      return (
        <div className={props.fullWidth ? "mt-1 col-12 p-0" : "mt-1 col-12 col-lg-6 p-0"}>
          <Textarea
            isValid={isValid}
            disabled={props.disabled}
            {...props.settingMD}
            value={value}
            key={props.settingMD.name}
            onChange={onChange}
          />
        </div>
      );
    } else if (
      props.settingMD.widgetType === WidgetTypes.FILE &&
      (_.isString(value) || _.isNumber(value) || _.isNull(value))
    ) {
      return (
        <FileUpload
          isValid={isValid}
          disabled={props.disabled}
          {...props.settingMD}
          type={props.settingMD.widgetType}
          value={value}
          key={props.settingMD.name}
          onChange={onChange}
        />
      );
    } else if (props.settingMD.widgetType === WidgetTypes.SLIDER && (_.isBoolean(value) || _.isNull(value))) {
      return (
        <Switcher
          isValid={isValid}
          disabled={props.disabled}
          {...props.settingMD}
          type={props.settingMD.widgetType}
          value={value}
          key={props.settingMD.name}
          onChange={onChange}
        />
      );
    } else if (
      props.settingMD.widgetType === WidgetTypes.DROPDOWN &&
      _.isArray(props.settingMD.options) &&
      (_.isString(value) || _.isNumber(value) || _.isNull(value))
    ) {
      return (
        <div className={props.fullWidth ? "mt-1 col-12 p-0" : "mt-1 col-12 col-lg-6 p-0"}>
          <Dropdown
            isValid={isValid}
            disabled={props.disabled}
            // @ts-ignore
            options={props.settingMD.options}
            {...props.settingMD}
            value={value}
            key={props.settingMD.name}
            onChange={onChange}
          />
        </div>
      );
    } else {
      return null;
    }
  } else if (props.settingMD.structureType === StructureTypes.LIST) {
    if (
      props.settingMD.widgetType === WidgetTypes.MULTISELECT &&
      _.isArray(props.settingMD.options) &&
      (_.isArray(value) || _.isNull(value))
    ) {
      return (
        <MultiSelect
          isValid={isValid}
          disabled={props.disabled}
          // @ts-ignore
          options={props.settingMD.options}
          {...props.settingMD}
          value={value}
          key={props.settingMD.name}
          onChange={onChange}
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
