import React from "react";
import {Modal, Alert, Table} from "react-bootstrap";
import "./index.scss";
import {ImportMeterReadingsResults} from "../types";

interface IImportMeterReadingsModal {
  show: boolean;
  onClose: () => void;
  fileName?: string | null;
  results: ImportMeterReadingsResults;
}

const ImportMeterReadingsModal = ({show, onClose, fileName, results}: IImportMeterReadingsModal) => {
  const renderErrorsTable = (errors: {type: string; line: string; message: string}[]) => {
    return (
      <Table striped bordered hover size="sm" className="mt-3 mb-0 errorsTable">
        <thead>
          <tr>
            <th>Type</th>
            <th>Line</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {errors.map((error, index) => (
            <tr key={index}>
              <td>{error.type}</td>
              <td>{error.line}</td>
              <td>{error.message}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };
  const isImportedWithErrors = results.itemsWithErrors || (results.errors && results.errors.length);

  return (
    <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p>Import Complete!</p>
          <p className="fileName">File: {fileName}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="importResultsContainer">
        <Alert variant={isImportedWithErrors ? "danger" : "success"} className="mb-1">
          <p>Import was completed with {isImportedWithErrors ? "errors" : "no errors or warnings"}.</p>
        </Alert>
        {results.createdItems ? <Alert variant="success" className="mb-1">
          <p>{results.createdItems} {`${results.createdItems > 1 ? 'items were' : 'item was'}`} successfully imported.</p>
        </Alert> : null}
        {results.updatedItems ? <Alert variant="success" className="mb-1">
          <p>{results.updatedItems} {`${results.updatedItems > 1 ? 'items were' : 'item was'}`} succesfully updated.</p>
        </Alert> : null}
        {results.itemsWithErrors ? (
          <Alert variant={isImportedWithErrors ? "danger" : "success"} className="mb-1">
            <p>{results.itemsWithErrors} {`${results.itemsWithErrors > 1 ? 'items were' : 'item was'}`} imported with errors.</p>
          </Alert>
        ) : null}
        {results.errors && results.errors.length ? renderErrorsTable(results.errors) : null}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportMeterReadingsModal;
