import {SHOW_CONFIRM_CANCEL, CO2_SETTINGS_SUCCESS, SettingsResponseSchema} from "./types";
import {GENERIC_REQUEST_FAILURE, GENERIC_REQUEST_START} from "../screenExtensions/types";

export function showConfirmCancel(show: boolean) {
  return {
    type: SHOW_CONFIRM_CANCEL,
    show
  };
}

export function fetchSettings() {
  return {
    types: [GENERIC_REQUEST_START, CO2_SETTINGS_SUCCESS, GENERIC_REQUEST_FAILURE],
    responseSchema: SettingsResponseSchema,
    getURL: () => `/e7/services/config/screen-extensions/fieldSupportConfig`
  };
}
