import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../store/rootReducer";
import Amr from "../../components/pages/amr";
import {
  menuClick,
  changeValue,
  saveProvider,
  loadDataProviders,
  loadDestinations,
  loadMeterStatuses,
  selectProvider,
  loadMeterMappings,
  loadMeterMappingsBySource,
  toggleExpanded,
  loadDataById,
  saveMeterMappings,
  newMeterClick,
  deleteMeterMapper,
  getMeterMappingById,
  deleteProvider,
  showDeleteModal,
  updateConfigurations,
  toggleImportResultsModal,
  importMetersFromCsv,
  newAlertClick,
  exportTemplate,
  getAssetsFieldConfigs
} from "../../store/amr/actions";
import {ValueType} from "../../components/common/input/types";
import {MeterMappingsPayload, SaveProviderPayload} from "../../components/pages/amr/types";

const mapStateToProps = (state: AppState) => state.amr;
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    loadDataSources: () => {
      dispatch(loadDataProviders());
      dispatch(loadDestinations());
      dispatch(loadMeterMappings());
    },
    loadMeterMappings: async () => {
      await dispatch(loadMeterMappings());
    },
    loadMeterMappingsBySource: (sourceSystemId: string, destinationSystemId: string) => {
      dispatch(loadMeterMappingsBySource(sourceSystemId, destinationSystemId));
    },
    loadMeterStatuses: async () => {
      await dispatch(loadMeterStatuses());
    },
    menuClick: (activeMenuItem: string) => {
      dispatch(menuClick(activeMenuItem));
    },
    changeValue: (activeMenuItem: string, name: string, value: ValueType) => {
      dispatch(changeValue(activeMenuItem, name, value));
    },
    saveProvider: async (payload: SaveProviderPayload, isDataProvider: boolean, isEditable: boolean) => {
      await dispatch(saveProvider(payload, isDataProvider, isEditable));
      if (isDataProvider) {
        dispatch(loadDataProviders());
      } else {
        dispatch(loadDestinations());
      }
    },
    deleteProvider: async (deleteProviderId: number, isDataProvider: boolean) => {
      await dispatch(deleteProvider(deleteProviderId, isDataProvider));
      dispatch(loadDataProviders());
      dispatch(loadDestinations());
      dispatch(loadMeterMappings());
    },
    selectProvider: (provider: SaveProviderPayload, activeMenuItem: string) => {
      dispatch(selectProvider(provider, activeMenuItem));
    },
    toggleExpanded: (key: string, value: boolean) => {
      dispatch(toggleExpanded(key, value));
    },
    changeMeterOption: (activeMenuItem: string, name: string, value: ValueType, objectType?: string) => {
      dispatch(changeValue(activeMenuItem, name, value));
      if (name === "type") {
        dispatch(changeValue(activeMenuItem, "site", null));
      }
      if (name === "type" || name === "site") {
        dispatch(changeValue(activeMenuItem, "asset", null));
      }
      if (name === "asset" || name === "site" || name === "type") {
        dispatch(changeValue(activeMenuItem, "meter", null));
      }
      dispatch(loadDataById(activeMenuItem, name, objectType));
    },
    loadDataById: async (activeMenuItem: string, name: string, objectType?: string) => {
      await dispatch(loadDataById(activeMenuItem, name, objectType));
    },
    saveMeterMappings: async (payload: MeterMappingsPayload, meterMapperId: number | undefined) => {
      await dispatch(saveMeterMappings(payload, meterMapperId));
    },
    newMeterClick: (meterMapperId?: number | undefined) => {
      dispatch(newMeterClick(meterMapperId));
    },
    deleteMeterMapper: async (meterMapperId: number | undefined) => {
      await dispatch(deleteMeterMapper(meterMapperId));
      dispatch(newMeterClick(undefined));
    },
    getMeterMappingById: async (meterMapperId: number) => {
      await dispatch(getMeterMappingById(meterMapperId));
    },
    showDeleteModal: () => {
      dispatch(showDeleteModal());
    },
    updateConfigurations: (activeMenuItem: string, data: object) => {
      dispatch(updateConfigurations(activeMenuItem, data));
    },
    toggleImportResultsModal: () => {
      dispatch(toggleImportResultsModal());
    },
    importMetersFromCsv: async (payload: FormData) => {
      await dispatch(importMetersFromCsv(payload));
      await dispatch(loadMeterMappings());
    },
    newAlertClick: (meterMapperId: number | undefined) => {
      dispatch(newAlertClick(meterMapperId));
    },
    exportTemplate: () => {
      dispatch(exportTemplate())
    },
    getAssetsFieldConfigs: () => {
      dispatch(getAssetsFieldConfigs())
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Amr);
