import React from "react";
import {IntegrationsProps} from "../types";

export default function SyncButton(props: IntegrationsProps) {
  return (
    <button className="d-flex ml-auto btn btn-primary" onClick={props.syncConfiguration}>
      Resync Configurations
    </button>
  );
}
