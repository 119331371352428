import _ from "lodash";
import {
  GENERIC_REQUEST_FAILURE,
  RELOAD_REQUEST,
  RELOAD_SUCCESS,
  RELOAD_STATUS_SUCCESS,
  RELOAD_STATUS_FAILURE,
  GENERIC_REQUEST_START,
  GENERIC_REQUEST_SUCCESS,
  CO2_COMPONENTS_SUCCESS,
  RELOAD_PAGE_INFO_SUCCESS,
  SELECT_FILTER_ATTRIBUTE,
  DESELECT_FILTER_ATTRIBUTE,
  SET_ATTRIBUTE_QUERY,
  OPEN_FILTER_ATTRIBUTE_SELECTOR,
  SHOW_ERROR_POPUP,
  HIDE_RELOAD_STATUS_INFO,
  CO2_COMPONENT_SELECT_CHANGE,
  CO2_ALL_COMPONENT_SELECT_CHANGE,
  SET_RELOAD_STATUS_INTERVALID,
  SET_SORT,
  CO2ActionTypes,
  DBReloadState,
  ReloadStatuses,
  ViewCO2Component,
  SortOrder,
  SortFields,
  CO2Component
} from "./types";

function getInitialViewCO2Components(co2Components: CO2Component[]) {
  return co2Components.map(
    (co2Component): ViewCO2Component => {
      return {
        co2Component,
        selected: false,
        countTotal: undefined,
        countProcessed: undefined,
        currentStep: undefined,
        viewComponentLoadStatus: undefined
      };
    }
  );
}

function getViewComponentWithInitialLoadState(vco2c: ViewCO2Component) {
  return {
    ...vco2c,
    viewComponentLoadStatus: undefined,
    countTotal: undefined,
    countProcessed: undefined,
    currentStep: undefined
  };
}

const defaultDBReloadState: DBReloadState = {
  filterAttributes: [],
  woVersion: undefined,
  storeroomVersion: undefined,
  viewCO2Components: undefined,
  errorMessage: "",
  loadId: undefined,
  requestStatusIntervalId: undefined,
  finalLoadStatus: undefined,
  errorPopupShown: false,
  reloadStatusInfoShown: true,
  sortField: SortFields.DB_NAME,
  sortOrder: SortOrder.ASC,
  lastReloadDate: undefined
};

export default function dbReload(state = defaultDBReloadState, action: CO2ActionTypes) {
  switch (action.type) {
    case SET_RELOAD_STATUS_INTERVALID: {
      return {...state, requestStatusIntervalId: action.id};
    }
    case RELOAD_STATUS_SUCCESS: {
      const reloadStatus = action.data;
      const positiveStatuses = [
        ReloadStatuses.DONE,
        ReloadStatuses.IN_PROGRESS,
        ReloadStatuses.STOPPED,
        ReloadStatuses.PENDING
      ];
      const newState = {...state};
      if (reloadStatus.loadStatus === ReloadStatuses.ERROR) {
        newState.loadId = undefined;
        newState.finalLoadStatus = reloadStatus.loadStatus;
      } else if (reloadStatus.loadStatus === ReloadStatuses.NONE) {
        newState.loadId = undefined;
        newState.finalLoadStatus = reloadStatus.loadStatus;
        newState.reloadStatusInfoShown = false;
      } else if (positiveStatuses.includes(reloadStatus.loadStatus)) {
        if (reloadStatus.loadStatus === ReloadStatuses.IN_PROGRESS) {
          newState.loadId = reloadStatus._id;
        }
        if ([ReloadStatuses.DONE, ReloadStatuses.STOPPED].includes(reloadStatus.loadStatus)) {
          newState.loadId = undefined;
          newState.finalLoadStatus = reloadStatus.loadStatus;
          newState.reloadStatusInfoShown = true;
        }
        if (_.isArray(state.viewCO2Components)) {
          newState.viewCO2Components = state.viewCO2Components.map(vc => {
            const statusData = reloadStatus.components[vc.co2Component._id];
            let resVC = {...vc};

            if (!!statusData) {
              resVC.viewComponentLoadStatus = statusData;

              if (!!statusData.currentStep) {
                const loadStep = statusData.loadSteps[statusData.currentStep];
                resVC = {
                  ...resVC,
                  ...loadStep,
                  currentStep: statusData.currentStep
                };
              }
            }
            return resVC;
          });
        }
      }
      newState.lastReloadDate = reloadStatus.timestemp;
      if (!!reloadStatus.errorLog) {
        newState.errorMessage = reloadStatus.errorLog.errorMessage;
      }
      return newState;
    }
    case RELOAD_STATUS_FAILURE: {
      return {
        ...state,
        loadId: undefined,
        finalLoadStatus: ReloadStatuses.ERROR
      };
    }
    case RELOAD_REQUEST: {
      return {
        ...state,
        errorMessage: "",
        reloadStatusInfoShown: true,
        finalLoadStatus: undefined,
        viewCO2Components: _.map(state.viewCO2Components, getViewComponentWithInitialLoadState)
      };
    }
    case RELOAD_SUCCESS: {
      return {...state, loadId: action.data.loadId};
    }
    case SELECT_FILTER_ATTRIBUTE: {
      return {
        ...state,
        filterAttributes: state.filterAttributes.map(attribute => {
          if (attribute.name === action.name) {
            return {
              ...attribute,
              selectedOptions: [...attribute.selectedOptions, action.option]
            };
          } else {
            return attribute;
          }
        })
      };
    }
    case DESELECT_FILTER_ATTRIBUTE: {
      return {
        ...state,
        filterAttributes: state.filterAttributes.map(attribute => {
          if (attribute.name === action.name) {
            let selectedOptions = [...attribute.selectedOptions];
            selectedOptions = _.pull(selectedOptions, action.option);
            return {
              ...attribute,
              selectedOptions: selectedOptions
            };
          } else {
            return attribute;
          }
        })
      };
    }
    case CO2_COMPONENT_SELECT_CHANGE: {
      if (_.isUndefined(state.viewCO2Components)) {
        return state;
      }
      return {
        ...state,
        viewCO2Components: state.viewCO2Components.map(viewCO2Component => {
          if (viewCO2Component === action.viewCO2Component) {
            return {...viewCO2Component, selected: !viewCO2Component.selected};
          } else {
            return viewCO2Component;
          }
        })
      };
    }
    case CO2_ALL_COMPONENT_SELECT_CHANGE: {
      if (_.isUndefined(state.viewCO2Components)) {
        return state;
      }
      return {
        ...state,
        viewCO2Components: state.viewCO2Components.map(viewCO2Component => {
          if (viewCO2Component.selected === action.checked) {
            return viewCO2Component;
          } else {
            return {...viewCO2Component, selected: action.checked};
          }
        })
      };
    }
    case RELOAD_PAGE_INFO_SUCCESS: {
      let filterAttributes = _.map(action.data.loadOptions, attribute => {
        return {
          name: attribute.name,
          title: attribute.name
            .charAt(0)
            .toUpperCase()
            .concat(attribute.name.slice(1)),
          options: attribute.options,
          selectedOptions: [],
          selectorOpened: false,
          attributeQuery: ""
        };
      });
      return {
        ...state,
        filterAttributes,
        woVersion: action.data.woVersion,
        storeroomVersion: action.data.storeroomVersion
      };
    }
    case CO2_COMPONENTS_SUCCESS: {
      return {
        ...state,
        viewCO2Components: getInitialViewCO2Components(action.data)
      };
    }
    case GENERIC_REQUEST_START: {
      return {...state, errorMessage: ""};
    }
    case GENERIC_REQUEST_SUCCESS: {
      return state;
    }
    case GENERIC_REQUEST_FAILURE: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        finalLoadStatus: ReloadStatuses.ERROR
      };
    }
    case SHOW_ERROR_POPUP: {
      return {...state, errorPopupShown: action.show};
    }
    case HIDE_RELOAD_STATUS_INFO: {
      return {...state, reloadStatusInfoShown: false, errorMessage: ""};
    }
    case OPEN_FILTER_ATTRIBUTE_SELECTOR: {
      return {
        ...state,
        filterAttributes: state.filterAttributes.map(attribute => {
          if (attribute.name === action.name) {
            return {...attribute, selectorOpened: action.open};
          } else {
            return attribute;
          }
        })
      };
    }
    case SET_ATTRIBUTE_QUERY: {
      return {
        ...state,
        filterAttributes: state.filterAttributes.map(attribute => {
          if (attribute.name === action.name) {
            return {...attribute, attributeQuery: action.attributeQuery};
          } else {
            return attribute;
          }
        })
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sortOrder: action.sortOrder,
        sortField: action.sortField
      };
    }
    default: {
      return state;
    }
  }
}
