import React from "react";
import _ from "lodash";
import {SaveCancelProps} from "./types";
import ConfirmCancel from "../../confirmCancel/ConfirmCancel";

export default function SaveCancelContainer(props: SaveCancelProps) {
  return (
    <>
      <div
        className={
          props.customStyle ||
          "SaveCancelContainer d-flex p-3 align-items-center justify-content-end alert-secondary fixed-bottom"
        }
      >
        <button
          type="button"
          className="btn btn-light border-dark m-1"
          onClick={props.cancelClick}
          disabled={props.isCancelDisabled}
        >
          Cancel
        </button>
        <button onClick={props.saveClick} type="button" disabled={props.isSaveDisabled} className="btn btn-primary m-1">
          Save
        </button>
      </div>

      {props.isModalShown && (
        <ConfirmCancel
          text="Please confirm that you want to discard the changes"
          cancelFunc={props.modalCancelClick}
          cancelText="Cancel"
          confirmFunc={props.modalConfirmClick}
          confirmText="Clear changes"
        />
      )}
    </>
  );
}
