import React from "react";
import _ from "lodash";
import DBReloadPageProps from "./types";

export default function AppVersions(props: DBReloadPageProps) {
  return (
    <>
      {_.isString(props.woVersion) && <p className="pb-3">WO Version: {props.woVersion}</p>}
      {_.isString(props.storeroomVersion) && <p className="pb-3">Storeroom Version: {props.storeroomVersion}</p>}
    </>
  );
}
