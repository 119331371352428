import React from "react";
import _ from "lodash";
import {ConfigurationProps} from "./types";
import FormItem from "../../formItem/FormItem";
import {ConfigurationMetadata, ConfigurationMetadataPart} from "../../../store/configuration/types";
import {getSettingKey} from "../../../store/configuration/utils";
import {ValueType} from "../../common/input/types";
import {integrationName} from "../../../store/_types/integrations.types";

export default function renderConfigList(
  configMDs: ConfigurationMetadata[],
  props: ConfigurationProps,
  tabLocked: boolean,
  currentMetadataPart: ConfigurationMetadataPart
) {
  return configMDs.map((settingMD: ConfigurationMetadata) => {
    let {value, isValid} = props.viewSettings[getSettingKey(currentMetadataPart, settingMD)];
    const showSetting =
      !settingMD.enabledSetting ||
      (settingMD.enabledSetting &&
        props.viewSettings[getSettingKey(currentMetadataPart, {name: settingMD.enabledSetting})].value);
    const onChange = (val: ValueType, name?: string) => {
      if (settingMD.warning) {
        const showWarning = settingMD.warning.values.some(function(value): boolean {
          let warningResult = val && value.toString() === val.toString();
          return Boolean(warningResult);
        });
        if (showWarning) {
          props.configurationCallback &&
            props.configurationCallback({
              showWarning: true,
              message: (settingMD.warning && settingMD.warning.message) || ""
            });
        } else {
          props.configurationCallback && props.configurationCallback({showWarning: false, message: ""});
        }
      } else {
        props.configurationCallback && props.configurationCallback({showWarning: false, message: ""});
      }
      props.changeValue(
        currentMetadataPart,
        name || settingMD.name,
        settingMD.structureType === "SINGLE" && settingMD.valueType === "INTEGER" && _.isString(val)
          ? parseInt(val)
          : val
      );
    };
    return (
      <React.Fragment key={settingMD.name}>
        {showSetting && (
          <div className="mt-3">
            <FormItem
              value={value}
              isValid={isValid}
              onChange={onChange}
              disabled={tabLocked}
              settingMD={settingMD}
              fullWidth={currentMetadataPart.name === integrationName}
            />
          </div>
        )}
      </React.Fragment>
    );
  });
}
