import _ from "lodash";

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faCogs, faDatabase, faPlus, faServer, faTimes, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

import {AmrProps, FormItem, SaveProviderPayload} from "./types";

import MainContentScroll from "../../mainContentScroll";
import RenderFormList from "./renderFormList/index";
import pieChart from "./standard-pie-chart.png";
import barChart from "./bar_chart.png";

import "./index.scss";
import SetupMeterReadings from "./setupMeterReadings";
import amrMenu from "./amrMenu";

import ConfirmCancel from "../../confirmCancel/ConfirmCancel";
import {ValueType} from "../../common/input/types";
import {Alert} from "react-bootstrap";

library.add(faServer, faDatabase, faCogs, faPlus, faTimes, faExclamationCircle);

class AmrPage extends React.Component<AmrProps, {intervalId: number}> {
  componentDidMount() {
    this.props.getAssetsFieldConfigs();
    this.props.loadDataSources();
    this.props.loadMeterStatuses();
    const intervalId = window.setInterval(this.props.loadMeterStatuses, 20000);
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount(): void {
    window.clearInterval(this.state.intervalId);
  }

  showConfig(name: string) {
    const configs = this.props.config[this.props.activeMenuItem];
    const config = configs.find(item => item.name === name);
    const activeMenuItem = this.props.activeMenuItem as
      | "setupConnection"
      | "setupDestination"
      | "configureMeterReadings";
    return (
      config &&
      (config.activationSettings === undefined ||
        config.activationSettings.every(setting =>
          setting.values.includes(this.props.formData[activeMenuItem][setting.name] as string)
        ))
    );
  }

  dataIsValid() {
    const activeMenuItem = this.props.activeMenuItem as
      | "setupConnection"
      | "setupDestination"
      | "configureMeterReadings";
    const formDataForActiveMenuItem = this.props.formData[activeMenuItem];
    return _.every(this.props.config[this.props.activeMenuItem], item => {
      if (!this.showConfig(item.name) || !item.required) {
        return true;
      }
      if (formDataForActiveMenuItem) {
        const value = formDataForActiveMenuItem[item.name];
        if (this.props.activeMenuItem === "setupConnection") {
          const {securityPolicy} = this.props.formData[this.props.activeMenuItem];
          if (
            securityPolicy === "None" &&
            (item.name === "certificate" || item.name === "userName" || item.name === "password")
          ) {
            return true;
          }
        }
        return _.isFinite(value) || !_.isEmpty(value);
      }
      return false;
    });
  }

  saveClick() {
    const standardConfigs = ["id", "address", "name", "userName", "password", "type", "objectType", "properties"];
    const configs =
      this.props.activeMenuItem === "setupConnection"
        ? this.props.formData.setupConnection
        : this.props.formData.setupDestination;
    const {id, address, name, userName, password, objectType, type} = configs;

    const secureProperties = ["refreshToken", "clientId", "clientSecret"];

    let properties: FormItem = {};
    const customProperties = Object.keys(configs).filter(key => !standardConfigs.includes(key));
    customProperties.forEach(key => {
      properties[key] = this.showConfig(key) ? (configs[key] as string) : undefined;
    });

    // eslint-disable-next-line array-callback-return
    Object.keys(properties).map(key => {
      if (secureProperties.includes(key)) {
        properties[key] = properties[key] === "someHardcodedPassword" ? undefined : properties[key];
      }
    });

    const formData: SaveProviderPayload = {
      id,
      address,
      name,
      userName,
      password: password === "someHardcodedPassword" ? undefined : password,
      type,
      ...(this.props.activeMenuItem === "setupConnection" && {objectType: objectType || "METER"}),
      properties
    };

    this.props.saveProvider(formData, this.props.activeMenuItem === "setupConnection", this.props.isEditable);
  }

  deleteProvider() {
    const deleteProviderId =
      this.props.activeMenuItem === "setupConnection"
        ? this.props.formData.setupConnection.id
        : this.props.formData.setupDestination.id;
    this.props.deleteProvider(Number(deleteProviderId), this.props.activeMenuItem === "setupConnection");
  }

  onConnectionChange = (activeMenuItem: string, name: string, value: ValueType) => {
    if (name === "type") {
      const {id, name: connectionName} = this.props.formData.setupConnection;
      this.props.updateConfigurations(this.props.activeMenuItem, {});
      if (connectionName) {
        this.props.changeValue(activeMenuItem, "name", connectionName);
      }
      if (id) {
        this.props.changeValue(activeMenuItem, "id", id);
      }
    }
    this.props.changeValue(activeMenuItem, name, value);
  };

  render() {
    return !_.isEmpty(this.props.config) ? (
      <>
        <div className="grey-bar" />
        <MainContentScroll>
          <div className="flex-grow-1 row">
            {amrMenu(this.props)}
            <div
              className={`amr-status col-8 pl-4 pr-4 pt-1 ${!!this.props.activeMenuItem ? "form-list" : ""} ${
                ["setupConnection", "setupDestination"].includes(this.props.activeMenuItem) ? "edit-mode-active" : ""
              }`}
            >
              {(this.props.activeMenuItem === "setupConnection" ||
                this.props.activeMenuItem === "setupDestination") && (
                <span
                  onClick={() => {
                    this.props.menuClick("");
                  }}
                >
                  <FontAwesomeIcon icon="times" className="position-absolute" />
                </span>
              )}
              {this.props.activeMenuItem === "" && (
                <div className="text-center">
                  <img src={pieChart} alt="pieCHart" height="150" className="m-3 m-auto d-block" />
                  <br />
                  <img src={barChart} alt="barChart" height="150" className="m-auto d-block" />
                </div>
              )}
              {this.props.activeMenuItem === "setupConnection" && (
                <div>
                  <h5 className="font-weight-bold text-center">
                    {this.props.isEditable ? "Update Data Provider" : "Setup Data Provider"}
                  </h5>
                  {RenderFormList(
                    this.props.config[this.props.activeMenuItem],
                    this.props.activeMenuItem,
                    this.onConnectionChange,
                    this.props.formData
                  )}
                </div>
              )}
              {this.props.activeMenuItem === "setupDestination" && (
                <div>
                  <h5 className="font-weight-bold text-center">
                    {this.props.isEditable ? "Update Data Publisher" : "Setup Data Publisher"}
                  </h5>
                  {RenderFormList(
                    this.props.config[this.props.activeMenuItem],
                    this.props.activeMenuItem,
                    this.props.changeValue,
                    this.props.formData
                  )}
                </div>
              )}
              {this.props.activeMenuItem === "configureMeterReadings" && <SetupMeterReadings props={this.props} />}
              {["setupConnection", "setupDestination"].includes(this.props.activeMenuItem) && (
                <div className="SaveCancelContainer d-flex p-3 align-items-center justify-content-end alert-secondary fixed-bottom">
                  <button
                    type="button"
                    disabled={!this.props.isEditable}
                    onClick={() => {
                      this.props.showDeleteModal();
                    }}
                    className="btn btn-warning m-1"
                  >
                    Delete
                  </button>
                  <button type="button" disabled={true} className="btn btn-warning m-1">
                    Test Connection
                  </button>
                  <button
                    onClick={() => {
                      this.saveClick();
                    }}
                    type="button"
                    disabled={!this.dataIsValid()}
                    className="btn btn-warning m-1"
                  >
                    Save
                  </button>
                  {this.props.isDeleteModalShown && (
                    <ConfirmCancel
                      text="Please Confirm that you want to delete all associated mappings."
                      cancelFunc={() => {
                        this.props.showDeleteModal();
                      }}
                      cancelText="Cancel"
                      confirmFunc={() => {
                        this.deleteProvider();
                        this.props.showDeleteModal();
                      }}
                      confirmText="Confirm to Delete"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </MainContentScroll>
      </>
    ) : (
      this.props.errorMessage && (
        <Alert variant="danger">
          <div className="m-0 pt-1 d-flex justify-content-between">
            <div>
              <span className="pr-3">
                <FontAwesomeIcon icon="ban" className="fa-lg" />
              </span>
              <span className="pl-2">Fields Configurations haven't been loaded.</span>
            </div>
          </div>
        </Alert>
      )
    );
  }
}

export default AmrPage;
