import _ from "lodash";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import DBReload from "../../components/pages/dbReload";
import {
  ViewCO2Component,
  SortFields,
  SortOrder,
  FilterAttribute,
  SelectedAttributesOptionsMap
} from "../../store/dbReload/types";
import {
  fetchComponents,
  selectFilterAttribute,
  deselectFilterAttribute,
  co2ComponentSelectChange,
  co2AllComponentSelectChange,
  startReload,
  setReloadStatusIntervalId,
  getReloadStatus,
  showErrorPopup,
  hideReloadStatusInfo,
  openFilterAttributeSelector,
  setAttributeQuery,
  setSort,
  stopReload,
  getLastReloadDetails,
  fetchFilterAttributes
} from "../../store/dbReload/actions";
import {AppState} from "../../store/rootReducer";

const mapStateToProps = (state: AppState) => state.dbReload;
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    loadData: async () => {
      dispatch(fetchComponents());
      dispatch(getLastReloadDetails());
      dispatch(fetchFilterAttributes());
    },
    selectFilterAttribute: (name: string, option: string) => {
      dispatch(selectFilterAttribute(name, option));
    },
    deselectFilterAttribute: (name: string, option: string) => {
      dispatch(deselectFilterAttribute(name, option));
    },
    onComponentCheckChange: (viewCO2Component: ViewCO2Component) => {
      dispatch(co2ComponentSelectChange(viewCO2Component));
    },
    onAllComponentCheckChange: (checked: boolean) => {
      dispatch(co2AllComponentSelectChange(checked));
    },
    startReload: (viewCO2Components: ViewCO2Component[], filterAttributes: FilterAttribute[]) => {
      let selectedAttributesOptionsMap: SelectedAttributesOptionsMap = {};
      filterAttributes.reduce((attributesObject, attribute) => {
        if (attribute.selectedOptions.length > 0) {
          attributesObject[attribute.name] = attribute.selectedOptions;
        } else {
          attributesObject[attribute.name] = attribute.options;
        }
        return attributesObject;
      }, selectedAttributesOptionsMap);
      const reloadData = {
        components: _.chain(viewCO2Components)
          .filter({selected: true})
          .map("co2Component._id")
          .value(),
        filterAttributes: selectedAttributesOptionsMap
      };
      dispatch(startReload(reloadData));
    },
    getReloadStatus: () => {
      dispatch(getReloadStatus());
    },
    setReloadStatusIntervalId: (id?: NodeJS.Timer) => {
      dispatch(setReloadStatusIntervalId(id));
    },
    stopReload: () => {
      dispatch(stopReload());
    },
    showErrorPopup: (show: boolean) => {
      dispatch(showErrorPopup(show));
    },
    hideReloadStatusInfo: () => {
      dispatch(hideReloadStatusInfo());
    },
    openFilterAttributeSelector: (name: string, open: boolean) => {
      dispatch(openFilterAttributeSelector(name, open));
    },
    setAttributeQuery: (name: string, query: string) => {
      dispatch(setAttributeQuery(name, query));
    },
    setSort: (sortField: SortFields, sortOrder: SortOrder) => {
      dispatch(setSort(sortField, sortOrder));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DBReload);
