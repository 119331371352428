import {
  AuthorizationState,
  AuthenticationActionsTypes,
  LOGIN_SUCCESS,
  AUTH_START,
  AUTH_FAILURE,
  LOGOUT_SUCCESS,
  SYSTEM_SETTINGS_SUCCESS,
  SYSTEM_SETTINGS_FAILURE,
  CMMS_SETTINGS_SUCCESS,
  SET_LOGIN_MODAL_MODE,
  Route
} from "../_types/authentication.types";
import {ThunkDispatch} from "redux-thunk";
import {showLoading} from "../loading/actions";
import routes from "../../routes";
import _ from "lodash";

const initialState: AuthorizationState = {
  userInfo: {
    username: "john",
    displayName: "John Doe"
  },
  errorMessage: "",
  routes: [],
  defaultRoute: "",
  goBackUrl: "",
  integrationSettingsReadOnly: false,
  isLoginModalOpened: false,
  cmms: ""
};

export const actionCreators = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    login: async (username: string, password: string) => {
      dispatch(showLoading(true));
      let form = new FormData();
      form.set("username", username);
      form.set("password", password);

      dispatch({type: AUTH_START});

      fetch("/login", {
        method: "POST",
        body: form
      })
        .then(response => {
          if (response.ok) {
            const user = {
              username: username,
              displayName: username
            };
            localStorage.setItem("userInfo", JSON.stringify(user));
            dispatch({
              types: [AUTH_START, SYSTEM_SETTINGS_SUCCESS, SYSTEM_SETTINGS_FAILURE],
              method: "GET",
              getURL: () => `api/system-settings`
            });
            dispatch({
              type: LOGIN_SUCCESS,
              user
            });
            dispatch(showLoading(false));
          } else {
            throw new Error(
              !!response.status && response.status >= 400 && response.status < 500
                ? "Authorization failed"
                : response.statusText || "Error occurred during login"
            );
          }
        })
        .catch((error: {message: string}) => {
          dispatch({
            type: AUTH_FAILURE,
            errorMessage: error.message
          });
          dispatch(showLoading(false));
        });
    },
    logout: async () => {
      dispatch(showLoading(true));
      localStorage.removeItem("userInfo");
      await dispatch({
        types: [AUTH_START, LOGOUT_SUCCESS, AUTH_FAILURE],
        method: "POST",
        showPopup: false,
        getURL: () => `logout`
      });
      dispatch(showLoading(false));
    },
    setLoginModalMode: (isLoginModalOpened: boolean) => {
      dispatch({
        type: "SET_LOGIN_MODAL_MODE",
        isLoginModalOpened
      });
    }
  };
};

export const reducer = (state = initialState, action: AuthenticationActionsTypes) => {
  switch (action.type) {
    case SET_LOGIN_MODAL_MODE:
      return {
        ...state,
        errorMessage: "",
        isLoginModalOpened: action.isLoginModalOpened
      };
    case AUTH_START:
      return {...state, errorMessage: ""};
    case AUTH_FAILURE:
      return {...state, errorMessage: action.errorMessage};
    case LOGIN_SUCCESS:
      return {...state, user: action.user};
    case SYSTEM_SETTINGS_SUCCESS:
      const filteredRoutes: Route[] = _.filter(routes, route => {
        if (!action.data.amrComponentDisabled) {
          return route.title === "Connect2 Assets";
        }
        if (route.title === "Connect2 Assets") {
          return !action.data.amrComponentDisabled;
        }
        if (route.title === "Users") {
          return !action.data.usersComponentDisabled;
        }
        return true;
      });
      if (action.data.userInfo) {
        localStorage.setItem("userInfo", JSON.stringify(action.data.userInfo));
      }
      return {
        ...state,
        defaultRoute: filteredRoutes[filteredRoutes.length - 1].path,
        routes: filteredRoutes,
        userInfo: action.data.userInfo,
        integrationSettingsReadOnly: !!action.data.integrationSettingsReadOnly,
        goBackUrl: action.data.goBackUrl || ""
      };
    case CMMS_SETTINGS_SUCCESS:
      return {
        ...state,
        cmms: action.data.cmms,
        goBackUrl: action.data.goBackUrl || ""
      };
    default:
      return state;
  }
};
