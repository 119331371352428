import React from "react";
import _ from "lodash";
import {SwitcherProps} from "./types";
import Switch from "react-switch";
import "./switcher.scss";

export default function Switcher(props: SwitcherProps) {
  const className = props.value ? "switch-checked" : "switch-uncheked";

  return (
    <div className="col">
      <div className="row pl-0 d-flex mb-1">
        <div className="col-8 col-lg-4 p-0">{props.title}</div>
        <div className="col-4 col-lg-2 d-flex justify-content-end justify-content-lg-start">
          <Switch
            className={className}
            id={props.name}
            onChange={check => {
              props.onChange(check);
            }}
            checked={_.isNull(props.value) ? false : props.value}
            disabled={props.disabled}
            onColor={"#0068bd"}
            offColor={"#f0f0f0"}
            offHandleColor={"#5d5d5d"}
            height={22}
            width={44}
          />
        </div>
        <div className="col-12 col-lg-6 p-0 pl-lg-2">
          <span className="text-muted">{props.description}</span>
        </div>
      </div>
    </div>
  );
}
