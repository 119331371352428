import {
  ACTIVE_MENU_CLICK,
  AmrType,
  CHANGE_FORM_VALUE,
  DATA_PROVIDERS_SUCCESS,
  DELETE_PROVIDER,
  DESTINATION_SUCCESS,
  GET_ASSETS,
  GET_METER_BY_ID,
  GET_METERS,
  METER_STATUSES_SUCCESS,
  GET_SITES,
  METER_MAPPINGS_BY_SOURCE_SUCCESS,
  METER_MAPPINGS_DESTINATION_SUCCESS,
  NEW_METER_CLICK,
  SAVE_METER_READINGS,
  SAVE_METER_READINGS_FAILURE,
  SAVE_METER_READINGS_START,
  SAVE_PROVIDER,
  SELECT_PROVIDER,
  SHOW_DELETE_MODAL,
  TOGGLE_METER_TREE_VIEW,
  GENERIC_REQUEST_START,
  GENERIC_REQUEST_FAILURE,
  METER_STATUS_REQUEST_FAILURE,
  UPDATE_CONFIGURATIONS,
  TOGGLE_IMPORT_RESULTS_MODAL,
  IMPORT_METERS_SUCCESS,
  NEW_ALERT_CLICK,
  ASSETS_FIELD_CONFIGS_SUCCESS
} from "./types";
import {AmrState, Config} from "../../components/pages/amr/types";

import _ from "lodash";
import {Options} from "../../components/pages/configuration/widgets/types";
import {getDefaultFormDataState, updateSecureFieldsForProvider} from "./utils";

const defaultAmrState: AmrState = {
  activeMenuItem: "",
  config: {} as Config,
  dataProviders: [],
  destinations: [],
  meterList: [],
  meterStatuses: [],
  toggleMeterList: {},
  isEditable: false,
  formData: {
    setupConnection: {},
    setupDestination: {},
    configureMeterReadings: {}
  },
  amrMenu: [
    {
      title: "Setup Connection",
      name: "setupConnection",
      icon: "server"
    },
    {
      title: "Setup Destination",
      name: "setupDestination",
      icon: "database"
    },
    {
      title: "Configure Meter / Alert readings",
      name: "configureMeterReadings",
      icon: "cogs"
    }
  ],
  isConfigureMeterReadings: false,
  meterMapperId: undefined,
  createUpdaterErrorMessage: "",
  errorFromMeterStatus: "",
  isDeleteModalShown: false,
  errorMessage: "",
  meterStatusRequestErrorMessage: "",
  isImportResultModalShown: false,
  importMeterReadingsResults: {
    createdItems: 0,
    updatedItems: 0,
    itemsWithErrors: 0,
    errors: []
  }
};

export default function amr(state = defaultAmrState, action: AmrType) {
  switch (action.type) {
    case ACTIVE_MENU_CLICK: {
      return {
        ...state,
        activeMenuItem: action.activeMenuItem,
        isEditable: false,
        formData: getDefaultFormDataState(state.config),
        ...(action.activeMenuItem === "configureMeterReadings" && {isConfigureMeterReadings: false})
      };
    }
    case CHANGE_FORM_VALUE: {
      let formDataConfig = action.activeMenuItem as "setupConnection" | "setupDestination" | "configureMeterReadings";

      return {
        ...state,
        formData: {
          ...state.formData,
          [action.activeMenuItem]: {
            ...state.formData[formDataConfig],
            [action.name]: action.value
          }
        }
      };
    }

    case GET_SITES: {
      const options = action.data;
      const setupDestinationForMeter = _.map(state.config.setupDestinationForMeter, configItem => {
        if (configItem.name === "site") {
          configItem.options = options as Options[];
        }
        if (configItem.name === "asset" || configItem.name === "meter") {
          configItem.options = [];
        }
        return configItem;
      });
      return {
        ...state,
        config: {
          ...state.config,
          setupDestinationForMeter
        }
      };
    }
    case GET_ASSETS: {
      const options = _.map(action.data, asset => ({
        value: asset.id,
        description: asset.name
      }));
      const setupDestinationForMeter = _.map(state.config.setupDestinationForMeter, configItem => {
        if (configItem.name === "asset") {
          configItem.options = options as Options[];
        }
        if (configItem.name === "meter") {
          configItem.options = [];
        }
        return configItem;
      });
      return {
        ...state,
        config: {
          ...state.config,
          setupDestinationForMeter
        }
      };
    }
    case GET_METERS: {
      const options = action.data;
      const setupDestinationForMeter = _.map(state.config.setupDestinationForMeter, configItem => {
        if (configItem.name === "meter") {
          configItem.options = options as Options[];
        }
        return configItem;
      });
      return {
        ...state,
        config: {
          ...state.config,
          setupDestinationForMeter
        }
      };
    }
    case METER_STATUSES_SUCCESS: {
      return {
        ...state,
        meterStatuses: action.data,
        meterStatusRequestErrorMessage: ""
      };
    }
    case METER_STATUS_REQUEST_FAILURE: {
      return {
        ...state,
        meterStatusRequestErrorMessage: action.errorMessage
      };
    }
    case DATA_PROVIDERS_SUCCESS: {
      return {
        ...state,
        dataProviders: action.data
      };
    }
    case DESTINATION_SUCCESS: {
      return {
        ...state,
        destinations: action.data
      };
    }
    case SELECT_PROVIDER: {
      let providerData = updateSecureFieldsForProvider(action.provider, action.activeMenuItem);

      if (providerData.properties) {
        Object.keys(providerData.properties).forEach(key => {
          providerData = {
            ...providerData,
            [key]: providerData.properties[key]
          };
        });
      }

      return {
        ...state,
        activeMenuItem: action.activeMenuItem,
        isEditable: true,
        formData: {
          ...state.formData,
          [action.activeMenuItem]: providerData
        }
      };
    }
    case SAVE_PROVIDER: {
      return {
        ...state,
        activeMenuItem: "",
        formData: getDefaultFormDataState(state.config)
      };
    }
    case DELETE_PROVIDER: {
      return {
        ...defaultAmrState,
        formData: getDefaultFormDataState(state.config),
        config: state.config
      };
    }
    case METER_MAPPINGS_DESTINATION_SUCCESS: {
      return {
        ...state,
        meterList: _.sortBy(action.data, "id")
      };
    }
    case METER_MAPPINGS_BY_SOURCE_SUCCESS: {
      const meterList = _.chain(state.meterList)
        .map(meterItem => {
          // eslint-disable-next-line eqeqeq
          if (meterItem.id != action.actionData.sourceSystemId) {
            return meterItem;
          }
          const destinationSystems = _.chain(meterItem.destinationSystems)
            .map(destination => {
              // eslint-disable-next-line eqeqeq
              if (destination.id != action.actionData.destinationSystemId) {
                return destination;
              }
              if (!action.data.length) {
                return null;
              }
              return {...destination, sites: action.data};
            })
            .compact()
            .value();
          if (!destinationSystems.length) {
            return null;
          }
          return {...meterItem, destinationSystems};
        })
        .compact()
        .value();
      return {
        ...state,
        meterList
      };
    }
    case TOGGLE_METER_TREE_VIEW: {
      return {
        ...state,
        toggleMeterList: {
          ...state.toggleMeterList,
          [action.key]: action.value
        }
      };
    }
    case SAVE_METER_READINGS: {
      const setupDestinationForMeter = _.map(state.config.setupDestinationForMeter, configItem => {
        configItem.options = configItem.name === "type" ? configItem.options : [];
        return configItem;
      });
      return {
        ...state,
        formData: {
          ...state.formData,
          configureMeterReadings: getDefaultFormDataState(state.config).configureMeterReadings
        },
        config: {
          ...state.config,
          setupDestinationForMeter
        },
        isConfigureMeterReadings: !state.isConfigureMeterReadings,
        toggleMeterList: {}
      };
    }
    case SAVE_METER_READINGS_START: {
      return {
        ...state,
        createUpdaterErrorMessage: ""
      };
    }
    case SAVE_METER_READINGS_FAILURE: {
      return {
        ...state,
        createUpdaterErrorMessage: JSON.parse(action.errorMessage).data.message
      };
    }
    case NEW_ALERT_CLICK: {
      const destinationsOptions = state.destinations
        .filter(destination => destination.type === "X4")
        .map(destination => ({
          value: destination.id,
          description: destination.name
        }));

      const dataProvidersOptions = state.dataProviders
        .filter(provider => provider.type === "WEB_SERVICE" && provider.objectType === "ALERT")
        .map(provider => ({
          value: provider.id,
          description: provider.name
        }));

      const setupDestinationForMeter = state.config.setupDestinationForMeter.map(configItem => {
        if (configItem.name === "type") {
          configItem.options = destinationsOptions as Options[];
          return configItem;
        } else {
          return configItem;
        }
      });

      const setupConnectionForMeter = state.config.setupConnectionForMeter.map(configItem => {
        if (configItem.name === "dataSource") {
          configItem.options = dataProvidersOptions as Options[];
          return configItem;
        } else {
          return configItem;
        }
      });

      const meterStatus = action.meterMapperId
        ? _.find(state.meterStatuses, {meterId: action.meterMapperId})
        : undefined;
      const meterStatusError =
        meterStatus && (meterStatus.status === "FAILED" || meterStatus.status === "NOT_SYNCHRONIZED")
          ? meterStatus.errorMessage
          : "";

      return {
        ...state,
        isConfigureMeterReadings: !state.isConfigureMeterReadings,
        meterMapperId: action.meterMapperId,
        formData: {
          ...state.formData,
          configureMeterReadings: {
            ...state.formData.configureMeterReadings,
            objectType: "ALERT"
          }
        },
        config: {
          ...state.config,
          setupDestinationForMeter,
          setupConnectionForMeter
        },
        errorFromMeterStatus: meterStatusError
      };
    }
    case NEW_METER_CLICK: {
      const configureMeterReadings = state.isConfigureMeterReadings
        ? getDefaultFormDataState(state.config).configureMeterReadings
        : state.formData.configureMeterReadings;

      const destinationsOptions = state.destinations.map(destination => ({
        value: destination.id,
        description: destination.name
      }));

      const dataProvidersOptions = state.dataProviders
        .filter(provider => provider.objectType !== "ALERT")
        .map(provider => ({
          value: provider.id,
          description: provider.name
        }));

      const setupDestinationForMeter = state.config.setupDestinationForMeter.map(configItem => {
        if (configItem.name === "type") {
          configItem.options = destinationsOptions as Options[];
          return configItem;
        } else {
          if (state.isConfigureMeterReadings) {
            configItem.options = [];
          }
          return configItem;
        }
      });

      const setupConnectionForMeter = _.map(state.config.setupConnectionForMeter, configItem => {
        if (configItem.name === "dataSource") {
          configItem.options = dataProvidersOptions as Options[];
        }
        return configItem;
      });

      const meterStatus = action.meterMapperId
        ? _.find(state.meterStatuses, {meterId: action.meterMapperId})
        : undefined;
      const meterStatusError =
        meterStatus && (meterStatus.status === "FAILED" || meterStatus.status === "NOT_SYNCHRONIZED")
          ? meterStatus.errorMessage
          : "";
      return {
        ...state,
        isConfigureMeterReadings: !state.isConfigureMeterReadings,
        meterMapperId: action.meterMapperId,
        config: {
          ...state.config,
          setupDestinationForMeter,
          setupConnectionForMeter
        },
        formData: {
          ...state.formData,
          configureMeterReadings: {
            ...configureMeterReadings,
            objectType: "METER"
          }
        },
        errorFromMeterStatus: meterStatusError,
        createUpdaterErrorMessage: ""
      };
    }
    case GET_METER_BY_ID: {
      let downsamplingProps = {};
      if (action.data.downsamplingConfig) {
        const {
          calculationType,
          conflationPeriod1,
          conflationPeriod1Lower,
          conflationPeriod1Unit,
          conflationPeriod1Upper,
          conflationPeriod2,
          conflationPeriod2Unit,
          linearCalculationFactor,
          linearCalculationOffset,
          postValueOnChange
        } = action.data.downsamplingConfig;
        const isConflationPeriod1Selected = Number(conflationPeriod1) > 0;
        const isConflationPeriod2Selected = isConflationPeriod1Selected && Number(conflationPeriod2) > 0;
        const isCalculationTypeLinear = isConflationPeriod1Selected && calculationType === "LINEAR";

        downsamplingProps = {
          calculationType: !isConflationPeriod1Selected ? undefined : calculationType,
          conflationPeriod1: !isConflationPeriod1Selected ? undefined : conflationPeriod1,
          conflationPeriod1Lower: !isConflationPeriod1Selected ? undefined : conflationPeriod1Lower,
          conflationPeriod1Unit: !isConflationPeriod1Selected ? undefined : conflationPeriod1Unit,
          conflationPeriod1Upper: !isConflationPeriod1Selected ? undefined : conflationPeriod1Upper,
          conflationPeriod2: !isConflationPeriod1Selected ? undefined : conflationPeriod2,
          conflationPeriod2Unit: !isConflationPeriod2Selected ? undefined : conflationPeriod2Unit,
          linearCalculationFactor: !isCalculationTypeLinear ? undefined : linearCalculationFactor,
          linearCalculationOffset: !isCalculationTypeLinear ? undefined : linearCalculationOffset,
          postValueOnChange,
          downsamplingConfigId: action.data.downsamplingConfig.id
        };
      }

      let sourceAttributeProps = {};
      if (!!action.data.sourceAttribute.properties) {
        Object.keys(action.data.sourceAttribute.properties).forEach(key => {
          sourceAttributeProps = {
            ...sourceAttributeProps,
            [key]: action.data.sourceAttribute.properties[key]
          };
        });
      }

      const sourceSystem = state.dataProviders.find(
        provider => provider.id === action.data.sourceAttribute.sourceSystemId
      );
      if (sourceSystem) {
        const {meterValueExtractor, conversionRule} = sourceSystem.properties;
        sourceAttributeProps = {
          ...sourceAttributeProps,
          ...(meterValueExtractor && {meterValueExtractor}),
          ...(conversionRule && {conversionRule})
        };
      }

      const configureMeterReadings = {
        ...sourceAttributeProps,
        ...downsamplingProps,
        id: action.data.id,
        address: action.data.sourceAttribute.address,
        dataSource: action.data.sourceAttribute.sourceSystemId,
        dataSourceId: action.data.sourceAttribute.id,
        interval: action.data.sourceAttribute.interval,
        intervalType: action.data.sourceAttribute.intervalType,
        type: action.data.destinationAttribute.destinationSystemId,
        typeId: action.data.destinationAttribute.id,
        asset: action.data.destinationAttribute.asset.id,
        site: action.data.destinationAttribute.site.id,
        ...(action.data.destinationAttribute.assetMeter && {meter: action.data.destinationAttribute.assetMeter.id})
      };
      return {
        ...state,
        formData: {
          ...state.formData,
          configureMeterReadings
        }
      };
    }
    case SHOW_DELETE_MODAL: {
      return {
        ...state,
        isDeleteModalShown: !state.isDeleteModalShown
      };
    }
    case UPDATE_CONFIGURATIONS: {
      let formDataConfig = action.activeMenuItem as "setupConnection" | "setupDestination" | "configureMeterReadings";

      const data = _.isEmpty(action.data)
        ? getDefaultFormDataState(state.config)[formDataConfig]
        : {...state.formData[formDataConfig], ...action.data};

      return {
        ...state,
        formData: {
          ...state.formData,
          [action.activeMenuItem]: data
        }
      };
    }
    case TOGGLE_IMPORT_RESULTS_MODAL: {
      return {
        ...state,
        isImportResultModalShown: !state.isImportResultModalShown
      };
    }
    case IMPORT_METERS_SUCCESS: {
      return {
        ...state,
        importMeterReadingsResults: action.data,
        isImportResultModalShown: true,
        toggleMeterList: {}
      };
    }
    case GENERIC_REQUEST_START: {
      return {...state, errorMessage: ""};
    }
    case GENERIC_REQUEST_FAILURE: {
      return {...state, errorMessage: action.errorMessage};
    }
    case ASSETS_FIELD_CONFIGS_SUCCESS: {
      const config = action.data;
      return {
        ...state,
        config: config as Config,
        formData: getDefaultFormDataState(config as Config)
      };
    }
    default: {
      return state;
    }
  }
}
