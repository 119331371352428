import React from "react";
import _ from "lodash";
import {Typeahead} from "react-bootstrap-typeahead";
import {Table, Modal} from "react-bootstrap";
import ConfigurableLabelRow from "./configurableLabelRow";
import {ScreenExtensionsProps} from "../types";
import {translationLanguages} from "../../../../store/screenExtensions/types";

export default function ConfigurableLabels(props: ScreenExtensionsProps) {
  let langCols = _.map(translationLanguages, (lang, key) => <th key={key}>{lang}</th>);
  let options = _.chain(props.configurableFields)
    .map("groups")
    .flatten()
    .map("fields")
    .flatten()
    .map("label")
    .difference(_.map(props.configurableLabels, (configLabel, key) => key.toString()))
    .flatten()
    .compact()
    .value();

  let configLabelRows = _.map(props.configurableLabels, (label, labelId: string) => {
    let configLabel = {...label, labelId};
    return <ConfigurableLabelRow {...props} key={labelId} configLabel={configLabel} />;
  });

  let confirmDeletePopup = (
    <Modal
      show={true}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => props.showConfirmCancel(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Confirm that you want to delete cofigurable label?</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => props.showConfirmCancel(false)}>
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            props.showConfirmCancel(false);
            props.deleteConfigLabel(props.activeLabel ? props.activeLabel.labelId : "");
          }}
        >
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className="pt-3">
      {props.showConfirmationDialog && confirmDeletePopup}
      <div className="row pb-3 mr-0">
        <div className="col-10">
          <Typeahead
            id="newLabelTranslations"
            multiple={false}
            options={options}
            allowNew={true}
            placeholder="Search label by ID"
            onChange={selected => {
              if (selected.length === 0) {
                props.changeActiveLabel();
                return;
              }
              let newLabelId = _.isString(selected[0]) ? selected[0] : _.get(selected, "[0].label");
              props.changeActiveLabel({
                labelId: newLabelId
              });
            }}
          />
        </div>
        <button
          className="col-2 btn btn-warning"
          disabled={!props.activeLabel}
          onClick={() => props.showAddEditConfigLabel(true, false)}
        >
          <span className="d-none d-sm-inline">Add translations</span>
          <span className="d-inline d-sm-none">+</span>
        </button>
      </div>
      <hr />
      {configLabelRows.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th key="label-id-col">Label ID</th>
              {langCols}
              <th />
            </tr>
          </thead>
          <tbody>{configLabelRows}</tbody>
        </Table>
      ) : (
        <div>No labels translations yet</div>
      )}
    </div>
  );
}
