import React, {useEffect, useState} from "react";
import {ConfigurationProps, FormItemData} from "./types";
import Tabs from "./Tabs";
import Configurations from "./Configurations";
import SaveCancelContainer from "../../common/SaveCancel/SaveCancelContainer";
import Breadcrumbs from "../../breadcrumbs";
import {ConfigurationPart, ConfigurationSetting} from "../../../store/configuration/types";
import {getSettingKey, getParts} from "../../../store/configuration/utils";

import "./index.scss";
import _ from "lodash";
import ConfirmCancel from "../../confirmCancel/ConfirmCancel";

const ConfigurationPage = (props: ConfigurationProps) => {
  useEffect(() => {
    props.loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [warningData, setWarningData] = useState({showWarning: false, message: ""});
  const [isShown, setIsShown] = useState(false);

  const handleFormItemCallback = (formItemData: FormItemData) => {
    setWarningData(formItemData);
  };

  let {currentConfigPart, currentMetadataPart} = getParts(props);

  const areCurrentPartsNotUndefined = !_.isUndefined(currentConfigPart) && !_.isUndefined(currentMetadataPart);

  const shouldSaveCancelBePresentOnCurrentTab =
    currentConfigPart && _.isString(props.unlockedTabName) && props.unlockedTabName === currentConfigPart.name;

  const finalConfigItemsList =
    currentConfigPart && currentMetadataPart
      ? _.intersectionBy(currentConfigPart.settings, currentMetadataPart.settingsMetadata, "name")
      : [];

  const dataWasChanged = _.some(
    finalConfigItemsList,
    (configItem: ConfigurationSetting) =>
      props.viewSettings[getSettingKey(currentConfigPart as ConfigurationPart, configItem)].isDirty
  );

  const dataIsValid = _.every(
    finalConfigItemsList,
    (configItem: ConfigurationSetting) =>
      props.viewSettings[getSettingKey(currentConfigPart as ConfigurationPart, configItem)].isValid
  );

  const cancelClick = () => {
    dataWasChanged
      ? props.showConfirmCancel(true)
      : currentConfigPart && props.changeTabLock((currentConfigPart as ConfigurationPart).name, true);
  };

  const saveClick = async () => {
    warningData.showWarning && setIsShown(false);
    await props.saveChanges(currentConfigPart as ConfigurationPart, props);
    currentConfigPart && props.changeTabLock((currentConfigPart as ConfigurationPart).name, true);

    if ((props.currentConfigPart as string) === "client") {
      props.setConfigPart("workorders");
      props.setConfigPart(props.currentConfigPart as string);
    }
  };

  const onSaveClickConfiguration = () => {
    if (warningData.showWarning) {
      return setIsShown(true);
    } else return saveClick();
  };

  return (
    <>
      <Breadcrumbs />
      {props.errorMessage.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {props.errorMessage}
        </div>
      )}
      <div
        className={
          !!props.unlockedTabName
            ? "overflow-auto flex-grow-1 p-3 edit-configuration-settings-block"
            : "overflow-auto flex-grow-1 p-3"
        }
      >
        <div className="position-relative">
          <Tabs {...props} />
          <Configurations {...props} configurationCallback={handleFormItemCallback} />
        </div>
      </div>
      {areCurrentPartsNotUndefined && shouldSaveCancelBePresentOnCurrentTab && (
        <SaveCancelContainer
          cancelClick={cancelClick}
          saveClick={onSaveClickConfiguration}
          isSaveDisabled={!dataWasChanged || !dataIsValid}
          isCancelDisabled={false}
          modalCancelClick={() => {
            props.showConfirmCancel(false);
          }}
          modalConfirmClick={() => {
            props.clearChanges(props);
            if ((props.currentConfigPart as string) === "client") {
              props.setConfigPart("workorders");
            }
          }}
          isModalShown={props.showConfirmationDialog}
        />
      )}
      {warningData.showWarning && isShown && (
        <ConfirmCancel
          text={<>{warningData.message}</>}
          cancelFunc={() => {
            setIsShown(false);
          }}
          cancelText="Cancel"
          confirmFunc={saveClick}
          confirmText="OK"
          title="Warning"
        />
      )}
    </>
  );
};

export default ConfigurationPage;
