import React from "react";

import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp, library} from "@fortawesome/fontawesome-svg-core";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {AmrProps, AssetMeterReadings, DestinationSystem, MeterListInterface, Sites, MeterStatus} from "../types";

import "./index.scss";

library.add(faMapMarkerAlt);

export default function MeterList(props: AmrProps) {
  const {meterList, toggleMeterList} = props;

  async function editMeterMapper(meterMappedId: number, objectType: string) {
    await props.getMeterMappingById(meterMappedId);
    await props.loadDataById(props.activeMenuItem, "type");
    await props.loadDataById(props.activeMenuItem, "site");
    await props.loadDataById(props.activeMenuItem, "asset", objectType);
    if (objectType === "ALERT") {
      props.newAlertClick(meterMappedId);
    } else {
      props.newMeterClick(meterMappedId);
    }
  }

  function isFailed(statusList: MeterStatus[]) {
    return _.some(
      statusList,
      meterStatus => meterStatus.status === "FAILED" || meterStatus.status === "NOT_SYNCHRONIZED"
    );
  }

  function getCollapse(
    expandKey: string,
    iconName: string,
    isFailed: boolean,
    params?: {connectionId?: string; destinationId?: string; meterMappedId?: number},
    isAlert?: boolean
  ) {
    return (
      <span
        className="mr-2 toggle-button"
        onClick={() => {
          if (!isAlert) {
            if (params && params.connectionId && params.destinationId && !props.toggleMeterList[expandKey]) {
              props.loadMeterMappingsBySource(params.connectionId, params.destinationId);
            }
            props.toggleExpanded(expandKey, !toggleMeterList[expandKey]);
          } else {
            if (params && params.meterMappedId) editMeterMapper(params.meterMappedId, "ALERT");
          }
        }}
      >
        {isFailed ? (
          <FontAwesomeIcon icon={"exclamation-circle" as IconProp} className="text-danger" />
        ) : (
          <FontAwesomeIcon icon={iconName as IconProp} className={toggleMeterList[expandKey] ? "selected" : ""} />
        )}
      </span>
    );
  }

  function getAssetMeters(assetMeterReading: AssetMeterReadings, meterStatusesByAsset: MeterStatus[]) {
    return (
      <ul>
        {_.map(assetMeterReading.meterReadings, meterReading => {
          const {assetMeter} = meterReading;
          const meterStatus = _.find(meterStatusesByAsset, {meterId: meterReading.meterReadingMappingId});
          let meterValueLabel = "";
          if (!!assetMeter.currentValue) {
            const currentValue = isNaN(Number(assetMeter.currentValue))
              ? assetMeter.currentValue
              : Number(assetMeter.currentValue).toFixed(2);
            const updateOn = assetMeter.updatedOn ? new Date(assetMeter.updatedOn).toLocaleDateString() : "";
            meterValueLabel = currentValue + " on " + updateOn;
          }

          return (
            <li key={meterReading.meterReadingMappingId}>
              <a
                tabIndex={meterReading.meterReadingMappingId}
                onClick={() => editMeterMapper(meterReading.meterReadingMappingId, "METER")}
                className="font-weight-bold meter-link text-primary"
              >
                {_.isObject(meterStatus) && isFailed([meterStatus]) && (
                  <FontAwesomeIcon icon={"exclamation-circle" as IconProp} className="text-danger mr-2" />
                )}
                {assetMeter.description}
                <span className="ml-4 text-dark">{`${meterValueLabel}`}</span>
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  function getAssets(site: Sites, key: string, meterStatusesBySite: MeterStatus[]) {
    return (
      <ul>
        {_.map(site.assetMeterReadings, assetMeterReading => {
          const meterStatusesByAsset = _.filter(meterStatusesBySite, {asset: assetMeterReading.assetName});
          const assetMeterKey = key + assetMeterReading.assetName;
          const assetMeter = !!assetMeterReading.meterReadings[0].assetMeter;
          return (
            <li key={assetMeterKey}>
              {getCollapse(
                assetMeterKey,
                "cogs",
                isFailed(meterStatusesByAsset),
                {meterMappedId: assetMeterReading.meterReadings[0].meterReadingMappingId},
                !assetMeter
              )}
              <span>{assetMeterReading.assetName}</span>
              {props.toggleMeterList[assetMeterKey] &&
                assetMeter &&
                getAssetMeters(assetMeterReading, meterStatusesByAsset)}
            </li>
          );
        })}
      </ul>
    );
  }

  function getSites(destination: DestinationSystem, key: string, meterStatusesByDestination: MeterStatus[]) {
    return (
      <ul>
        {_.map(destination.sites, site => {
          const siteKey: string = key + site.site;
          const meterStatusesBySite = _.filter(meterStatusesByDestination, {site: site.site});
          return (
            <li key={siteKey}>
              {getCollapse(siteKey, "map-marker-alt", isFailed(meterStatusesBySite))}
              <span>{site.site}</span>
              {props.toggleMeterList[siteKey] && getAssets(site, siteKey, meterStatusesBySite)}
            </li>
          );
        })}
      </ul>
    );
  }

  function getDestinations(item: MeterListInterface, meterStatusesBySource: MeterStatus[]) {
    return (
      <ul>
        {_.map(item.destinationSystems, destination => {
          const key: string = item.name + destination.name;
          const meterStatusesByDestination = _.filter(meterStatusesBySource, {destinationSystemId: destination.id});
          return (
            <li key={key}>
              {getCollapse(key, "database", isFailed(meterStatusesByDestination), {
                connectionId: item.id,
                destinationId: destination.id
              })}
              <span>{destination.name}</span>
              {props.toggleMeterList[key] && getSites(destination, key, meterStatusesByDestination)}
            </li>
          );
        })}
      </ul>
    );
  }

  const list = _.map(meterList, item => {
    const meterStatusesBySource = _.filter(props.meterStatuses, {sourceSystemId: item.id});
    return (
      <ul className="bg-white m-0" key={item.name}>
        <li>
          {getCollapse(item.name, "server", isFailed(meterStatusesBySource))}
          <span>{item.name}</span>
          {props.toggleMeterList[item.name] && getDestinations(item, meterStatusesBySource)}
        </li>
      </ul>
    );
  });

  const errorBox = (errorMessage: string) => {
    return (
      <>
        {errorMessage.length > 0 && (
          <div className="alert alert-danger" role="alert">
            <FontAwesomeIcon icon="ban" className="error-icon mr-3" />
            {errorMessage}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="tree-view mb-3 overflow-auto">
      {isFailed(props.meterStatuses) && (
        <div className="text-danger text-center font-weight-bold alert alert-danger d-block">
          <FontAwesomeIcon icon={"exclamation-circle" as IconProp} className="mr-2" />
          <b>Oops!</b> There is an error please check meter for more details
        </div>
      )}
      {errorBox(props.errorMessage)}
      {errorBox(props.meterStatusRequestErrorMessage)}
      {list}
    </div>
  );
}
