import React from "react";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {ConfigurableLabelRowProps, ScreenExtensionsProps} from "../../types";
import {translationLanguages} from "../../../../../store/screenExtensions/types";
import "./configurableLabelRow.scss";

library.add(faEllipsisV);

export default function ConfigurableLabelRow(props: ConfigurableLabelRowProps & ScreenExtensionsProps) {
  let getLangsCols = (rowKey: string) =>
    _.map(translationLanguages, (lang: string, langKey: number) => {
      return <td key={`${rowKey}-${langKey}`}>{props.configLabel[langKey]}</td>;
    });
  return (
    <>
      <tr>
        <td key={`#-${props.configLabel.labelId}`}>{props.configLabel.labelId}</td>
        {getLangsCols(props.configLabel.labelId)}
        <td key={`label-actions-${props.configLabel.labelId}`}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-custom-components" className="clearfix border border-0" variant="link">
              <FontAwesomeIcon icon="ellipsis-v" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="edit-dropdown-item"
                eventKey="1"
                onClick={() => {
                  props.changeActiveLabel(props.configLabel);
                  props.showAddEditConfigLabel(true, true);
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="delete-dropdown-item"
                eventKey="2"
                onClick={() => {
                  props.changeActiveLabel(props.configLabel);
                  props.showConfirmCancel(true);
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
}
