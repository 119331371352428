import React from "react";
import _ from "lodash";
import {InputProps, ConfigurationValueTypes} from "./types";

export default function Input(props: InputProps) {
  let finalValue = _.isNull(props.value) || _.isNaN(props.value) ? "" : props.value;
  return (
    <div className={`form-group ${props.class}`}>
      <label htmlFor={props.name}>{props.title}</label>
      <input
        value={finalValue}
        type={props.type === "TIMEOUT" ? "NUMBER" : props.type}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          let preparedValue: string | number = e.currentTarget.value;
          if (props.valueType === ConfigurationValueTypes.INTEGER) {
            preparedValue = parseInt(e.currentTarget.value);
          } else if (props.valueType === ConfigurationValueTypes.DECIMAL) {
            const parsedValue = parseFloat(e.currentTarget.value);
            preparedValue = _.isNaN(parsedValue) ? "" : parsedValue;
          }
          if (!_.isUndefined(props.minValue)) {
            preparedValue = Number(preparedValue) >= props.minValue ? preparedValue : "";
          }
          props.onChange(preparedValue);
        }}
        min={props.minValue}
        disabled={props.disabled}
        className={`form-control rounded-0 ${props.isValid ? "" : "is-invalid"}`}
        id={props.name}
        placeholder={`Enter ${props.title}`}
      />
      <small className="form-text text-muted">{props.description}</small>
    </div>
  );
}
