import React from "react";
import _ from "lodash";

import {MultiSelectProps, Options} from "./types";

import "./multiSelect.scss";

export default function MultiSelect(props: MultiSelectProps) {
  function getOptionValue(option: string | number | Options) {
    return (option as Options).value ? (option as Options).value : (option as number | string);
  }
  function getDescription(option: string | number | Options) {
    return (option as Options).description ? (option as Options).description : getOptionValue(option);
  }
  return (
    <div className="MultiSelect">
      <div className="row">
        <div className="col col-lg-2 col-5">
          <label>{props.title}</label>
        </div>
        <div className="col col-lg-10 col-7 d-flex align-items-start flex-wrap">
          {_.map(props.options as [], (option: string | number | Options) => (
            <button
              type="button"
              disabled={props.disabled}
              className="btn mr-2 mb-2 multiItem d-flex align-items-center"
              onClick={() => {
                let newValues: number[] | string[] = [];
                const newValue = getOptionValue(option);
                if (_.includes(props.value, newValue)) {
                  newValues = _.without(props.value, newValue) as number[] | string[];
                } else {
                  newValues = [...props.value, newValue].sort() as number[] | string[];
                }
                props.onChange(newValues);
              }}
              key={getOptionValue(option)}
            >
              <span className={_.includes(props.value, getOptionValue(option)) ? "checked" : "unchecked"} />
              {getDescription(option)}
            </button>
          ))}
        </div>
      </div>
      <small className="form-text text-muted">{props.description}</small>
    </div>
  );
}
