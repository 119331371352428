import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {Props} from "./types";

library.add(faChevronDown);

export default function MainLabel(props: Props) {
  return (
    <div className="row m-0">
      <div className="col-12 col-sm-6 pr-0 pl-0">
        <div
          className="input-group mb-3 no-gutters optionButtonRow"
          onClick={() => {
            props.openOptionSelector(props.name, true);
          }}
        >
          <div className="text-muted border p-2 flex-grow-1">select a {props.optionLabel}</div>
          <div className="input-group-append">
            <span className="input-group-text rounded-0">
              <FontAwesomeIcon icon="chevron-down" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
