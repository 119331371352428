import {ValueType} from "../../components/common/input/types";
import {
  MeterListInterface,
  MeterMappingsPayload,
  SaveProviderPayload,
  Sites,
  MeterStatus,
  ImportMeterReadingsResults
} from "../../components/pages/amr/types";

export const ACTIVE_MENU_CLICK = "ACTIVE_MENU_CLICK";

export interface ActiveMenuClick {
  type: typeof ACTIVE_MENU_CLICK;
  activeMenuItem: string;
}

export const DATA_PROVIDERS_SUCCESS = "DATA_PROVIDERS_SUCCESS";

export interface GetDataProviders {
  type: typeof DATA_PROVIDERS_SUCCESS;
  data: SaveProviderPayload[];
}

export const DESTINATION_SUCCESS = "DESTINATION_SUCCESS";

export interface GetDestinationsSuccess {
  type: typeof DESTINATION_SUCCESS;
  data: SaveProviderPayload[];
}

export const CHANGE_FORM_VALUE = "CHANGE_FORM_VALUE";

export interface ChangeValue {
  type: typeof CHANGE_FORM_VALUE;
  activeMenuItem: string;
  name: string;
  value: ValueType;
}

export const SELECT_PROVIDER = "SELECT_PROVIDER";

export interface SelectProvider {
  type: typeof SELECT_PROVIDER;
  provider: SaveProviderPayload;
  activeMenuItem: string;
}

export const SAVE_PROVIDER = "SAVE_PROVIDER";

export interface SaveProvider {
  type: typeof SAVE_PROVIDER;
}

export const DELETE_PROVIDER = "DELETE_PROVIDER";

export interface DeleteProvider {
  type: typeof DELETE_PROVIDER;
}

export const METER_MAPPINGS_DESTINATION_SUCCESS = "METER_MAPPINGS_DESTINATION_SUCCESS";

export interface GetMeterMappings {
  type: typeof METER_MAPPINGS_DESTINATION_SUCCESS;
  data: MeterListInterface[];
}

export const METER_STATUSES_SUCCESS = "METER_STATUSES_SUCCESS";

export interface GetMeterStatuses {
  type: typeof METER_STATUSES_SUCCESS;
  data: MeterStatus[];
}

export const METER_STATUS_REQUEST_START = "METER_STATUS_REQUEST_START";
interface MeterStatusRequestStartAction {
  type: typeof METER_STATUS_REQUEST_START;
}

export const METER_STATUS_REQUEST_FAILURE = "METER_STATUS_REQUEST_FAILURE";
interface MeterStatusRequestFailureAction {
  type: typeof METER_STATUS_REQUEST_FAILURE;
  errorMessage: string;
}

interface Option {
  description: string;
  id: string | number;
}

export const GET_SITES = "GET_SITES";

export interface GetSites {
  type: typeof GET_SITES;
  data: Option[];
}

interface AssetOption {
  id: string;
  name: string;
}

export const GET_ASSETS = "GET_ASSETS";

export interface GetAssets {
  type: typeof GET_ASSETS;
  data: AssetOption[];
}

export const GET_METERS = "GET_METERS";

export interface GetMeters {
  type: typeof GET_METERS;
  data: Option[];
}

export const SAVE_METER_READINGS = "SAVE_METER_READINGS";

export interface SaveMeterReadings {
  type: typeof SAVE_METER_READINGS;
}

export const SAVE_METER_READINGS_START = "SAVE_METER_READINGS_START";

export interface SaveMeterReadingsStart {
  type: typeof SAVE_METER_READINGS_START;
}
export const SAVE_METER_READINGS_FAILURE = "SAVE_METER_READINGS_FAILURE";

export interface SaveMeterReadingsFailure {
  type: typeof SAVE_METER_READINGS_FAILURE;
  errorMessage: string;
}

export const NEW_METER_CLICK = "NEW_METER_CLICK";

export interface newMeterClick {
  type: typeof NEW_METER_CLICK;
  meterMapperId: number | undefined;
}

export const METER_MAPPINGS_BY_SOURCE_SUCCESS = "METER_MAPPINGS_BY_SOURCE_SUCCESS";

export interface GetMeterMappingsBySource {
  type: typeof METER_MAPPINGS_BY_SOURCE_SUCCESS;
  data: Sites[];
  actionData: {
    sourceSystemId: string;
    destinationSystemId: string;
  };
}

export const TOGGLE_METER_TREE_VIEW = "TOGGLE_METER_TREE_VIEW";

export interface ToggleMeterTreeView {
  type: typeof TOGGLE_METER_TREE_VIEW;
  key: string;
  value: boolean;
}

export const DELETE_METER_MAPPER = "DELETE_METER_MAPPER";

export const GET_METER_BY_ID = "GET_METER_BY_ID";

export interface GetMeterMapperById {
  type: typeof GET_METER_BY_ID;
  data: MeterMappingsPayload;
}

export const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL";
export interface ShowDeleteModal {
  type: typeof SHOW_DELETE_MODAL;
}

export const GENERIC_REQUEST_START = "GENERIC_REQUEST_START";
interface GenericRequestStartAction {
  type: typeof GENERIC_REQUEST_START;
}
export const GENERIC_REQUEST_FAILURE = "GENERIC_REQUEST_FAILURE";
interface GenericRequestFailAction {
  type: typeof GENERIC_REQUEST_FAILURE;
  errorMessage: string;
}

export const UPDATE_CONFIGURATIONS = "UPDATE_CONFIGURATIONS";

export interface UpdateConfigurations {
  type: typeof UPDATE_CONFIGURATIONS;
  activeMenuItem: string;
  data: object;
}

export const TOGGLE_IMPORT_RESULTS_MODAL = "TOGGLE_IMPORT_RESULTS_MODAL";

export interface ShowImportResultsModal {
  type: typeof TOGGLE_IMPORT_RESULTS_MODAL;
}

export const IMPORT_METERS_SUCCESS = "IMPORT_METERS_SUCCESS";

export interface ImportMetersSuccess {
  type: typeof IMPORT_METERS_SUCCESS;
  payload: FormData;
  data: ImportMeterReadingsResults;
}

export const NEW_ALERT_CLICK = "NEW_ALERT_CLICK";

export interface newAlertClick {
  type: typeof NEW_ALERT_CLICK;
  meterMapperId: number | undefined;
}

export const EXPORT_TEMPLATE = "EXPORT_TEMPLATE";

export interface exportTemplate {
  type: typeof EXPORT_TEMPLATE;
}

export const ASSETS_FIELD_CONFIGS_SUCCESS = "ASSETS_FIELD_CONFIGS_SUCCESS";

export interface getAssetsFieldConfigs {
  type: typeof ASSETS_FIELD_CONFIGS_SUCCESS;
  data: any;
}

export type AmrType =
  | ActiveMenuClick
  | ChangeValue
  | GetDataProviders
  | GetDestinationsSuccess
  | SelectProvider
  | SaveProvider
  | DeleteProvider
  | GetSites
  | GetAssets
  | GetMeters
  | GetMeterMappings
  | GetMeterMappingsBySource
  | GetMeterStatuses
  | ToggleMeterTreeView
  | SaveMeterReadings
  | newMeterClick
  | SaveMeterReadingsStart
  | SaveMeterReadingsFailure
  | GetMeterMapperById
  | GenericRequestStartAction
  | GenericRequestFailAction
  | MeterStatusRequestStartAction
  | MeterStatusRequestFailureAction
  | ShowDeleteModal
  | UpdateConfigurations
  | ShowImportResultsModal
  | ImportMetersSuccess
  | UpdateConfigurations
  | newAlertClick
  | exportTemplate
  | getAssetsFieldConfigs;
