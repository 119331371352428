import React from "react";
import _ from "lodash";
import {ConfigurationProps} from "./types";
import {ConfigurationPart} from "../../../store/configuration/types";
import {getParts} from "../../../store/configuration/utils";

export default function EditTab(props: ConfigurationProps) {
  let {currentConfigPart, currentMetadataPart} = getParts(props);
  if (_.isUndefined(currentMetadataPart) || _.isUndefined(currentConfigPart)) {
    return null;
  }
  const tabLocked = _.isUndefined(props.unlockedTabName) || props.unlockedTabName !== currentConfigPart.name;
  if (tabLocked) {
    return (
      <div className="w-100 text-right pt-3">
        <button
          type="button"
          className="btn btn-warning editButton"
          onClick={() => props.changeTabLock((currentConfigPart as ConfigurationPart).name, false)}
        >
          Edit
        </button>
      </div>
    );
  } else {
    return null;
  }
}
