import React from "react";
import Integrations from "./Integrations";
import {IntegrationsProps} from "./types";
import ConfirmCancel from "../../confirmCancel/ConfirmCancel";
import Breadcrumbs from "../../breadcrumbs";
import {connect} from "react-redux";
import {AppState} from "../../../store/rootReducer";
import {actionCreators} from "../../../store/_reducers/integrations.reducer";

class IntegrationsPage extends React.Component<IntegrationsProps> {
  componentDidMount() {
    this.props.loadData();
  }

  render() {
    return (
      <>
        {this.props.showConfirmationDialog && (
          <ConfirmCancel
            text="Please confirm that you want to discard the changes"
            cancelFunc={() => {
              this.props.showConfirmCancel(false);
            }}
            cancelText="Cancel"
            confirmFunc={() => {
              this.props.clearChanges(this.props);
            }}
            confirmText="Clear changes"
          />
        )}
        <Breadcrumbs />
        <div
          className={
            this.props.editModeIsActive
              ? "overflow-auto flex-grow-1 p-3 edit-integrations-settings-block"
              : "overflow-auto flex-grow-1 p-3"
          }
        >
          <Integrations {...this.props} />
        </div>
      </>
    );
  }
}

export default connect(
  (state: AppState) => {
    return {
      ...state.integrations,
      ...state.configuration,
      ...state.authentication
    };
  },
  actionCreators
)(IntegrationsPage);
