import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import ConnectedApp from "./containers/ConnectedApp";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store";

const store = configureStore();

let render = MainApp => {
  ReactDOM.render(
    <Provider store={store}>
      <MainApp />
    </Provider>,
    document.getElementById("root")
  );
};
if (module.hot) {
  module.hot.accept("./containers/ConnectedApp", () => {
    const NextApp = require("./containers/ConnectedApp").default;
    render(NextApp);
  });
}

render(ConnectedApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
