import _ from "lodash";
import {
  GENERIC_REQUEST_START,
  GENERIC_REQUEST_FAILURE,
  CONFIGURATION_SUCCESS,
  CONFIGURATION_METADATA_SUCCESS,
  SELECT_CONFIG_PART,
  CHANGE_VALUE,
  TAB_LOCK_CHANGE,
  SHOW_CONFIRM_CANCEL,
  CLEAR_DIRTY,
  SHOW_ADVANCED_CONFIGS,
  ConfigurationState,
  ConfigurationTypes,
  ConfigurationMetadata
} from "./types";
import {getSettingKey, validate, areWidgetsCompatibleWithTypes, generateViewSettings, createViewSetting} from "./utils";

const defaultConfigurationState: ConfigurationState = {
  configuration: undefined,
  metadata: undefined,
  currentConfigPart: undefined,
  errorMessage: "",
  viewSettings: {},
  unlockedTabName: undefined,
  showConfirmationDialog: false,
  showAdvancedOptions: false
};
export default function configuration(state = defaultConfigurationState, action: ConfigurationTypes) {
  switch (action.type) {
    case CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration: action.data,
        viewSettings: generateViewSettings(state.viewSettings, state.metadata, action.data)
      };
    }
    case CONFIGURATION_METADATA_SUCCESS: {
      let compatibilityErrors = areWidgetsCompatibleWithTypes(action.data);
      if (compatibilityErrors.length !== 0) {
        return {
          ...state,
          errorMessage: JSON.stringify(compatibilityErrors),
          metadata: undefined
        };
      }
      return {
        ...state,
        metadata: action.data,
        currentConfigPart: _.isString(state.currentConfigPart) ? state.currentConfigPart : action.data[0].name,
        viewSettings: generateViewSettings(state.viewSettings, action.data, state.configuration)
      };
    }
    case GENERIC_REQUEST_START: {
      return {...state, errorMessage: ""};
    }
    case GENERIC_REQUEST_FAILURE: {
      return {...state, errorMessage: action.errorMessage};
    }
    case SELECT_CONFIG_PART: {
      return {...state, currentConfigPart: action.part};
    }
    case TAB_LOCK_CHANGE: {
      return {
        ...state,
        unlockedTabName: action.locked ? undefined : action.tabName
      };
    }
    case SHOW_CONFIRM_CANCEL: {
      return {...state, showConfirmationDialog: action.show};
    }
    case SHOW_ADVANCED_CONFIGS: {
      return {...state, showAdvancedOptions: action.show};
    }
    case CHANGE_VALUE: {
      let key = getSettingKey(action.mdPart, action);
      const settingMD = _.find(action.mdPart.settingsMetadata, {
        name: action.name
      });
      let originalValue;
      const configPart = _.find(state.configuration, {
        name: action.mdPart.name
      });
      if (!_.isUndefined(configPart)) {
        const config = _.find(configPart.settings, {name: action.name});
        if (!_.isUndefined(config)) {
          originalValue = config.value;
        }
      }
      return {
        ...state,
        viewSettings: {
          ...state.viewSettings,
          [key]: {
            value: action.value,
            isDirty: _.isArray(originalValue)
              ? !_.isEqual(originalValue, action.value)
              : originalValue !== action.value,
            isValid: validate(action.value, settingMD as ConfigurationMetadata)
          }
        }
      };
    }
    case CLEAR_DIRTY: {
      const configPart = _.find(state.configuration, {
        name: action.tabName
      });
      if (_.isUndefined(configPart)) {
        return state;
      }
      const configMDPart = _.find(state.metadata, {
        name: configPart.name
      });
      if (_.isUndefined(configMDPart)) {
        return state;
      }
      const newViewSettings = {...state.viewSettings};
      configMDPart.settingsMetadata.forEach(settingMD => {
        const key = getSettingKey(configMDPart, settingMD);
        const config = _.find(configPart.settings, {name: settingMD.name});
        if (_.isUndefined(config)) {
          return;
        }

        newViewSettings[key] = createViewSetting(config, settingMD);
      });
      return {
        ...state,
        viewSettings: newViewSettings
      };
    }
    default:
      return state;
  }
}
