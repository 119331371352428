import React from "react";
import Modal from "react-bootstrap/Modal";

export default function ConfirmCancel(props: {
  text: string | JSX.Element;
  cancelFunc: () => void;
  cancelText: string;
  confirmFunc: () => void;
  confirmText: string;
  bodyComponent?: React.ReactElement;
  title?: string;
}) {
  return (
    <Modal show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={props.cancelFunc}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title ? props.title : "Confirm"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.text}</p>
        {props.bodyComponent ? <div className="pt-3 pl-3">{props.bodyComponent}</div> : null}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={props.cancelFunc}>
          {props.cancelText}
        </button>
        <button type="button" className="btn btn-primary" onClick={props.confirmFunc}>
          {props.confirmText}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
