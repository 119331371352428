import {
  GENERIC_REQUEST_FAILURE,
  GENERIC_REQUEST_START,
  CO2_COMPONENTS_SUCCESS,
  RELOAD_PAGE_INFO_SUCCESS,
  SELECT_FILTER_ATTRIBUTE,
  DESELECT_FILTER_ATTRIBUTE,
  OPEN_FILTER_ATTRIBUTE_SELECTOR,
  CO2_COMPONENT_SELECT_CHANGE,
  CO2_ALL_COMPONENT_SELECT_CHANGE,
  GENERIC_REQUEST_SUCCESS,
  RELOAD_STATUS_SUCCESS,
  RELOAD_STATUS_FAILURE,
  SET_RELOAD_STATUS_INTERVALID,
  SET_ATTRIBUTE_QUERY,
  SHOW_ERROR_POPUP,
  HIDE_RELOAD_STATUS_INFO,
  RELOAD_REQUEST,
  RELOAD_SUCCESS,
  ViewCO2Component,
  SET_SORT,
  SortOrder,
  SortFields,
  StartPayload,
  ReloadDetailsResponseSchema,
  ComponentsResponseSchema,
  ReloadPageInfoSchema
} from "./types";
import {AppState} from "../rootReducer";

export function getReloadStatus() {
  return {
    types: [GENERIC_REQUEST_START, RELOAD_STATUS_SUCCESS, RELOAD_STATUS_FAILURE],
    showPopup: false,
    responseSchema: ReloadDetailsResponseSchema,
    getURL: (state: AppState) => `/e7/services/config/load/${state.dbReload.loadId}`
  };
}

export function getLastReloadDetails() {
  return {
    types: [GENERIC_REQUEST_START, RELOAD_STATUS_SUCCESS, GENERIC_REQUEST_FAILURE],
    showPopup: false,
    responseSchema: ReloadDetailsResponseSchema,
    getURL: () => `/e7/services/config/load/lastLoad`
  };
}

export function stopReload() {
  return {
    types: [GENERIC_REQUEST_START, GENERIC_REQUEST_SUCCESS, GENERIC_REQUEST_FAILURE],
    method: "POST",
    showPopup: false,
    getURL: (state: AppState) => `/e7/services/config/load/stop/${state.dbReload.loadId}`
  };
}
export function startReload(payload: StartPayload) {
  return {
    types: [RELOAD_REQUEST, RELOAD_SUCCESS, GENERIC_REQUEST_FAILURE],
    method: "POST",
    showPopup: false,
    payload,
    getURL: () => `/e7/services/config/load`
  };
}

export function fetchComponents() {
  return {
    types: [GENERIC_REQUEST_START, CO2_COMPONENTS_SUCCESS, GENERIC_REQUEST_FAILURE],
    responseSchema: ComponentsResponseSchema,
    getURL: () => `/e7/services/components/public`
  };
}
export function fetchFilterAttributes() {
  return {
    types: [GENERIC_REQUEST_START, RELOAD_PAGE_INFO_SUCCESS, GENERIC_REQUEST_FAILURE],
    responseSchema: ReloadPageInfoSchema,
    getURL: () => `/e7/services/config/load/info`
  };
}
export function setReloadStatusIntervalId(id?: NodeJS.Timeout) {
  return {
    type: SET_RELOAD_STATUS_INTERVALID,
    id
  };
}

export function selectFilterAttribute(name: string, option: string) {
  return {
    type: SELECT_FILTER_ATTRIBUTE,
    name,
    option
  };
}
export function deselectFilterAttribute(name: string, option: string) {
  return {
    type: DESELECT_FILTER_ATTRIBUTE,
    name,
    option
  };
}

export function openFilterAttributeSelector(name: string, open: boolean) {
  return {
    type: OPEN_FILTER_ATTRIBUTE_SELECTOR,
    name,
    open
  };
}
export function co2ComponentSelectChange(viewCO2Component: ViewCO2Component) {
  return {
    type: CO2_COMPONENT_SELECT_CHANGE,
    viewCO2Component
  };
}
export function co2AllComponentSelectChange(checked: boolean) {
  return {
    type: CO2_ALL_COMPONENT_SELECT_CHANGE,
    checked
  };
}
export function showErrorPopup(show: boolean) {
  return {
    type: SHOW_ERROR_POPUP,
    show
  };
}
export function hideReloadStatusInfo() {
  return {
    type: HIDE_RELOAD_STATUS_INFO
  };
}
export function setAttributeQuery(name: string, attributeQuery: string) {
  return {
    type: SET_ATTRIBUTE_QUERY,
    name,
    attributeQuery
  };
}
export function setSort(sortField: SortFields, sortOrder: SortOrder) {
  return {
    type: SET_SORT,
    sortOrder,
    sortField
  };
}
