export interface IntegrationsState {
  editModeIsActive: boolean;
  testingConnection: boolean;
  connectionPassed: boolean;
  testConnectionResult: string;
}

export const integrationName = "cmms";

export const CHANGE_EDIT_MODE = "CHANGE_EDIT_MODE";
interface ChangeEditModeAction {
  type: typeof CHANGE_EDIT_MODE;
  editable: boolean;
}

export const SYNC_CONFIGURATION = "SYNC_CONFIGURATION";
interface SyncConfiguration {
  type: typeof SYNC_CONFIGURATION;
}

export const TEST_CONNECTION_PASSED = "TEST_CONNECTION_PASSED";
interface TestConnection {
  type: typeof TEST_CONNECTION_PASSED;
}

export const TEST_CONNECTION_START = "TEST_CONNECTION_START";
interface TestConnectionStartAction {
  type: typeof TEST_CONNECTION_START;
}

export const TEST_CONNECTION_FAILURE = "TEST_CONNECTION_FAILURE";
interface TestConnectionFailAction {
  type: typeof TEST_CONNECTION_FAILURE;
  errorMessage: string;
}

export type IntegrationsTypes =
  | ChangeEditModeAction
  | SyncConfiguration
  | TestConnection
  | TestConnectionStartAction
  | TestConnectionFailAction;
