import Joi from "joi";

export interface FieldSupportConfig {
  [key: string]: string[];
}

export interface SharedStoreState {
  showConfirmationDialog: boolean;
  fieldSupportConfig: FieldSupportConfig;
}

export const SHOW_CONFIRM_CANCEL = "SHOW_CONFIRM_CANCEL";

interface ShowConfirmCancelAction {
  type: typeof SHOW_CONFIRM_CANCEL;
  show: boolean;
}

export const CO2_SETTINGS_SUCCESS = "CO2_SETTINGS_SUCCESS";

interface ReceiveFieldSupportConfig {
  type: typeof CO2_SETTINGS_SUCCESS;
  data: FieldSupportConfig;
}

export const SettingsResponseSchema = Joi.object()
  .keys({
    checkbox: Joi.array()
      .items(Joi.string())
      .required(),
    dropdown: Joi.array()
      .items(Joi.string())
      .required(),
    text: Joi.array()
      .items(Joi.string())
      .required(),
    textarea: Joi.array()
      .items(Joi.string())
      .required()
  })
  .unknown(true);

export type SharedStoreTypes = ShowConfirmCancelAction | ReceiveFieldSupportConfig;
