import React from "react";
import _ from "lodash";
import DBReloadPageProps from "./types";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner);

export default class SpinnerWithInterval extends React.Component<DBReloadPageProps> {
  timerID: NodeJS.Timer | undefined;
  constructor(props: DBReloadPageProps) {
    super(props);
    this.timerID = undefined;
  }
  componentDidMount() {
    this.timerID = setInterval(() => {
      this.props.getReloadStatus();
    }, 300);
    this.props.setReloadStatusIntervalId(this.timerID);
  }
  componentWillUnmount() {
    if (!_.isUndefined(this.timerID)) {
      clearInterval(this.timerID);
      this.props.setReloadStatusIntervalId(undefined);
    }
  }
  render() {
    return <FontAwesomeIcon icon="spinner" spin />;
  }
}
