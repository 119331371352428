import {TextPart} from "./types";
import {createOuterPart, createInnerText, getFinalListOfOptions} from "./_optionFilterUtility";

export function createOptions(
  potentialOptions: string[],
  optionsQuery: string,
  selectedOptions: string[],
  selectOption: (name: string, option: string) => void,
  deselectOption: (name: string, option: string) => void,
  name: string
) {
  let finalOptions = getFinalListOfOptions(potentialOptions, optionsQuery);
  const queryRegex: RegExp | null = optionsQuery.length === 0 ? null : new RegExp(optionsQuery, "gi");

  return finalOptions
    .map((option): TextPart => createInnerText(option, queryRegex))
    .map(textPart => createOuterPart(textPart, selectedOptions, selectOption, deselectOption, name));
}
export function containsChild(parent: HTMLElement, child: HTMLElement) {
  var potentialParent = child.parentNode;
  while (potentialParent) {
    if (potentialParent === parent) {
      return true;
    }
    potentialParent = potentialParent.parentNode;
  }
  return false;
}
